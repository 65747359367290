import React, { Component } from 'react'
import { getNotification } from '../services/bookingService';
import { getCurrentDate, getTomorrowDate } from '../utils/utils';
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import moment from 'moment'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


class Notification extends Component {
    _isMounted = false;

    state = {
        bookings: [],

        view: false,
        buttonDisabled: false,
        selectDisabled: false,
        loading: true
    }

    populateBookings = async () => {
        const { data: bookings } = await getNotification(getCurrentDate(), getTomorrowDate());
        this._isMounted && this.setState({ bookings })
    }

    async componentDidMount() {
        this._isMounted = true;

        this._isMounted && await this.populateBookings();
        this._isMounted && this.setState({ loading: false })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChange = event => {
        const { name, value } = event.target
        this.setState({
            [name]: value
        })
    }

    renderTable() {
        const { bookings } = this.state;

        return (
            <div style={{ fontSize: '12px' }}>
                <Table style={{ backgroundColor: '#353B40', color: 'white' }} size='sm' striped bordered>
                    <thead>
                        <tr>
                            <th>Event Date</th>
                            <th>Program</th>
                            <th>Venue</th>
                            <th>Customer Name</th>
                            <th>Mobile</th>
                            <th>Additional Phone</th>
                            <th>Additional Phone 2</th>
                            <th className="text-center">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bookings.map((booking, index) => (
                            <tr key={index}>
                                <td>{moment(booking.eventDate).format('MMMM DD, YYYY')}</td>
                                <td>{booking.program}</td>
                                <td>{booking.venue}</td>
                                <td>{booking.customer.customerName}</td>
                                <td>{booking.customer.mobile}</td>
                                <td>{booking.additional_phone}</td>
                                <td>{booking.additional_phone_2}</td>
                                <td className="text-center">
                                    <Button
                                        variant='outline-info'
                                        style={{ padding: '0.25rem 0.5rem', fontSize: '10px', marginRight: '5px', marginLeft: '5px' }}
                                        onClick={() => this.props.printBooking(booking)}
                                    >
                                        Print
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }

    render() {
        return (
            <div>
                <ToastContainer hideProgressBar position="bottom-right" />

                {this.state.loading
                    ?
                    <div>
                        <center>
                            <br />
                            <br />
                            <br />
                            <Spinner size="sm" animation="grow" variant="danger" />{' '}
                            <Spinner size="sm" animation="grow" variant="warning" />{' '}
                            <Spinner size="sm" animation="grow" variant="success" />
                        </center>
                    </div>
                    :
                    <div>
                        <Row>
                            <Col>
                                {/* <Alert style={{ color: 'black', backgroundColor: '#FFC4C4'}}> */}
                                <Alert style={{ color: 'white', backgroundColor: '#353B40' }}>
                                    <center>
                                        <h6>Today's Bookings</h6>
                                        <hr />
                                        <p>{this.state.bookings[0]?.counts.today || 0}</p>
                                    </center>
                                </Alert>
                            </Col>
                            <Col>
                                <Alert variant='light' style={{ color: '#36454F' }}>
                                    <center>
                                        {/* <h5>{'S K'}</h5>
                                        <hr />
                                        <p>{'S O U N D'}</p> */}

                                        <h6>Developed By</h6>
                                        <hr />
                                        <div style={{ fontSize: '14px' }}>
                                            <p>Er. Sunil Gautam<br />
                                                gautam.sunil7725@gmail.com
                                            </p>
                                        </div>
                                    </center>
                                </Alert>
                            </Col>
                            <Col>
                                {/* <Alert style={{ color: 'black', backgroundColor: '#C4FFC4'}}> */}
                                <Alert style={{ color: 'white', backgroundColor: '#353B40' }}>
                                    <center>
                                        <h6>Tomorrow's Bookings</h6>
                                        <hr />
                                        <p>{this.state.bookings[0]?.counts.tomorrow || 0}</p>
                                    </center>
                                </Alert>
                            </Col>
                        </Row>
                        <br /><br />

                        {(this.state.bookings.length > 0) ? this.renderTable() : <div><p>No Bookings for Today and Tomorrow</p></div>}

                    </div>
                }
            </div>

        )
    }
}

export default Notification