import React, { Component } from 'react'
import { getReport } from '../services/reportService'
import { getCurrentDate, getDateThreeMonthsAgo } from '../utils/utils';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
const company = require('./../company');


class ReportForm extends Component {
    _isMounted = false;

    state = {
        date1: "",
        date2: "",

        summary: [],

        view: false,
        buttonDisabled: false,
        selectDisabled: false,

        update: false,
        loading: true
    }

    async componentDidMount() {
        this._isMounted = true;

        this._isMounted && this.setState({
            date1: getDateThreeMonthsAgo(),
            date2: getCurrentDate()
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChange = event => {
        const { name, value } = event.target
        this.setState({
            [name]: value
        })
    }

    populateSummary = async (d1, d2) => {
        const { data: summary } = await getReport(d1, d2);
        this._isMounted && this.setState({ summary, loading: false })
    }

    handleSubmit = async event => {
        event.preventDefault();

        const oneYearInMilliseconds = 1000 * 60 * 60 * 24 * 100;
        const dateDifference = Math.abs(new Date(this.state.date2) - new Date(this.state.date1));

        if (this.state.date1 > this.state.date2) {
            toast.info('Start Date should be less than End Date')
        }
        else if (dateDifference > oneYearInMilliseconds) {
            toast.info('The gap between dates must be less than 100 days');
        }
        else {
            this.setState({
                view: true,
                buttonDisabled: true,
                selectDisabled: true
            })

            await this.populateSummary(this.state.date1, this.state.date2)
        }
    }

    handleReset = () => {
        this.setState({

            view: false,
            buttonDisabled: false,
            selectDisabled: false,
            loading: true
        })
    }

    render() {
        return (
            <div>
                <ToastContainer hideProgressBar position="bottom-right" />

                <Form
                    style={{ fontSize: '12px', padding: '0.25rem 0.5rem' }}
                    onSubmit={this.handleSubmit}
                >

                    <Form.Row>
                        <Form.Group as={Col} controlId="">
                            <Form.Label>Start Date</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} controlId="">
                            <Form.Control
                                style={{ height: '30px' }}
                                type="date"
                                name="date1"
                                disabled={this.state.selectDisabled}
                                value={this.state.date1}
                                onChange={this.handleChange}
                            />
                        </Form.Group>
                        <Col></Col>
                        <Form.Group as={Col} controlId="">
                            <Form.Label>End Date</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} controlId="">
                            <Form.Control
                                style={{ height: '30px' }}
                                type="date"
                                name="date2"
                                disabled={this.state.selectDisabled}
                                value={this.state.date2}
                                onChange={this.handleChange}
                            />
                        </Form.Group>
                        <Col></Col>
                        <Col>
                            <Button
                                variant="dark"
                                type="submit"
                                size='sm'
                                disabled={this.state.buttonDisabled}
                            >
                                Generate
                            </Button>
                            {' '}
                            <Button
                                variant="danger"
                                size='sm'
                                onClick={this.handleReset}
                            >
                                Reset
                            </Button>
                        </Col>
                    </Form.Row>


                </Form>

                {this.state.view
                    ?
                    <div>
                        {this.state.loading
                            ?
                            <div>
                                <center>
                                    <br />
                                    <br />
                                    <br />
                                    <Spinner size="sm" animation="grow" variant="danger" />{' '}
                                    <Spinner size="sm" animation="grow" variant="warning" />{' '}
                                    <Spinner size="sm" animation="grow" variant="success" />
                                </center>
                            </div>
                            :
                            <div>
                                <center>
                                    <br />
                                    <b>{company.company}</b>
                                    <h2>Transaction Summary</h2>
                                    <p>From <b>{this.state.date1}</b> to <b>{this.state.date2}</b></p>
                                </center>
                                <br />
                                <br />
                                <Row>
                                    <Col>
                                        <p><b>Number of Bookings</b><span style={{ float: 'right' }}>{this.state.summary.bookingCount}</span></p>
                                        <p><b>Booking Amount</b><span style={{ float: 'right' }}>{this.state.summary.bookingAmount}</span></p>
                                        <br /><br />
                                        <p><b>Number of Payments</b><span style={{ float: 'right' }}>{this.state.summary.paymentCount}</span></p>
                                        <p><b>Payment Amount</b><span style={{ float: 'right' }}>{this.state.summary.paymentAmount}</span></p>
                                        <p><b>(Less) Discount</b><span style={{ float: 'right' }}>{this.state.summary.discount}</span></p>
                                        <hr />
                                        <p><b>Total Earning</b><span style={{ float: 'right' }}>{this.state.summary.earning}</span></p>
                                        <p><b>(Less) Total Expenses</b><span style={{ float: 'right' }}>{this.state.summary.totalExpenses}</span></p>
                                        <hr />
                                        <p><b>Total Income</b><span style={{ float: 'right' }}>{this.state.summary.totalIncome}</span></p>
                                    </Col>
                                    <Col></Col>

                                    <Col>
                                        <p><b>Salary</b><span style={{ float: 'right' }}>{this.state.summary.salary}</span></p>
                                        <p><b>Bonus</b><span style={{ float: 'right' }}>{this.state.summary.bonus}</span></p>
                                        <p><b>(Less) Deductions</b><span style={{ float: 'right' }}>{this.state.summary.deductions}</span></p>
                                        <hr />
                                        <p><b>Total Employee Expenses</b><span style={{ float: 'right' }}>{this.state.summary.totalEmployeeExpenses}</span></p>
                                        <p><b>Other Expenses</b><span style={{ float: 'right' }}>{this.state.summary.otherExpenses}</span></p>
                                        <hr />
                                        <p><b>Total Expenses</b><span style={{ float: 'right' }}>{this.state.summary.totalExpenses}</span></p>
                                    </Col>
                                </Row>
                                <br />
                                <hr />
                                <Button
                                    variant="outline-dark"
                                    size='sm'
                                    onClick={() => this.props.printReport(this.state)}
                                >
                                    Print
                                </Button>
                                <br />
                            </div>
                        }
                    </div>
                    :
                    <div><center><p><br />Choose a timeframe to generate Report.</p></center></div>

                }
            </div>
        )
    }
}

export default ReportForm
