import React, { Component } from 'react';
import { saveReturn } from '../services/returnService';
import { getBooking } from '../services/bookingService';
import { getDelivery } from '../services/deliveryService';
import { getCustomer } from '../services/customerService';
import { getEmployees } from '../services/employeeService';
import { getCurrentDate } from '../utils/utils';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'
import moment from 'moment'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Joi from "joi-browser";
const company = require('./../company');


class ReturnForm extends Component {
    _isMounted = false;

    state = {
        returnDate: "",
        bookingId: "",
        items: [],
        technicians: [
            {
                employeeId: "",
                employeeName: "",
                phone: ""
            }
        ],
        userId: "",
        submittedBy: "",
        comments: "",

        employees: [],
        billNum: "",
        program: "",
        venue: "",
        customerId: "",
        customerName: "",
        mobile: "",
        panNum: "",
        deliveryTechnicians: [],

        buttonDisabled: false,
        loading: true,
        isFormVisible: false
    }

    populateInformation = async () => {
        const { data: booking } = await getBooking(this.props.bookingId);

        this._isMounted && this.setState(
            {
                eventDate: booking.eventDate,
                bookingId: this.props.bookingId,
                customerId: booking.customerId,
                program: booking.program,
                venue: booking.venue,
                billNum: booking.billNum
            },
            async () => {
                const { data: customer } = await getCustomer(this.state.customerId);

                this._isMounted && this.setState({
                    customerName: customer.customerName,
                    panNum: customer.panNum,
                    mobile: customer.mobile
                });
            }
        );
    }

    populateDelivery = async () => {
        const { data: delivery } = await getDelivery(this.props.deliveryId);

        const processedItems = delivery.items.map(item => {
            const { _id, ...rest } = item;
            return {
                ...rest,
                in: item.out,
            };
        });

        const updatedTechnicians = delivery.technicians.map(technician => {
            const employee = this.state.employees.find(employee => employee._id === technician.employeeId);
            if (employee) {
                return {
                    ...technician,
                    phone: employee.phone
                };
            }
            return technician; // If no matching employee is found, keep the original technician
        });

        this._isMounted && this.setState({
            items: processedItems,
            comments: delivery.comments,
            deliveryTechnicians: updatedTechnicians
        });
    }

    populateEmployees = async () => {
        const { data: employees } = await getEmployees();
        this._isMounted && this.setState({ employees })
    }

    async componentDidMount() {
        this._isMounted = true;
        this._isMounted && await this.populateInformation();
        this._isMounted && await this.populateEmployees();
        this._isMounted && await this.populateDelivery();

        this._isMounted && this.setState({
            loading: false,
            submittedBy: this.props.user.name,
            userId: this.props.user._id,
            returnDate: getCurrentDate()
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleOutChange = (index, event) => {
        const { name, value } = event.target;
        const intValue = parseInt(value, 10);
        const updatedItems = this.state.items.map((item, idx) =>
            idx === index ? { ...item, [name]: intValue } : item
        );
        this.setState({ items: updatedItems });
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    validateReturn = (data) => {
        const schema = {
            returnDate: Joi.date().required(),
            bookingId: Joi.string().required(),
            customerId: Joi.string().required(),
            items: Joi.array().items(Joi.object({
                particulars: Joi.string().required(),
                equipmentId: Joi.string().required(),
                days: Joi.number().required(),
                qty: Joi.number().required(),
                codeNo: Joi.string().required(),
                out: Joi.number().required(),
                in: Joi.number().allow(''),
                remarks: Joi.string().allow('')
            })),
            technicians: Joi.array().items(Joi.object({
                employeeId: Joi.string().required(),
                employeeName: Joi.string().required()
            })),
            comments: Joi.string().max(1024).allow(''),
            userId: Joi.string().required(),
            submittedBy: Joi.string().required()
        };

        return Joi.validate(data, schema);
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        const data = { ...this.state };
        delete data.buttonDisabled;
        delete data.loading;
        delete data.employees;
        delete data.program;
        delete data.customerName;
        delete data.panNum;
        delete data.mobile;
        delete data.program;
        delete data.venue;
        delete data.billNum;
        delete data.eventDate;
        delete data.isFormVisible;

        // Append deliveryTechnicians to technicians
        data.technicians = [...data.technicians, ...data.deliveryTechnicians];

        // Filter out technicians with null or empty employeeId
        data.technicians = data.technicians.filter(technician => technician.employeeId !== null && technician.employeeId !== '');

        data.technicians = data.technicians.map(technician => {
            const { _id, phone, ...rest } = technician;
            return rest;
        });

        delete data.deliveryTechnicians;

        const { error } = this.validateReturn(data)
        if (error) {
            toast.error(error.details[0].message)
        }
        else {
            this.setState({ buttonDisabled: true });
            try {
                await saveReturn(data);
                toast.dark("Return added successfully")

                this.props.next()
            }
            catch (error) {
                toast.error(error);
            }
        }
    }

    handleTechnicianSelect = (index, e) => {
        const values = [...this.state.technicians];
        values[index].employeeId = e.employeeId;
        values[index].employeeName = e.employeeName;
        values[index].phone = e.phone;

        this.setState({ technicians: values })
    }

    handleRecordAdd = () => {
        this.setState((prevState) => ({
            technicians: [...prevState.technicians, {
                employeeId: "",
                employeeName: "",
                phone: "",
            }]
        }))
    }

    handleRecordRemove = (index) => {
        if (this.state.technicians.length > 1) {
            const values = [...this.state.technicians];
            values.splice(index, 1);

            this.setState({ technicians: values });
        }
    }

    toggleFormVisibility = () => {
        this.setState(prevState => ({
            isFormVisible: !prevState.isFormVisible
        }));
    };

    render() {
        const labelStyle = {
            display: 'inline-block',
            width: '120px',
            marginRight: '10px',
            textAlign: 'left'
        };

        const valueStyle = {
            display: 'inline-block',
        };

        return (
            <div>
                <ToastContainer hideProgressBar position="bottom-right" />

                {this.state.loading
                    ?
                    <div>
                        <center>
                            <br />
                            <br />
                            <br />
                            <Spinner size="sm" animation="grow" variant="danger" />{' '}
                            <Spinner size="sm" animation="grow" variant="warning" />{' '}
                            <Spinner size="sm" animation="grow" variant="success" />
                        </center>
                    </div>
                    :
                    <div>
                        <Form
                            style={{ fontSize: '12px', padding: '0.25rem 0.5rem' }}
                            onSubmit={this.handleSubmit}
                            onKeyDown={this.handleKeyPress}
                        >
                            <div style={{ backgroundColor: '#F0F7FF', padding: '20px' }}>
                                <center>
                                    {company.company}
                                    <h4>Return Form</h4>
                                    <p><br /></p>
                                </center>
                                <div style={{ fontSize: '15px' }}>
                                    <Row>
                                        <Col>
                                            <div>
                                                <span style={labelStyle}><strong>Bill Number:</strong></span>
                                                <span style={valueStyle}>{this.state.billNum}</span>
                                            </div>
                                            <div>
                                                <span style={labelStyle}><strong>Program:</strong></span>
                                                <span style={valueStyle}>{this.state.program}</span>
                                            </div>
                                            <div>
                                                <span style={labelStyle}><strong>Venue:</strong></span>
                                                <span style={valueStyle}>{this.state.venue}</span>
                                            </div>
                                            <div>
                                                <span style={labelStyle}><strong>Event Date:</strong></span>
                                                <span style={valueStyle}>{moment(this.state.eventDate).format('MMMM DD, YYYY')}</span>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div>
                                                <span style={labelStyle}><strong>Customer Name:</strong></span>
                                                <span style={valueStyle}>{this.state.customerName}</span>
                                            </div>
                                            <div>
                                                <span style={labelStyle}><strong>PAN:</strong></span>
                                                <span style={valueStyle}>{this.state.panNum}</span>
                                            </div>
                                            <div>
                                                <span style={labelStyle}><strong>Mobile:</strong></span>
                                                <span style={valueStyle}>{this.state.mobile}</span>
                                            </div>
                                            <div>
                                                <span style={labelStyle}><strong>Address:</strong></span>
                                                <span style={valueStyle}>{this.state.address}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <br />
                                <br />

                                <Form.Row>
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label>Return Date</Form.Label>
                                        <Form.Control
                                            style={{ width: '200px', height: '30px' }}
                                            type="date"
                                            name="returnDate"
                                            value={this.state.returnDate}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                    <Col></Col><Col></Col>
                                </Form.Row>

                                <br />
                                <br />

                                <Table size='sm' striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '60%' }}>Particulars</th>
                                            <th style={{ width: '10%', textAlign: 'center' }}>Code No</th>
                                            <th style={{ width: '15%', textAlign: 'center' }}>Out</th>
                                            <th style={{ width: '15%', textAlign: 'center' }}>In</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.items?.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.particulars}</td>
                                                <td style={{ textAlign: 'center' }}>{item.codeNo}</td>
                                                <td style={{ backgroundColor: item.out !== item.in ? '#FFC4C4' : '', textAlign: 'center' }}>
                                                    <Form.Control
                                                        style={{ width: '100%', height: '30px', fontSize: '12px' }}
                                                        type="number"
                                                        name="out"
                                                        max={item.out}
                                                        value={item.out}
                                                        readOnly
                                                    />
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <Form.Control
                                                        style={{ width: '100%', height: '30px', fontSize: '12px' }}
                                                        type="number"
                                                        name="in"
                                                        min={0}
                                                        max={item.out}
                                                        value={item.in}
                                                        onChange={(event) => this.handleOutChange(index, event)}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <br />
                                <br />

                                <Row>
                                    <Col>
                                        <Form.Group controlId="">
                                            <Form.Label>Technicians</Form.Label>
                                            <div style={{ paddingRight: '100px' }}>
                                                <Table size='sm' bordered>
                                                    <thead>
                                                        <tr>
                                                            <th>Name</th>
                                                            <th>Contact</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>{this.state.deliveryTechnicians?.map((technician, index) => (
                                                        <tr key={index}>
                                                            <td>{technician.employeeName}</td>
                                                            <td>{technician.phone}</td>
                                                        </tr>
                                                    ))}</tbody>
                                                </Table>
                                            </div>
                                        </Form.Group>
                                        <Button
                                            variant='outline-dark'
                                            size='sm'
                                            onClick={this.toggleFormVisibility}
                                        >
                                            Add More
                                        </Button>

                                        {this.state.isFormVisible && (
                                            <div>
                                                <br />
                                                <Form.Row>
                                                    <Col>
                                                        <Form.Label>Technician</Form.Label>
                                                    </Col>

                                                    <Form.Group as={Col} controlId="">
                                                        <Form.Label>Contact Number</Form.Label>
                                                    </Form.Group>

                                                    <Form.Group as={Col} controlId="">
                                                        <Form.Label></Form.Label>
                                                    </Form.Group>
                                                </Form.Row>

                                                {this.state.technicians.map((i, index) => {
                                                    return (
                                                        <Form.Row key={"T" + i + index}>
                                                            <Col>
                                                                <Select
                                                                    onChange={e => this.handleTechnicianSelect(index, e)}
                                                                    options={this.state.employees?.map(i => {
                                                                        return ({
                                                                            value: i.employeeName,
                                                                            label: i.employeeName,
                                                                            employeeName: i.employeeName,
                                                                            phone: i.phone,
                                                                            employeeId: i._id
                                                                        })
                                                                    })}
                                                                />
                                                            </Col>

                                                            <Form.Group as={Col} controlId="">
                                                                <Form.Control
                                                                    type="text"
                                                                    name="phone"
                                                                    value={i.phone}
                                                                    onChange={e => this.handleRecordChange(index, e)}
                                                                    readOnly
                                                                />
                                                            </Form.Group>

                                                            <Form.Group as={Col}>
                                                                <Button variant="light" size="sm" onClick={this.handleRecordAdd}>
                                                                    +
                                                                </Button>{' '}

                                                                <Button variant="light" size="sm" onClick={() => this.handleRecordRemove(index)}>
                                                                    -
                                                                </Button>
                                                            </Form.Group>

                                                        </Form.Row>
                                                    )
                                                })}
                                            </div>
                                        )}
                                    </Col>
                                    <Col>
                                        <Form.Group as={Col} controlId="">
                                            <Form.Label>Comments</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                name="comments"
                                                value={this.state.comments}
                                                onChange={this.handleChange}
                                            />
                                        </Form.Group>
                                    </Col></Row>

                            </div>

                            <div style={{ textAlign: 'right', fontSize: '12px' }}>
                                Submitted By: <b>{this.state.submittedBy}</b>
                            </div>

                            <br />
                            <Button
                                variant="dark"
                                type="submit"
                                disabled={this.state.buttonDisabled}
                            >
                                Confirm Return
                            </Button>

                        </Form>
                    </div>
                }
            </div>

        )
    }
}

export default ReturnForm