import httpService from "./httpService.js";


export async function getPayments(pageNumber) {
    return httpService.get(`/api/payment?page=${pageNumber}`);
}

export async function getPaymentsWithCustomer(pageNumber) {
    return httpService.get(`/api/payment/withCustomer?page=${pageNumber}`);
}

export async function getPaymentsByCustomerId(customerId, pageNumber) {
    return httpService.get(`/api/payment/byCustomerId?customerId=${customerId}&page=${pageNumber}`);
}

export async function getPayment(id) {
    return httpService.get(`/api/payment/${id}`);
}

export function downloadCSV(d1, d2) {
    return httpService.get(`/api/payment/download/?date1=${d1}&date2=${d2}`);
}

export async function savePayment(data) {
    if (!data._id) {
        return httpService.post(`/api/payment`, data);
    }

    const body = { ...data };
    delete body._id;
    return httpService.put(`/api/payment/${data._id}`, body);
}

export async function updatePayment(data, id) {
    return httpService.put(`/api/payment/${id}`, data);
}

export async function deletePayment(id) {
    return httpService.delete(`/api/payment/${id}`);
}