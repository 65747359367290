import React, { Component } from 'react';
import { getDetailedBooking, updateBooking } from '../services/bookingService';
import { getCustomers } from '../services/customerService';
import { getEquipments } from '../services/equipmentService';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'
import moment from 'moment'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Joi from "joi-browser";
const company = require('./../company');


class UpdateBooking extends Component {
	_isMounted = false;

	state = {
		bookings: [],
		customers: [],
		equipments: [],

		items: [
			{
				particulars: "",
				equipmentId: "",
				days: "",
				qty: "",
				codeNo: "",
				remarks: ""
			}
		],

		submittedBy: "",
		userId: "",

		buttonDisabled: false,
		loading: true
	}

	populateBooking = async () => {
		const { data: bookings } = await getDetailedBooking(this.props.bookingId);
		this._isMounted && this.setState({ bookings })
	}

	populateCustomers = async () => {
		const { data: customers } = await getCustomers();
		this._isMounted && this.setState({ customers })
	}

	populateEquipments = async () => {
		const { data: equipments } = await getEquipments();
		this._isMounted && this.setState({ equipments })
	}

	async componentDidMount() {
		this._isMounted = true;
		this._isMounted && await this.populateBooking();
		this._isMounted && await this.populateCustomers();
		this._isMounted && await this.populateEquipments();
		this._isMounted && this.setState({
			submittedBy: this.props.user.name,
			userId: this.props.user._id,
			loading: false
		})
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleChange = event => {
		const { name, value } = event.target;
		this.setState(prevState => ({
			bookings: {
				...prevState.bookings,
				booking: {
					...prevState.bookings.booking,
					[name]: value
				}
			}
		}), () => {
			this.calculateBalance();
		});
	};

	handleRecordChange = (index, e) => {
		const values = [...this.state.items];
		values[index][e.target.name] = e.target.value;
		this.setState({ values })
		this.setState({ values }, () => {
			this.calculateBalance()
		});
	}

	calculateBalance = () => {
		const { bookings } = this.state;
		const newBalance = bookings.booking.totalAmount * 1 - bookings.booking.advance * 1;
		const updatedBooking = { ...bookings.booking, balance: newBalance };
		const updatedBookings = { ...bookings, booking: updatedBooking };
		this.setState({ bookings: updatedBookings });
	}

	handleCustomerSelect = (e) => {
		this.setState((prevState) => ({
			bookings: {
				...prevState.bookings,
				booking: {
					...prevState.bookings.booking,
					customerId: e.value,
				},
				customer: {
					...prevState.bookings.customer,
					customerId: e.value,
					customerName: e.label,
					panNum: e.panNum,
					mobile: e.mobile,
					address: e.address,
				},
			},
		}));
	};

	handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	}

	validateBooking = (data) => {
		const schema = {
			date: Joi.date().required(),
			eventDate: Joi.date().required(),
			venue: Joi.string().max(1024).required(),
			program: Joi.string().max(1024).required(),
			billNum: Joi.string().required(),
			items: Joi.array().items(Joi.object({
				particulars: Joi.string().required(),
				equipmentId: Joi.string().required(),
				days: Joi.number().required(),
				qty: Joi.number().required(),
				codeNo: Joi.string().required(),
				remarks: Joi.string().allow('')
			})),
			totalAmount: Joi.number().required(),
			advance: Joi.number().required(),
			balance: Joi.number().required(),
			customerId: Joi.string().required(),
			userId: Joi.string().required(),
			submittedBy: Joi.string().allow(''),
			additional_phone: Joi.string().max(1024).allow(''),
			additional_phone_2: Joi.string().max(1024).allow(''),
			comments: Joi.string().max(1024).allow(''),
			deliveryId: Joi.string().allow(''),
			returnId: Joi.string().allow(''),
			paymentId: Joi.string().allow('')
		};

		return Joi.validate(data, schema);
	}

	handleSubmit = async (event) => {
		event.preventDefault();
		
		const shouldUpdate = window.confirm('-----------------------------------------------------\n     Do you really want to save changes ?\n-----------------------------------------------------');
		if (shouldUpdate) {
			let data = { ...this.state.bookings.booking };

			delete data._id;
			data.items = data.items.map(item => {
				const { _id, out, in: inProp, ...itemWithoutId } = item;
				return itemWithoutId;
			});
			delete data.isDelivered;
			delete data.isReturned;
			delete data.isPaid;
			delete data.dateModified;
			data.submittedBy = this.props.user.name;
			data.userId = this.props.user._id;

			const { error } = this.validateBooking(data)
			if (error) {
				toast.error(error.details[0].message)
			}
			else {
				this.setState({ buttonDisabled: true });
				try {
					await updateBooking(data, this.props.bookingId);
					toast.dark("Updated successfully")

					this.props.next()
				}
				catch (error) {
					toast.error(error);
				}
			}
		}
	}

	handleEquipmentSelect = (index, e) => {
		const values = [...this.state.items];
		values[index].particulars = e.value;
		values[index].equipmentId = e.equipmentId;
		values[index].codeNo = e.codeNo;

		this.setState({ values })
	}

	handleRecordAdd = () => {
		const item = this.state.items[0];
		if (item.particulars !== '' && item.codeNo !== '' && item.days !== '' && item.qty !== '') {

			const {
				particulars,
				equipmentId,
				days,
				qty,
				codeNo,
				remarks
			} = this.state.items[0];

			this.setState((prevState) => ({
				bookings: {
					...prevState.bookings,
					booking: {
						...prevState.bookings.booking,
						items: [
							...prevState.bookings.booking.items,
							{
								particulars,
								equipmentId,
								days,
								qty,
								codeNo,
								remarks
							}
						]
					}
				},
				items: [
					{
						particulars: "",
						equipmentId: "",
						days: "",
						qty: "",
						codeNo: "",
						remarks: ""
					}
				]
			}), () => {
				this.calculateBalance();
			});
		}
	}

	removeItem(id) {
		const { bookings } = this.state;

		const updatedBookingItems = bookings.booking.items.filter((item) => item._id !== id);

		this.setState({
			bookings: {
				...bookings,
				booking: {
					...bookings.booking,
					items: updatedBookingItems,
				},
			},
		});
	}

	render() {
		const labelStyle = {
			display: 'inline-block',
			width: '160px',
			marginRight: '10px',
			textAlign: 'left'
		};

		const valueStyle = {
			display: 'inline-block'
		};

		return (
			<div>
				<ToastContainer hideProgressBar position="bottom-right" />

				{this.state.loading
					?
					<div>
						<center>
							<br />
							<br />
							<br />
							<Spinner size="sm" animation="grow" variant="danger" />{' '}
							<Spinner size="sm" animation="grow" variant="warning" />{' '}
							<Spinner size="sm" animation="grow" variant="success" />
						</center>
					</div>
					:
					<div>
						<Form
							style={{ fontSize: '12px', padding: '0.25rem 0.5rem' }}
							onSubmit={this.handleSubmit}
							onKeyDown={this.handleKeyPress}
						>
							<div style={{ backgroundColor: '#FAFAFA', padding: '20px', border: '0.5px solid red' }}>
								<center>
									{company.company}
									<h4>Update Booking Details</h4>
									<p><br /></p>
								</center>
								<div style={{ fontSize: '15px' }}>
									<Row>
										<Col>
											<div>
												<span style={labelStyle}><strong>Date:</strong></span>
												<span style={valueStyle}>{moment(this.state.bookings.booking.date).format('MMMM DD, YYYY')}</span>
											</div>
											<div>
												<span style={labelStyle}><strong>Bill Number:</strong></span>
												<span style={valueStyle}>
													<Form.Control
														style={{ width: '200px', height: '20px' }}
														type="text"
														name="billNum"
														value={this.state.bookings.booking.billNum}
														onChange={this.handleChange}
													/>
												</span>
											</div>
											<div>
												<span style={labelStyle}><strong>Program:</strong></span>
												<span style={valueStyle}>
													<Form.Control
														style={{ width: '200px', height: '20px' }}
														type="text"
														name="program"
														value={this.state.bookings.booking.program}
														onChange={this.handleChange}
													/>
												</span>
											</div>
											<div>
												<span style={labelStyle}><strong>Venue:</strong></span>
												<span style={valueStyle}>
													<Form.Control
														style={{ width: '200px', height: '20px' }}
														type="text"
														name="venue"
														value={this.state.bookings.booking.venue}
														onChange={this.handleChange}
													/>
												</span>
											</div>
											<div>
												<span style={labelStyle}><strong>Event Date:</strong></span>
												<span style={valueStyle}>
													<Form.Control
														style={{ width: '200px', height: '20px' }}
														type="date"
														name="eventDate"
														value={moment(this.state.bookings.booking.eventDate).format("YYYY-MM-DD")}
														onChange={this.handleChange}
													/>
												</span>
											</div>
											<br />
											<div>
												<span style={labelStyle}><strong>Additional Phone 1:</strong></span>
												<span style={valueStyle}>
													<Form.Control
														style={{ width: '200px', height: '20px' }}
														type="text"
														name="additional_phone"
														value={this.state.bookings.booking.additional_phone}
														onChange={this.handleChange}
													/>
												</span>
											</div>
											<div>
												<span style={labelStyle}><strong>Additional Phone 2:</strong></span>
												<span style={valueStyle}>
													<Form.Control
														style={{ width: '200px', height: '20px' }}
														type="additional_phone_2"
														name="venue"
														value={this.state.bookings.booking.additional_phone_2}
														onChange={this.handleChange}
													/>
												</span>
											</div>
										</Col>
										<Col>
											<div>
												<span style={labelStyle}><strong>Customer Name:</strong></span>
												<span style={valueStyle}>{this.state.bookings.customer.customerName}</span>
											</div>
											<div>
												<span style={labelStyle}><strong>PAN:</strong></span>
												<span style={valueStyle}>{this.state.bookings.customer.panNum}</span>
											</div>
											<div>
												<span style={labelStyle}><strong>Mobile:</strong></span>
												<span style={valueStyle}>{this.state.bookings.customer.mobile}</span>
											</div>
											<div>
												<span style={labelStyle}><strong>Address:</strong></span>
												<span style={valueStyle}>{this.state.bookings.customer.address}</span>
											</div>
											<br />
											<div>
												<span style={labelStyle}><strong>Change Customer:</strong></span>
												<span style={valueStyle}>
													<div style={{ fontSize: '12px', width: '200px' }}>
														<Select
															onChange={this.handleCustomerSelect}
															options={this.state.customers?.map(i => ({
																value: i._id,
																label: i.customerName,
																panNum: i.panNum,
																mobile: i.mobile,
																address: i.address
															}))}
															menuPortalTarget={document.body}
															styles={{
																menuPortal: base => ({ ...base, zIndex: 9999 }),
																control: (provided) => ({ ...provided, minHeight: 30, height: 30, maxHeight: 30, padding: '0px' }),
																valueContainer: (provided) => ({ ...provided, padding: '0px 8px' }),
																option: (provided, state) => ({
																	...provided,
																	fontSize: '12px'
																}),
																singleValue: (provided, state) => ({
																	...provided,
																	fontSize: '12px',
																	transform: 'none',
																	margin: '0px'
																}),
															}}
														/>
													</div>
												</span>
											</div>
										</Col>
									</Row>
								</div>

								<br />
								<br />

								<Table size='sm' striped bordered hover responsive>
									<thead>
										<tr>
											<th style={{ width: '60%' }}>Particulars</th>
											<th style={{ textAlign: 'center', width: '10%' }}>Code No</th>
											<th style={{ textAlign: 'center', width: '10%' }}>Days</th>
											<th style={{ textAlign: 'center', width: '10%' }}>Qty</th>
											<th style={{ textAlign: 'center', width: '10%' }}>Action</th>
										</tr>
									</thead>
									<tbody>{this.state.bookings.booking.items?.map((item, index) => (
										<tr key={index}>
											<td>{item.particulars}</td>
											<td style={{ textAlign: 'center' }}>{item.codeNo}</td>
											<td style={{ textAlign: 'center' }}>{item.days}</td>
											<td style={{ textAlign: 'center' }}>{item.qty}</td>
											<td style={{ textAlign: 'center' }}>
												<Button
													variant='outline-danger'
													style={{ padding: '0.25rem 0.5rem', fontSize: '10px', marginRight: '5px' }}
													onClick={() => this.removeItem(item._id)}
												>
													Remove
												</Button>
											</td>
										</tr>
									))}</tbody>
								</Table>

								<br />
								<br />
								<h6>Add Equipment</h6>

								<Table size='sm' responsive style={{ border: 'none' }}>
									<thead>
										<tr style={{ border: 'none' }}>
											<th style={{ width: '60%', border: 'none' }}>Particulars</th>
											<th style={{ width: '10%', border: 'none' }} className="text-center">Code No</th>
											<th style={{ width: '10%', border: 'none' }} className="text-center">Days</th>
											<th style={{ width: '10%', border: 'none' }} className="text-center">Quantity</th>
											<th style={{ width: '10%', border: 'none' }} className="text-center">Action</th>
										</tr>
									</thead>
									<tbody>
										{this.state.items.map((i, index) => (
											<tr key={"Cr" + i + index}>
												<td>
													<Select
														onChange={e => this.handleEquipmentSelect(index, e)}
														options={this.state.equipments?.map(i => ({
															value: i.equipmentName,
															label: i.equipmentName + ' ' + i.code,
															codeNo: i.code,
															equipmentId: i._id
														}))}
														menuPortalTarget={document.body}
														styles={{
															menuPortal: base => ({ ...base, zIndex: 9999 }),
															control: (provided) => ({ ...provided, minHeight: 30, height: 30, maxHeight: 30, padding: '0px' }),
															valueContainer: (provided) => ({ ...provided, padding: '0px 8px' }),
															option: (provided, state) => ({
																...provided,
																fontSize: '12px'
															}),
															singleValue: (provided, state) => ({
																...provided,
																fontSize: '12px',
																transform: 'none',
																margin: '0px'
															}),
														}}
													/>
												</td>
												<td className="text-center">
													<Form.Control
														style={{ textAlign: "center", height: '30px', fontSize: "12px" }}
														type="text"
														name="codeNo"
														value={i.codeNo}
														onChange={e => this.handleRecordChange(index, e)}
														readOnly
													/>
												</td>
												<td className="text-center">
													<Form.Control
														style={{ textAlign: "center", height: '30px', fontSize: "12px" }}
														type="number"
														step="1"
														min={0}
														name="days"
														value={i.days}
														onChange={e => this.handleRecordChange(index, e)}
													/>
												</td>
												<td className="text-center">
													<Form.Control
														style={{ textAlign: "center", height: '30px', fontSize: "12px" }}
														type="number"
														step="1"
														min={0}
														name="qty"
														value={i.qty}
														onChange={e => this.handleRecordChange(index, e)}
													/>
												</td>
												<td className="text-center">
													<Button
														variant="outline-success"
														style={{ padding: '0.25rem 0.5rem', fontSize: '10px' }}
														size="sm"
														onClick={this.handleRecordAdd}
													>
														Add
													</Button>{' '}
												</td>
											</tr>
										))}
									</tbody>
								</Table>

								<br />
								<br />

								<div style={{ fontSize: '15px' }}>
									<Row>
										<Col>
											<div>
												<span style={labelStyle}><strong>Total Amount:</strong></span>
												<span style={valueStyle}>
													<Form.Control
														style={{ width: '200px', height: '20px' }}
														type="number"
														name="totalAmount"
														min={0}
														value={this.state.bookings.booking.totalAmount}
														onChange={this.handleChange}
													/>
												</span>
											</div>
											<div>
												<span style={labelStyle}><strong>Advance Payment:</strong></span>
												<span style={valueStyle}>
													<Form.Control
														style={{ width: '200px', height: '20px' }}
														type="number"
														name="advance"
														min={0}
														max={this.state.bookings.booking.totalAmount}
														value={this.state.bookings.booking.advance}
														onChange={this.handleChange}
													/>
												</span>
											</div>
											<div>
												<span style={labelStyle}><strong>Balance Amount:</strong></span>
												<span style={valueStyle}>
													<Form.Control
														style={{ width: '200px', height: '20px' }}
														type="number"
														name="balance"
														value={this.state.bookings.booking.balance}
														onChange={this.handleChange}
														readOnly
													/>
												</span>
											</div>
										</Col>
										<Col></Col>
									</Row>
									<br />
									<br />
									<div>
										<span style={labelStyle}><strong>Comments:</strong></span>
										<span>
											<Form.Control
												style={{ height: '30px', width: '370px' }}
												type="text"
												name="comments"
												value={this.state.bookings.booking.comments}
												onChange={this.handleChange}
											/>
										</span>
									</div>
								</div>

							</div>

							<div style={{ textAlign: 'right', fontSize: '12px' }}>
								User: <b>{this.state.submittedBy}</b>
							</div>

							<br />
							<Button
								variant="dark"
								size="sm"
								type="submit"
								disabled={this.state.buttonDisabled}
							>
								Save Changes
							</Button>{" "}
							<Button
								variant="outline-dark"
								size='sm'
								onClick={() => this.props.next()}
							>
								Cancel
							</Button>
						</Form>
					</div>
				}
			</div>
		)
	}
}

export default UpdateBooking