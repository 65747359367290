import httpService from "./httpService.js";


export async function registerUser(data) {
    return httpService.post(`/api/users`, data);
}

export async function forgetPassword(data) {
    return httpService.post(`/api/users/resetPassword`, data);
}

export async function resetPassword(data) {
    return httpService.post(`/api/users/storePassword`, data);
}

export async function getUserInfo(data) {
    return httpService.get(`/api/users/super`, data);
}