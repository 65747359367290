import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import PaymentForm from '../components/PaymentForm'
import MakePayment from '../components/MakePayment'
import SearchPayments from '../components/SearchPayments'
import ExplorePayments from '../components/ExplorePayments'
import UpdatePayment from '../components/UpdatePayment'
import PaymentCSV from '../components/PaymentCSV'
import { ToastContainer, toast } from 'react-toastify';
import { AiOutlineDownload } from "react-icons/ai";


class Payment extends Component {
	state = {
		view: "makePayment",
		bookingId: "",
		returnId: "",
		paymentId: ""
	}

	makePayment = () => {
		this.setState({
			view: "makePayment"
		})
	}

	paymentForm = (bookingId, returnId) => {
		this.setState({ bookingId, returnId }, () => {
			this.setState({ view: "paymentForm" })
		})
	}

	explore = () => {
		this.setState({
			view: "explore"
		})
	}

	search = () => {
		this.setState({
			view: "search"
		})
	}

	update = (paymentId) => {
		this.setState({ paymentId }, () => {
			this.setState({ view: "update" })
		})
	}

	csv = () => {
		if (this.props.user.isAdmin || this.props.user.isSuperAdmin) {
			this.setState({
				view: "csv"
			})
		}
		else {
			toast.error('Access Denied.')
		}
	}

	renderView = () => {
		if (this.state.view === "makePayment") {
			return (
				<div>
					<MakePayment user={this.props.user} paymentForm={this.paymentForm} />
				</div>
			)
		}
		else if (this.state.view === "paymentForm") {
			return (
				<div>
					<PaymentForm user={this.props.user} bookingId={this.state.bookingId} returnId={this.state.returnId} next={this.explore} />
				</div>
			)
		}
		else if (this.state.view === "search") {
			return (
				<div>
					<SearchPayments user={this.props.user} update={this.update} printPayment={this.props.printPayment} />
				</div>
			)
		}
		else if (this.state.view === "update") {
			return (
				<div>
					<UpdatePayment user={this.props.user} paymentId={this.state.paymentId} next={this.explore} />
				</div>
			)
		}
		else if (this.state.view === "explore") {
			return (
				<div>
					<ExplorePayments user={this.props.user} update={this.update} printPayment={this.props.printPayment} />
				</div>
			)
		}
		else if (this.state.view === "csv") {
			return (
				<div>
					<PaymentCSV user={this.props.user} />
				</div>
			)
		}
	}

	render() {
		return (
			<div>
				<Container>
					<ToastContainer position="bottom-right" hideProgressBar />
					<Button size='sm' variant="dark" onClick={this.makePayment}>
						Make Payment
					</Button>{" "}

					<Button size='sm' variant="dark" onClick={this.search}>
						Search
					</Button>{" "}

					<Button size='sm' variant="dark" onClick={this.explore}>
						History
					</Button>{" "}

					<span
						style={{
							cursor: 'pointer',
							color: 'black',
							marginLeft: '5px'
						}}
						onClick={() => { this.csv() }}
					>
						<AiOutlineDownload size={24} />
					</span>

					<hr />

					{this.renderView()}
				</Container>
			</div>
		)
	}
}

export default Payment