import React, { Component } from 'react'
import { getCustomers } from '../services/customerService';
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import { CSVLink } from "react-csv";
import CreateCustomer from '../components/CreateCustomer'
import ExploreCustomer from '../components/ExploreCustomer'
import CustomerPayments from '../components/CustomerPayments'


class Customer extends Component {
	_isMounted = false;

	state = {
		view: "explore",
		id: "",
		customerName: "",

		csvData: [],
		headers: [],

		loading: true
	}

	async componentDidMount() {
		this._isMounted = true;

		if (this._isMounted) {
			const { data: customers } = await getCustomers();

			const headersData = [
				{ label: "Customer Name", key: "customerName" },
				{ label: "PAN", key: "pan" },
				{ label: "Mobile", key: "mobile" },
				{ label: "Phone", key: "phone" },
				{ label: "Email", key: "email" },
				{ label: "Address", key: "address" }
			]

			const data = [];
			customers.forEach(i => {
				data.push(
					{
						customerName: i.customerName,
						pan: i.panNum,
						mobile: i.mobile,
						phone: i.phone,
						email: i.email,
						address: i.address
					}
				)
			});
			this.setState({ csvData: data, headers: headersData, loading: false })
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	customerForm = () => {
		this.setState({
			view: "customerForm"
		})
	}

	explore = () => {
		this.setState({
			view: "explore"
		})
	}

	payments = (id) => {
		this.setState({ id }, () => {
			this.setState({ view: "payments" })
		})
	}

	download = (c_id, c_name) => {
		this.setState({
			id: c_id,
			customerName: c_name
		})
		setTimeout(this.setState({ view: "download" }), 10)
	}

	renderView = () => {
		if (this.state.view === "explore") {
			return (
				<div>
					<ExploreCustomer user={this.props.user} handleDownload={this.download} payments={this.payments} printPayment={this.props.printPayment} />
				</div>
			)
		}
		else if (this.state.view === "payments") {
			return (
				<div>
					<CustomerPayments user={this.props.user} customerId={this.state.id} printPayment={this.props.printPayment} />
				</div>
			)
		}
		else if (this.state.view === "customerForm") {
			return (
				<div>
					<CreateCustomer />
				</div>
			)
		}
	}

	render() {
		return (
			<div>

				{this.state.loading
					?
					<div>
						<center>
							<br />
							<br />
							<br />
							<Spinner size="sm" animation="grow" variant="danger" />{' '}
							<Spinner size="sm" animation="grow" variant="warning" />{' '}
							<Spinner size="sm" animation="grow" variant="success" />
						</center>
					</div>
					:
					<div>
						<Container>
							<Button size='sm' variant="dark" onClick={this.explore}>
								Explore
							</Button>{' '}

							<Button size='sm' variant="dark" onClick={this.customerForm}>
								Add New
							</Button>{' '}

							<CSVLink
								filename={"customerDetails.csv"}
								data={this.state.csvData}
								headers={this.state.headers}
							>
								<Button size="sm" variant="outline-success" style={{ float: "right" }}>
									Download
								</Button>
							</CSVLink>

							<hr />

							{this.renderView()}

						</Container>
					</div>
				}
			</div>
		)
	}
}

export default Customer