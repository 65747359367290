import httpService from "./httpService.js";


export async function getBookings(pageNumber) {
    return httpService.get(`/api/booking?page=${pageNumber}`);
}

export async function getBookingsWithCustomer(pageNumber) {
    return httpService.get(`/api/booking/withCustomer?page=${pageNumber}`);
}

export async function getBookingsByCustomerId(customerId, pageNumber) {
    return httpService.get(`/api/booking/byCustomerId?customerId=${customerId}&page=${pageNumber}`);
}

export async function getUndeliveredBookings(pageNumber) {
    return httpService.get(`/api/booking/unDelivered?page=${pageNumber}`);
}

export async function getUnreturnedBookings(pageNumber) {
    return httpService.get(`/api/booking/unReturned?page=${pageNumber}`);
}

export async function getUnpaidBookings(pageNumber) {
    return httpService.get(`/api/booking/unPaid?page=${pageNumber}`);
}

export async function getNotification(date1, date2) {
    return httpService.get(`/api/booking/notify?date1=${date1}&date2=${date2}`);
}

export async function getBooking(id) {
    return httpService.get(`/api/booking/${id}`);
}

export async function getDetailedBooking(id) {
    return httpService.get(`/api/booking/detailed/${id}`);
}

export async function saveBooking(data) {
    if (!data._id) {
        return httpService.post(`/api/booking`, data);
    }

    const body = { ...data };
    delete body._id;
    return httpService.put(`/api/booking/${data._id}`, body);
}

export async function updateBooking(data, id) {
    return httpService.put(`/api/booking/${id}`, data);
}

export async function deleteBooking(id) {
    return httpService.delete(`/api/booking/${id}`);
}