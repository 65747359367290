import React, { Component } from 'react'

import { getEquipmentsByCategory } from '../services/equipmentService';

import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner'
import 'react-toastify/dist/ReactToastify.css'


class DisplayInventory extends Component {
	_isMounted = false;

	state = {
		equipments: [],

		loading: true
	}

	populateEquipments = async () => {
		const { data: equipments } = await getEquipmentsByCategory();
		this._isMounted && this.setState({ equipments });
	}

	async componentDidMount() {
		this._isMounted = true;

		this._isMounted && await this.populateEquipments();
		this._isMounted && this.setState({ loading: false });
	}

	componentWillUnmount() {
		this._isMounted = false;
	}


	render() {
		return (
			<div>
				{this.state.loading
					?
					<div>
						<center>
							<br />
							<br />
							<br />
							<Spinner size="sm" animation="grow" variant="danger" />{' '}
							<Spinner size="sm" animation="grow" variant="warning" />{' '}
							<Spinner size="sm" animation="grow" variant="success" />
						</center>
					</div>
					:
					<div>
						<br />
						{Object.keys(this.state.equipments).map((category) => (
							<div key={category}>
								<h3>{category}</h3>
								<Table variant='light' size='sm' striped bordered hover>
									<thead>
										<tr className="text-center">
											<th style={{ width: '30%' }} className="text-left">Equipment Name</th>
											<th style={{ width: '10%', textAlign: 'center' }}>Code</th>
											<th style={{ width: '10%', textAlign: 'center' }}>Stock</th>
											<th style={{ width: '10%', textAlign: 'center' }}>Booked</th>
											<th style={{ width: '10%', textAlign: 'center' }}>Out</th>
											<th style={{ width: '10%', textAlign: 'center' }}>Available</th>
											<th style={{ width: '10%', textAlign: 'center' }}>Remarks</th>
											<th style={{ width: '10%', textAlign: 'center' }}>Lost</th>
										</tr>
									</thead>
									<tbody>
										{this.state.equipments[category].map((equipment, index) => (
											<tr key={index}  className="text-center">
												<td className="text-left">{equipment.equipmentName}</td>
												<td>{equipment.code}</td>
												<td><b>{equipment.stock}</b></td>
												<td style={{ backgroundColor: equipment.booked > 0 ? '#FFDAB9' : '' }}>{equipment.booked}</td>
												<td style={{ backgroundColor: equipment.out > 0 ? '#FFC4C4' : '' }}>{equipment.out}</td>
												<td style={{ backgroundColor: (equipment.stock*1 - equipment.out*1 - equipment.lost*1) > 0 ? '#C4FFC4' : '' }}>{equipment.stock*1 - equipment.out*1 - equipment.lost*1}</td>
												<td style={{ backgroundColor: 'white' }}>{equipment.stock*1 - equipment.out*1 - equipment.booked*1 - equipment.lost*1}</td>
												<td style={{ backgroundColor: 'white', color: 'red' }}>{equipment.lost}</td>
											</tr>
										))}
									</tbody>
								</Table>
								<br />
							</div>
						))}
					</div>
				}
			</div>
		)
	}
}

export default DisplayInventory