import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import DisplayInventory from '../components/DisplayInventory'


class Inventory extends Component {
	state = {
		view: "display"
	}

	display = () => {
		this.setState({
			view: "display"
		})
	}

	renderView = () => {
		return <DisplayInventory />
	}

	render() {
		return (
			<div>
				<Container>
					<br />
					<h3>Inventory Section</h3>
					<br />
					
					{this.renderView()}
					
				</Container>			
			</div>
		)
	}
}

export default Inventory