import React, { Component } from 'react'
import { deleteBooking, getBookingsByCustomerId } from '../services/bookingService';
import { getCustomers } from '../services/customerService';
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'
import Form from 'react-bootstrap/Form'
import moment from 'moment'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { HiOutlineSwitchHorizontal } from "react-icons/hi";


class SearchBooking extends Component {
    _isMounted = false;

    state = {
        bookings: [],
        customerId: "",
        currentPage: 1,

        customers: [],

        view: false,
        viewStyle: 'table',
        buttonDisabled: false,
        selectDisabled: false,
        loading: true,
        loading_2: false
    }

    populateCustomers = async () => {
        const { data: customers } = await getCustomers();
        this._isMounted && this.setState({ customers })
    }

    async componentDidMount() {
        this._isMounted = true;
        this._isMounted && await this.populateCustomers()
        this._isMounted && this.setState({ loading: false })
    }

    nextPage = async () => {
        const { customerId, currentPage } = this.state;
        this.setState({ loading_2: true });

        const nextPage = currentPage + 1;

        const { data: bookings } = await getBookingsByCustomerId(customerId, nextPage);

        this.setState({
            bookings,
            currentPage: nextPage,
            loading_2: false,
        });
    };

    prevPage = async () => {
        const { customerId, currentPage } = this.state;
        if (currentPage === 1) return; // Prevent going to negative page number

        this.setState({ loading_2: true });

        const prevPage = currentPage - 1;

        const { data: bookings } = await getBookingsByCustomerId(customerId, prevPage);

        this.setState({
            bookings,
            currentPage: prevPage,
            loading_2: false,
        });
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    handlePageChange = pageNumber => {
        this.setState({ currentPage: pageNumber });
    };

    getButtonVariant = (booking) => {
        if (booking.isPaid) return 'outline-success';
        if (booking.isReturned) return 'outline-info';
        if (booking.isDelivered) return 'outline-warning';
        return 'outline-danger';
    };

    getStatusColor = (booking) => {
        if (booking.isPaid) return '#C4FFC4';
        if (booking.isReturned) return '#5bc0de';
        if (booking.isDelivered) return '#ffe57e';
        return '#FFC4C4';
    };

    toggleView = () => {
        this.setState((prevState) => ({
            viewStyle: prevState.viewStyle === 'table' ? 'block' : 'table',
        }));
    };

    deleteBooking = async (booking) => {
        if (booking.isDelivered || booking.isReturned || booking.isPaid) {
            toast.info('Booking has already been DELIVERED or RETURNED or PAID');
            return;
        } else {
            const shouldDelete = window.confirm('-----------------------------------------------------\n     Do you really want to delete this Booking ?\n-----------------------------------------------------');
            if (shouldDelete) {
                const { bookings } = this.state;
                const updatedBookings = bookings.filter((b) => b._id !== booking._id);

                this.setState({ bookings: updatedBookings });

                try {
                    await deleteBooking(booking._id);
                    toast.dark('Deleted successfully');
                } catch (ex) {
                    this.setState({ bookings });
                    toast.error('Failed to delete the booking. Please try again.');
                }
            }
        }
    };

    handleChange = event => {
        const { name, value } = event.target
        this.setState({
            [name]: value
        })
    };

    handleSubmit = async (event) => {
        event.preventDefault();

        if (this.state.customerId !== "") {
            this.setState({
                loading_2: true,
                buttonDisabled: true,
                selectDisabled: true
            })

            const { data: bookings } = await getBookingsByCustomerId(this.state.customerId, this.state.currentPage);
            this._isMounted && this.setState({ bookings })

            this.setState({
                view: true,
                loading_2: false
            })
        }
    };

    handleCustomerSelect = e => {
        this.setState({
            customerId: e.value,
            customerName: e.customerName
        })
    };

    handleReset = () => {
        this.setState({
            bookings: [],
            view: false,
            buttonDisabled: false,
            selectDisabled: false,
            loading_2: false
        })
    };

    renderBlock() {
        const { bookings } = this.state;
        return (
            <div>
                {bookings.map((booking, index) => (
                    <div key={booking._id}>
                        <Alert
                            key={booking.id}
                            style={{
                                backgroundColor: '',
                                borderColor: '#e0dcdc',
                                color: '#000',
                                marginBottom: '10px',
                            }}
                        >
                            <Button
                                disabled
                                variant={this.getButtonVariant(booking)}
                                className="float-right"
                            >
                                STATUS: {booking.isPaid
                                    ? 'Paid'
                                    : booking.isReturned
                                        ? 'Returned'
                                        : booking.isDelivered
                                            ? 'Delivered'
                                            : 'Booked'}
                            </Button>

                            <h4>{booking.program}</h4>
                            <strong>Event Date:</strong> {moment(booking.eventDate).format('MMMM DD, YYYY')}<br /><br />
                            <Row>
                                <Col>
                                    <strong>Bill Number:</strong> {booking.billNum}<br />
                                    <strong>Venue:</strong> {booking.venue}<br />
                                    <strong>Additional Phone 1:</strong> {booking.additional_phone}<br />
                                    <strong>Additional Phone 2:</strong> {booking.additional_phone_2}<br />

                                </Col>
                                <Col>
                                    <strong>Customer Name:</strong> {booking.customer.customerName}<br />
                                    <strong>PAN:</strong> {booking.customer.panNum}<br />
                                    <strong>Mobile:</strong> {booking.customer.mobile}<br />
                                    <strong>Address:</strong> {booking.customer.address}<br />
                                </Col>
                            </Row>
                            <br />
                            <div className="float-right" style={{ fontSize: '12px' }}>
                                <strong>Date:</strong> {moment(booking.date).format('MMMM DD, YYYY')}<br />
                                <strong>Submitted By:</strong> {booking.submittedBy}<br />
                            </div>
                            <br />
                            <Button
                                size='sm'
                                variant='outline-primary'
                                style={{ marginRight: 5 }}
                                onClick={() => {
                                    if (this.props.user.isAdmin || this.props.user.isSuperAdmin) {
                                        this.props.printAdvance(booking)
                                    }
                                    else {
                                        toast.error('Access Denied.')
                                    }
                                }}
                            >
                                Advance
                            </Button>
                            <Button
                                size='sm'
                                variant='outline-dark'
                                style={{ marginRight: 5 }}
                                onClick={() => this.props.printBooking(booking)}
                            >
                                Print
                            </Button>
                            <Button
                                size='sm'
                                variant='outline-warning'
                                style={{ marginRight: 5 }}
                                onClick={() => {
                                    if (this.props.user.isAdmin || this.props.user.isSuperAdmin) {
                                        if (booking.isDelivered || booking.isReturned || booking.isPaid) {
                                            toast.info('Booking has already been DELIVERED or RETURNED or PAID');
                                        }
                                        else { this.props.update(booking._id) }
                                    }
                                    else {
                                        toast.error('Access Denied.')
                                    }
                                }}
                                disabled={booking.isDelivered}
                            >
                                Edit
                            </Button>

                            <Button
                                size='sm'
                                variant='outline-danger'
                                onClick={() => {
                                    if (this.props.user.isAdmin || this.props.user.isSuperAdmin) {
                                        this.deleteBooking(booking)
                                    }
                                    else {
                                        toast.error('Access Denied.')
                                    }
                                }}
                            >
                                Delete
                            </Button>
                        </Alert>
                        <br />
                    </div>
                ))}
            </div>
        )
    }

    renderTable() {
        const { bookings } = this.state;

        return (
            <div style={{ fontSize: '14px' }}>
                <Table size='sm' bordered hover style={{ 'width': '100%', tableLayout: 'auto' }}>
                    <thead>
                        <tr className="text-center">
                            <th style={{ whiteSpace: 'nowrap' }}>Bill No.</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Event Date</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Program</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Venue</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Customer Name</th>
                            <th style={{ whiteSpace: 'nowrap' }}>PAN</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Mobile</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Address</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Date</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Submitted By</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Modified Date</th>
                            <th style={{ whiteSpace: 'nowrap' }}>STATUS</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {bookings.map((booking, index) => (
                            <tr key={booking._id}>
                                <td style={{ whiteSpace: 'nowrap' }}>{booking.billNum}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{moment(booking.eventDate).format('MMMM DD, YYYY')}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{booking.program}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{booking.venue}</td>
                                <td style={{ whiteSpace: 'nowrap', fontWeight: '500' }}>{booking.customer.customerName}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{booking.customer.panNum}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{booking.customer.mobile}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{booking.customer.address}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{moment(booking.date).format('MMMM DD, YYYY')}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{booking.submittedBy}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{moment(booking.dateModified).format('MMMM DD, YYYY')}</td>
                                <td className="text-center" style={{ backgroundColor: this.getStatusColor(booking), whiteSpace: 'nowrap' }}>
                                    {booking.isPaid
                                        ? 'Paid'
                                        : booking.isReturned
                                            ? 'Returned'
                                            : booking.isDelivered
                                                ? 'Delivered'
                                                : 'Booked'}
                                </td>
                                <td style={{ whiteSpace: 'nowrap' }}>
                                    <Button
                                        variant='outline-primary'
                                        style={{ padding: '0.25rem 0.5rem', fontSize: '10px', marginRight: '5px', marginLeft: '5px' }}
                                        onClick={() => {
                                            if (this.props.user.isAdmin || this.props.user.isSuperAdmin) {
                                                this.props.printAdvance(booking)
                                            }
                                            else {
                                                toast.error('Access Denied.')
                                            }
                                        }}
                                    >
                                        Advance
                                    </Button>
                                    <Button
                                        variant='outline-dark'
                                        style={{ padding: '0.25rem 0.5rem', fontSize: '10px', marginRight: '5px', marginLeft: '5px' }}
                                        onClick={() => this.props.printBooking(booking)}
                                    >
                                        Print
                                    </Button>
                                    <Button
                                        variant='outline-warning'
                                        style={{ padding: '0.25rem 0.5rem', fontSize: '10px', marginRight: '5px' }}
                                        onClick={() => {
                                            if (this.props.user.isAdmin || this.props.user.isSuperAdmin) {
                                                if (booking.isDelivered || booking.isReturned || booking.isPaid) {
                                                    toast.info('Booking has already been DELIVERED or RETURNED or PAID');
                                                }
                                                else { this.props.update(booking._id) }
                                            }
                                            else {
                                                toast.error('Access Denied.')
                                            }
                                        }}
                                        disabled={booking.isDelivered}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant='outline-danger'
                                        style={{ padding: '0.25rem 0.5rem', fontSize: '10px', marginRight: '5px' }}
                                        onClick={() => {
                                            if (this.props.user.isAdmin || this.props.user.isSuperAdmin) {
                                                this.deleteBooking(booking)
                                            }
                                            else {
                                                toast.error('Access Denied.')
                                            }
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }

    render() {
        const { bookings, loading, loading_2 } = this.state;

        return (
            <div>
                {loading ? (
                    <div>
                        <center>
                            <br />
                            <br />
                            <br />
                            <Spinner size="sm" animation="grow" variant="danger" />{' '}
                            <Spinner size="sm" animation="grow" variant="warning" />{' '}
                            <Spinner size="sm" animation="grow" variant="success" />
                        </center>
                    </div>
                ) : (
                    <div>
                        <br />
                        <Form onSubmit={this.handleSubmit}>
                            <Form.Row>
                                <Col xs={6}>
                                    <Form.Label>Customer Name / Mobile Number / PAN</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Label></Form.Label>
                                </Col>
                            </Form.Row>

                            <Form.Row>
                                <Col xs={4}>
                                    <Select
                                        onChange={this.handleCustomerSelect}
                                        options={this.state.customers?.map(i => {
                                            return ({
                                                value: i._id,
                                                label: `${i.customerName} [ ${i.mobile} ] ${i.panNum}`,
                                                customerName: i.customerName
                                            })
                                        })}
                                        isDisabled={this.state.selectDisabled}
                                    />
                                </Col>
                                <Col>
                                    <Button
                                        variant="dark"
                                        type="submit"
                                        disabled={this.state.buttonDisabled}
                                    >
                                        Choose
                                    </Button>
                                    {' '}
                                    <Button
                                        variant="danger"
                                        onClick={this.handleReset}
                                    >
                                        Reset
                                    </Button>
                                </Col>
                            </Form.Row>
                        </Form>

                        <br />

                        {loading_2 ? (
                            <div>
                                <center>
                                    <br />
                                    <br />
                                    <br />
                                    <Spinner size="sm" animation="grow" variant="danger" />{' '}
                                    <Spinner size="sm" animation="grow" variant="warning" />{' '}
                                    <Spinner size="sm" animation="grow" variant="success" />
                                </center>
                            </div>
                        ) : (
                            <div>
                                {this.state.view
                                    ?
                                    <div>
                                        <ToastContainer position="bottom-right" hideProgressBar />
                                        {bookings.length === 0 ? (
                                            <Alert variant="info">No more Bookings available.</Alert>
                                        ) : (
                                            <div>
                                                <br />
                                                <span
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={this.toggleView}
                                                >
                                                    <HiOutlineSwitchHorizontal size={18} />{' '}
                                                </span>
                                                {/* <Button variant='outline-dark' size='sm' onClick={this.toggleView}>Toggle View</Button> */}
                                                <br />
                                                <br />
                                                {(this.state.viewStyle === 'table') ? this.renderTable() : this.renderBlock()}
                                            </div>
                                        )}
                                        <br />
                                        <Button size='sm' variant='outline-dark' onClick={this.prevPage}>Previous</Button>
                                        <Button size='sm' variant='outline-dark' className="float-right" onClick={this.nextPage} disabled={(this.state.bookings.length === 0)}>Next</Button>
                                        <br /><br />
                                    </div>
                                    :
                                    <p>Choose a Customer Name or Mobile Number or PAN.</p>
                                }
                            </div>
                        )}
                    </div>
                )}
            </div >
        );
    }
}

export default SearchBooking;