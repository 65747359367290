import httpService from "./httpService.js";


export async function getEquipments() {
    return httpService.get(`/api/equipment`);
}

export async function getEquipmentsByCategory() {
    return httpService.get(`/api/equipment/byCategory`);
}

export async function getEquipment(id) {
    return httpService.get(`/api/equipment/${id}`);
}

export async function saveEquipment(data) {
    if (!data._id) {
        return httpService.post(`/api/equipment`, data);
    }

    const body = { ...data };
    delete body._id;
    return httpService.put(`/api/equipment/${data._id}`, body);
}

export async function updateEquipment(data, id) {
    return httpService.put(`/api/equipment/${id}`, data);
}

export async function deleteEquipment(id) {
    return httpService.delete(`/api/equipment/${id}`);
}