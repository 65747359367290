import React, { Component } from 'react'
import { saveCustomer, getCustomers } from '../services/customerService';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Joi from "joi-browser";
const company = require('./../company');


class CreateCustomerTwo extends Component {
    _isMounted = false;

    state = {

        customerName: "",
        panNum: "",
        mobile: "",
        phone: "",
        email: "",
        address: "",

        customers: [],

        buttonDisabled: false,
        loading: true
    }

    populateCustomers = async () => {
        const { data: customers } = await getCustomers();
        const p = [];

        customers.forEach(i => {
            p.push(i.panNum)
        })

        this._isMounted && this.setState({ customers: p })
    }

    async componentDidMount() {
        this._isMounted = true;

        this._isMounted && await this.populateCustomers();
        this._isMounted && this.setState({ loading: false })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    validateCustomer = (customer) => {
        const schema = {
            customerName: Joi.string()
                .min(2)
                .max(100)
                .required(),
            panNum: Joi.string()
                .max(1024)
                .allow(''),
            mobile: Joi.string()
                .max(1024)
                .allow(''),
            phone: Joi.string()
                .max(1024)
                .allow(''),
            email: Joi.string()
                .max(1024)
                .email()
                .allow(''),
            address: Joi.string()
                .max(1024)
                .allow('')
        };

        return Joi.validate(customer, schema);
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        const data = { ...this.state };
        delete data.buttonDisabled;
        delete data.customers;
        delete data.loading;

        const dupList = this.state.customers;

        const { error } = this.validateCustomer(data);
        if (error) {
            toast.error(error.details[0].message);
        } else if (data.panNum !== "" && dupList.includes(data.panNum)) {
            toast.error("PAN already in use");
        } else {
            this.setState({ buttonDisabled: true });
            try {
                const response = await saveCustomer(data);
                toast.dark("Added successfully");
                this.props.initiateBooking(response.data._id, response.data.customerName, response.data.panNum, response.data.mobile, response.data.address);
            } catch (error) {
                toast.error(error);
            }
        }
    };


    handleClear = async () => {
        await this.populateCustomers();

        this.setState({
            customerName: "",
            panNum: "",
            mobile: "",
            phone: "",
            email: "",
            address: "",

            buttonDisabled: false
        })
    }

    render() {
        return (
            <div>
                <ToastContainer hideProgressBar position="bottom-right" />

                {this.state.loading
                    ?
                    <div>
                        <center>
                            <br />
                            <br />
                            <br />
                            <Spinner size="sm" animation="grow" variant="danger" />{' '}
                            <Spinner size="sm" animation="grow" variant="warning" />{' '}
                            <Spinner size="sm" animation="grow" variant="success" />
                        </center>
                    </div>
                    :
                    <div>
                        <Form
                            style={{ fontSize: '12px', padding: '0.25rem 0.5rem' }}
                            onSubmit={this.handleSubmit}
                            onKeyDown={this.handleKeyPress}
                        >
                            <div style={{ backgroundColor: '#FAFAFA', padding: '20px' }}>
                                <center>
                                    {company.company}
                                    <h4>Create New Customer</h4>
                                    <p><br /></p>
                                </center>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            style={{ height: '30px', width: '400px' }}
                                            placeholder="Customer Name"
                                            name="customerName"
                                            value={this.state.customerName}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="">
                                        <Form.Label>PAN</Form.Label>
                                        <Form.Control
                                            type="text"
                                            style={{ height: '30px', width: '400px' }}
                                            placeholder="Permanent Account Number"
                                            name="panNum"
                                            value={this.state.panNum}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                </Form.Row>

                                <Form.Row>
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label>Mobile Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            style={{ height: '30px', width: '400px' }}
                                            placeholder="Mobile Number"
                                            name="mobile"
                                            value={this.state.mobile}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label>Phone Number</Form.Label>
                                        <Form.Control
                                            type="text"
                                            style={{ height: '30px', width: '400px' }}
                                            placeholder="Phone Number"
                                            name="phone"
                                            value={this.state.phone}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                </Form.Row>

                                <Form.Group controlId="">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control
                                        type="email"
                                        style={{ height: '30px', width: '400px' }}
                                        placeholder="abc@xyz.com"
                                        name="email"
                                        value={this.state.email}
                                        onChange={this.handleChange}
                                    />
                                </Form.Group>

                                <Form.Row>
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label>Address</Form.Label>
                                        <Form.Control
                                            type="text"
                                            style={{ height: '60px', width: '400px' }}
                                            name="address"
                                            value={this.state.address}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </div>
                            <br />

                            <Button
                                variant="dark"
                                type="submit"
                                size='sm'
                                disabled={this.state.buttonDisabled}
                            >
                                Create and Make Booking
                            </Button>

                            <Button
                                variant="danger"
                                className="ml-2"
                                size='sm'
                                onClick={this.handleClear}
                            >
                                Clear
                            </Button>
                        </Form>
                    </div>
                }
            </div>
        )
    }
}

export default CreateCustomerTwo
