import React, { Component } from 'react'
import { getSalariesByEmployeeId, deleteSalary } from '../services/salaryService';
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


class EmployeeSalary extends Component {
    _isMounted = false;

    state = {
        salaries: [],
        currentPage: 1,

        loading: true
    }

    populateSalary = async () => {
        const { data: salaries } = await getSalariesByEmployeeId(this.props.employeeId, this.state.currentPage);
        this._isMounted && this.setState({ salaries })
    }

    async componentDidMount() {
        this._isMounted = true;
        this._isMounted && await this.populateSalary()
        this._isMounted && this.setState({ loading: false })
    }

    nextPage = async () => {
        const { currentPage } = this.state;
        this.setState({ loading: true });

        const nextPage = currentPage + 1;
        const { data: salaries } = await getSalariesByEmployeeId(this.props.employeeId, nextPage);

        this.setState({
            salaries,
            currentPage: nextPage,
            loading: false,
        });
    };

    prevPage = async () => {
        const { currentPage } = this.state;
        if (currentPage === 1) return; // Prevent going to negative page number

        this.setState({ loading: true });
        const prevPage = currentPage - 1;
        const { data: salaries } = await getSalariesByEmployeeId(this.props.employeeId, prevPage);

        this.setState({
            salaries,
            currentPage: prevPage,
            loading: false,
        });
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    handlePageChange = pageNumber => {
        this.setState({ currentPage: pageNumber });
    };

    toggleView = () => {
        this.setState((prevState) => ({
            viewStyle: prevState.viewStyle === 'table' ? 'block' : 'table',
        }));
    };

    deleteSalary = async (salary) => {

        const shouldDelete = window.confirm('-----------------------------------------------------\n     Do you really want to delete this Salary ?\n-----------------------------------------------------');
        if (shouldDelete) {
            const { salaries } = this.state;
            const updatedSalaries = salaries.filter((b) => b._id !== salary._id);

            this.setState({ salaries: updatedSalaries });

            try {
                await deleteSalary(salary._id);
                toast.dark('Deleted successfully');
            } catch (ex) {
                this.setState({ salaries });
                toast.error('Failed to delete the salary. Please try again.');
            }
        }

    };

    renderTable() {
        const { salaries } = this.state;

        return (
            <div style={{ fontSize: '12px' }}>
                <Table size='sm' bordered hover style={{ 'width': '100%', tableLayout: 'auto' }}>
                    <thead>
                        <tr className="text-center" style={{background: '#ebf3f7'}}>
                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Date</th>
                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Employee Name</th>
                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Job Title</th>
                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Mobile</th>
                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>PAN</th>

                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Base Salary</th>
                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Bonus</th>
                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Deductions</th>
                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Net Payment</th>

                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Payment Method</th>
                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Bank</th>
                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Account Number</th>
                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Cheque Number</th>
                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Remarks</th>
                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Submitted By</th>
                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Date Modified</th>
                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {salaries.map((salary, index) => (
                            <tr key={salary._id}>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{moment(salary.date).format('MMMM DD, YYYY')}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center', fontWeight: '500' }}>{salary.employee.employeeName}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{salary.employee.jobTitle}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{salary.employee.phone}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{salary.employee.pan}</td>

                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center', backgroundColor: '#C4FFC4' }}>{salary.baseSalary}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center', backgroundColor: '#C4FFC4' }}>{salary.bonus}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center', backgroundColor: '#FFC4C4' }}>{salary.deductions}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center', backgroundColor: '#FFDAB9' }}>{salary.netPayment}</td>

                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{salary.paymentMethod}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{salary.bank}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{salary.accountNumber}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{salary.chequeNumber}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{salary.remarks}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{salary.submittedBy}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{moment(salary.dateModified).format('MMMM DD, YYYY')}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                                    <Button
                                        variant='outline-warning'
                                        style={{ padding: '0.25rem 0.5rem', fontSize: '10px', marginRight: '5px' }}
                                        onClick={() => this.props.update(salary._id)}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant='outline-danger'
                                        style={{ padding: '0.25rem 0.5rem', fontSize: '10px', marginRight: '5px' }}
                                        onClick={() => this.deleteSalary(salary)}
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }

    render() {
        const { salaries, loading } = this.state;

        return (
            <div>
                {loading ? (
                    <div>
                        <center>
                            <br />
                            <br />
                            <br />
                            <Spinner size="sm" animation="grow" variant="danger" />{' '}
                            <Spinner size="sm" animation="grow" variant="warning" />{' '}
                            <Spinner size="sm" animation="grow" variant="success" />
                        </center>
                    </div>
                ) : (
                    <div>
                        <ToastContainer position="bottom-right" hideProgressBar />
                        {salaries.length === 0 ? (
                            <Alert variant="info">No more Salary available.</Alert>
                        ) : (
                            <div>
                                <br />
                                { this.renderTable() }
                            </div>
                        )}
                    </div>
                )}
                <br />
                <Button size='sm' variant='outline-dark' onClick={this.prevPage}>Previous</Button>
                <Button size='sm' variant='outline-dark' className="float-right" onClick={this.nextPage} disabled={(this.state.salaries.length === 0)}>Next</Button>
                <br /><br />
            </div>
        );
    }
}

export default EmployeeSalary;