import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import ExpenseForm from '../components/ExpenseForm'
import ExploreExpenses from '../components/ExploreExpenses'
import UpdateExpense from '../components/UpdateExpense'
import ExpenseCSV from '../components/ExpenseCSV'
import { ToastContainer, toast } from 'react-toastify';
import { AiOutlineDownload } from "react-icons/ai";


class Expense extends Component {
    state = {
        view: "expenseForm",
        expenseId: ""
    }

    addExpense = () => {
        this.setState({
            view: "expenseForm"
        })
    }

    explore = () => {
        if (this.props.user.isAdmin || this.props.user.isSuperAdmin) {
            this.setState({
                view: "explore"
            })
        }
        else {
            toast.error('Access Denied.')
        }
    }

    update = (id) => {
        this.setState({
            expenseId: id
        }, () => {
            this.setState({ view: "update" })
        })
    }

    csv = () => {
		if (this.props.user.isAdmin || this.props.user.isSuperAdmin) {
			this.setState({
				view: "csv"
			})
		}
		else {
			toast.error('Access Denied.')
		}
	}

    renderView = () => {
        if (this.state.view === "expenseForm") {
            return (
                <div>
                    <ExpenseForm user={this.props.user} />
                </div>
            )
        }
        else if (this.state.view === "explore") {
            return (
                <div>
                    <ExploreExpenses user={this.props.user} update={this.update} />
                </div>
            )
        }
        else if (this.state.view === "update") {
            return (
                <div>
                    <UpdateExpense user={this.props.user} expenseId={this.state.expenseId} next={this.explore} />
                </div>
            )
        }
        else if (this.state.view === "csv") {
			return (
				<div>
					<ExpenseCSV user={this.props.user} />
				</div>
			)
		}
    }

    render() {
        return (
            <div>
                <Container>
                    <ToastContainer position="bottom-right" hideProgressBar />
                    <Button size='sm' variant="dark" onClick={this.addExpense}>
                        Add Expense
                    </Button>{" "}

                    <Button size='sm' variant="dark" onClick={this.explore}>
                        History
                    </Button>{" "}

                    <span
                        style={{
                            cursor: 'pointer',
                            color: 'black',
                            marginLeft: '5px'
                        }}
                        onClick={() => { this.csv() }}
                    >
                        <AiOutlineDownload size={24} />
                    </span>

                    <hr />

                    {this.renderView()}
                </Container>
            </div>
        )
    }
}

export default Expense