import React, { Component } from 'react'
import { saveSalary } from '../services/salaryService'
import { getEmployees } from '../services/employeeService';
import { getCurrentDate } from '../utils/utils';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Joi from "joi-browser";
const company = require('./../company');


class PaySalary extends Component {
	_isMounted = false;

	state = {
		date: "",
		employeeId: "",
		employeeName: "",
		phone: "",
		panNum: "",
		paymentMethod: "",
		bank: "",
		accountNumber: "",
		chequeNumber: "",

		baseSalary: 0.0,
		bonus: 0.0,
		deductions: 0.0,
		netPayment: 0.0,
		remarks: "",

		employees: [],

		userId: "",
		submittedBy: "",
		buttonDisabled: false,
		loading: true
	}

	populateEmployees = async () => {
		const { data: employees } = await getEmployees();
		this._isMounted && this.setState({ employees })
	}

	async componentDidMount() {
		this._isMounted = true;

		this._isMounted && await this.populateEmployees();
		this._isMounted && this.setState({
			loading: false,
			submittedBy: this.props.user.name,
			userId: this.props.user._id,
			date: getCurrentDate()
		})
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleChange = event => {
		this.setState({
			[event.target.name]: event.target.value
		}, () => {
			this.calculate()
		});
	}

	handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	}

	calculate = () => {
		this.setState({
			netPayment: this.state.baseSalary * 1 + this.state.bonus * 1 - this.state.deductions * 1
		});
	}

	handleEmployeeSelect = async e => {
		this.setState({
			employeeId: e.value,
			employeeName: e.label,
			panNum: e.pan,
			phone: e.phone
		})
	}

	validateSalary = (salary) => {
		const schema = {
			date: Joi.date().required(),
			employeeId: Joi.string().required(),
			paymentMethod: Joi.string().required(),
			bank: Joi.string().allow(''),
			accountNumber: Joi.string().allow(''),
			chequeNumber: Joi.string().allow(''),
			baseSalary: Joi.number().min(0).required(),
			bonus: Joi.number().min(0).allow(''),
			deductions: Joi.number().min(0).allow(''),
			netPayment: Joi.number().allow(''),
			remarks: Joi.string().max(1024).allow(''),
			userId: Joi.string().required(),
			submittedBy: Joi.string().required(),
		};

		return Joi.validate(salary, schema);
	}

	handleSubmit = async (event) => {
		event.preventDefault();

		const data = { ...this.state };
		delete data.employees;
		delete data.buttonDisabled;
		delete data.loading;
		delete data.employeeName;
		delete data.panNum;
		delete data.phone;

		if (this.state.paymentMethod === "Cash") {
			data.bank = "";
			data.accountNumber = "";
			data.chequeNumber = "";
		}

		const { error } = this.validateSalary(data)
		if (error) {
			toast.error(error.details[0].message)
		}
		else {
			this.setState({ buttonDisabled: true });
			try {
				await saveSalary(data);
				toast.dark("Payment added successfully")

				this.props.next();
			}
			catch (error) {
				toast.error(error);
			}
		}
	}

	handleClear = async () => {
		this.setState({
			bank: "",
			accountNumber: "",
			chequeNumber: "",
			baseSalary: 0.0,
			bonus: 0.0,
			deductions: 0.0,
			netPayment: 0.0,
			remarks: "",
			buttonDisabled: false,
		})
	}

	render() {
		const labelStyle = {
			display: 'inline-block',
			width: '160px',
			marginRight: '10px',
			textAlign: 'left'
		};

		const valueStyle = {
			display: 'inline-block'
		};

		return (
			<div>
				<ToastContainer hideProgressBar position="bottom-right" />

				{this.state.loading
					?
					<div>
						<center>
							<br />
							<br />
							<br />
							<Spinner size="sm" animation="grow" variant="danger" />{' '}
							<Spinner size="sm" animation="grow" variant="warning" />{' '}
							<Spinner size="sm" animation="grow" variant="success" />
						</center>
					</div>
					:
					<div>

						<Form onSubmit={this.handleSubmit} onKeyDown={this.handleKeyPress} style={{ fontSize: '12px', padding: '0.25rem 0.5rem' }}>
							<div style={{ backgroundColor: '#F0F7FF', padding: '20px' }}>
								<center>
									{company.company}
									<h4>Salary Form</h4>
									<p><br /></p>
								</center>
								<div style={{ fontSize: '15px' }}>
									<Row>
										<Col>
											<div>
												<span style={labelStyle}><strong>Date:</strong></span>
												<span style={valueStyle}>
													<Form.Control
														style={{ width: '200px', height: '20px' }}
														type="date"
														name="date"
														value={this.state.date}
														onChange={this.handleChange}
													/>
												</span>
											</div>
											<br />
											<div>
												<span style={labelStyle}><strong>Base Salary:</strong></span>
												<span style={valueStyle}>
													<Form.Control
														style={{ width: '200px', height: '20px', border: '1px solid green' }}
														type="number"
														step="1"
														min={0}
														name="baseSalary"
														value={this.state.baseSalary}
														onChange={this.handleChange}
													/>
												</span>
											</div>
											<div>
												<span style={labelStyle}><strong>Bonus:</strong></span>
												<span style={valueStyle}>
													<Form.Control
														style={{ width: '200px', height: '20px', border: '1px solid green' }}
														type="number"
														step="1"
														min={0}
														name="bonus"
														value={this.state.bonus}
														onChange={this.handleChange}
													/>
												</span>
											</div>
											<div>
												<span style={labelStyle}><strong>Deductions:</strong></span>
												<span style={valueStyle}>
													<Form.Control
														style={{ width: '200px', height: '20px', border: '1px solid red' }}
														type="number"
														step="1"
														max={this.state.baseSalary}
														name="deductions"
														value={this.state.deductions}
														onChange={this.handleChange}
													/>
												</span>
											</div>
											<div>
												<span style={labelStyle}><strong>Net Payment:</strong></span>
												<span style={valueStyle}>
													<Form.Control
														style={{ width: '200px', height: '20px' }}
														type="number"
														name="netPayment"
														value={this.state.netPayment}
														onChange={this.handleChange}
														readOnly
													/>
												</span>
											</div>
										</Col>
										<Col>
											<div>
												<span style={labelStyle}><strong>Choose Employee:</strong></span>
												<span style={valueStyle}>
													<div style={{ fontSize: '12px', width: '200px' }}>
														<Select
															onChange={this.handleEmployeeSelect}
															options={this.state.employees?.map(i => ({
																value: i._id,
																label: i.employeeName,
																pan: i.pan,
																phone: i.phone
															}))}
															menuPortalTarget={document.body}
															styles={{
																menuPortal: base => ({ ...base, zIndex: 9999 }),
																control: (provided) => ({ ...provided, minHeight: 30, height: 30, maxHeight: 30, padding: '0px' }),
																valueContainer: (provided) => ({ ...provided, padding: '0px 8px' }),
																option: (provided, state) => ({
																	...provided,
																	fontSize: '12px'
																}),
																singleValue: (provided, state) => ({
																	...provided,
																	fontSize: '12px',
																	transform: 'none',
																	margin: '0px'
																}),
															}}
														/>
													</div>
												</span>
											</div>

											<br />

											<div>
												<span style={labelStyle}><strong>Employee Name:</strong></span>
												<span style={valueStyle}>{this.state.employeeName}</span>
											</div>
											<div>
												<span style={labelStyle}><strong>Contact Number:</strong></span>
												<span style={valueStyle}>{this.state.phone}</span>
											</div>
											<div>
												<span style={labelStyle}><strong>PAN:</strong></span>
												<span style={valueStyle}>{this.state.panNum}</span>
											</div>
										</Col>
									</Row>

									<br /><br />

									<Row>
										<Col xs={2}><Form.Label>Payment Method</Form.Label></Col>
										<Col>
											<Form.Check
												id="paymentMethod"
												inline
												type="radio"
												label="Cheque"
												value="Cheque"
												name="paymentMethod"
												onChange={this.handleChange}
											/>

											<Form.Check
												id="paymentMethod"
												inline
												type="radio"
												label="Cash"
												value="Cash"
												name="paymentMethod"
												onChange={this.handleChange}
											/>
										</Col>
									</Row>
									<br />

									{this.state.paymentMethod === "Cheque"
										?
										<div>
											<Form.Row>
												<Form.Group as={Col} controlId="">
													<Form.Label>Bank Name</Form.Label>
													<Form.Control
														style={{ height: '30px', width: '370px' }}
														type="text"
														name="bank"
														value={this.state.bank}
														onChange={this.handleChange}
													/>
												</Form.Group>

												<Form.Group as={Col} controlId="">
													<Form.Label>Account Number</Form.Label>
													<Form.Control
														style={{ height: '30px', width: '370px' }}
														type="text"
														name="accountNumber"
														value={this.state.accountNumber}
														onChange={this.handleChange}
													/>
												</Form.Group>
											</Form.Row>

											<Form.Group controlId="">
												<Form.Label>Cheque Number</Form.Label>
												<Form.Control
													style={{ height: '30px', width: '370px' }}
													type="text"
													name="chequeNumber"
													value={this.state.chequeNumber}
													onChange={this.handleChange}
												/>
											</Form.Group>

										</div>
										:
										<div></div>
									}

									<Form.Group controlId="">
										<Form.Label>Remarks</Form.Label>
										<Form.Control
											style={{ height: '30px', width: '370px' }}
											type="text"
											name="remarks"
											value={this.state.remarks}
											onChange={this.handleChange}
										/>
									</Form.Group>
								</div>
							</div>
							<div style={{ textAlign: 'right', fontSize: '12px' }}>
								Submitted By: <b>{this.state.submittedBy}</b>
							</div>

							<br />
							<Button
								variant="dark"
								type="submit"
								size='sm'
								disabled={this.state.buttonDisabled}
							>
								Confirm Payment
							</Button>

							<Button
								variant="danger"
								className="ml-2"
								size='sm'
								onClick={this.handleClear}
							>
								Clear
							</Button>
						</Form>
					</div>
				}
			</div>
		)
	}
}

export default PaySalary