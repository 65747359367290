import React, { Component } from 'react'
import { getExpenses, deleteExpense } from '../services/expenseService';
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
const company = require('./../company');


class ExploreExpenses extends Component {
    _isMounted = false;

    state = {
        expenses: [],
        currentPage: 1,

        loading: true
    }

    populateExpenses = async () => {
        const { data: expenses } = await getExpenses(this.state.currentPage);
        this._isMounted && this.setState({ expenses })
    }

    async componentDidMount() {
        this._isMounted = true;
        this._isMounted && await this.populateExpenses()
        this._isMounted && this.setState({ loading: false })
    }

    nextPage = async () => {
        const { currentPage } = this.state;
        this.setState({ loading: true });

        const nextPage = currentPage + 1;
        const { data: expenses } = await getExpenses(nextPage);

        this.setState({
            expenses,
            currentPage: nextPage,
            loading: false,
        });
    };

    prevPage = async () => {
        const { currentPage } = this.state;
        if (currentPage === 1) return; // Prevent going to negative page number
        this.setState({ loading: true });

        const prevPage = currentPage - 1;
        const { data: expenses } = await getExpenses(prevPage);

        this.setState({
            expenses,
            currentPage: prevPage,
            loading: false,
        });
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    handlePageChange = pageNumber => {
        this.setState({ currentPage: pageNumber });
    };

    deleteExpense = async (expense) => {
        const shouldDelete = window.confirm('-----------------------------------------------------\n     Do you really want to delete this Expenses ?\n-----------------------------------------------------');
            if (shouldDelete) {
                const { expenses } = this.state;
                const updatedExpenses = expenses.filter((b) => b._id !== expense._id);

                this.setState({ expenses: updatedExpenses });

                try {
                    await deleteExpense(expense._id);
                    toast.dark('Deleted successfully');
                } catch (ex) {
                    this.setState({ expenses });
                    toast.error('Failed to delete the expense. Please try again.');
                }
            }
    };

    renderTable() {
        const { expenses } = this.state;

        return (
            <div style={{ fontSize: '12px' }}>
                <Table size='sm' bordered hover style={{ 'width': '100%', tableLayout: 'auto' }}>
                    <thead>
                        <tr className="text-center" style={{background: '#ebf3f7'}}>
                            <th style={{ whiteSpace: 'nowrap', 'width': '10%' }}>Date</th>
                            <th style={{ whiteSpace: 'nowrap', 'width': '20%' }}>Particulars</th>
                            <th style={{ whiteSpace: 'nowrap', 'width': '10%' }}>Amount</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Remarks</th>
                            <th style={{ whiteSpace: 'nowrap', 'width': '10%' }}>Submitted By</th>
                            <th style={{ whiteSpace: 'nowrap', 'width': '10%' }}>Modified Date</th>
                            <th style={{ whiteSpace: 'nowrap', 'width': '10%' }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {expenses.map((expense, index) => (
                            <tr key={expense._id}>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{moment(expense.date).format('MMMM DD, YYYY')}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{expense.particulars}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{expense.amount}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{expense.remarks}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{expense.submittedBy}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{moment(expense.dateModified).format('MMMM DD, YYYY')}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                                    <Button
                                        variant='outline-warning'
                                        style={{ padding: '0.25rem 0.5rem', fontSize: '10px', marginRight: '5px' }}
                                        onClick={() => this.props.update(expense._id)}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant='outline-danger'
                                        style={{ padding: '0.25rem 0.5rem', fontSize: '10px', marginRight: '5px' }}
                                        onClick={() => this.deleteExpense(expense)}
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }

    render() {
        const { expenses, loading } = this.state;

        return (
            <div>
                {loading ? (
                    <div>
                        <center>
                            <br />
                            <br />
                            <br />
                            <Spinner size="sm" animation="grow" variant="danger" />{' '}
                            <Spinner size="sm" animation="grow" variant="warning" />{' '}
                            <Spinner size="sm" animation="grow" variant="success" />
                        </center>
                    </div>
                ) : (
                    <div>
                        <ToastContainer position="bottom-right" hideProgressBar />
                        {expenses.length === 0 ? (
                            <Alert variant="info">No more Expenses available.</Alert>
                        ) : (
                            <div>
                                <center>
                                    {company.company}
                                    <h4>Expenses</h4>
                                    <p><br /></p>
                                </center>
                                
                                { this.renderTable() }
                            </div>
                        )}
                    </div>
                )}
                <br />
                <Button size='sm' variant='outline-dark' onClick={this.prevPage}>Previous</Button>
                <Button size='sm' variant='outline-dark' className="float-right" onClick={this.nextPage} disabled={(this.state.expenses.length === 0)}>Next</Button>
                <br /><br />
            </div>
        );
    }
}

export default ExploreExpenses;