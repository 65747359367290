import React, { Component } from 'react'
import { getPaymentsWithCustomer, deletePayment } from '../services/paymentService';
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


class ExplorePayments extends Component {
    _isMounted = false;

    state = {
        payments: [],
        currentPage: 1,

        loading: true,
        viewStyle: 'table'
    }

    populatePayment = async () => {
        const { data: payments } = await getPaymentsWithCustomer(this.state.currentPage);
        this._isMounted && this.setState({ payments })
    }

    async componentDidMount() {
        this._isMounted = true;
        this._isMounted && await this.populatePayment()
        this._isMounted && this.setState({ loading: false })
    }

    nextPage = async () => {
        const { currentPage } = this.state;
        this.setState({ loading: true });

        const nextPage = currentPage + 1;
        const { data: payments } = await getPaymentsWithCustomer(nextPage);

        this.setState({
            payments,
            currentPage: nextPage,
            loading: false,
        });
    };

    prevPage = async () => {
        const { currentPage } = this.state;
        if (currentPage === 1) return; // Prevent going to negative page number

        this.setState({ loading: true });
        const prevPage = currentPage - 1;
        const { data: payments } = await getPaymentsWithCustomer(prevPage);

        this.setState({
            payments,
            currentPage: prevPage,
            loading: false,
        });
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    handlePageChange = pageNumber => {
        this.setState({ currentPage: pageNumber });
    };

    toggleView = () => {
        this.setState((prevState) => ({
            viewStyle: prevState.viewStyle === 'table' ? 'block' : 'table',
        }));
    };

    deletePayment = async (payment) => {

        const shouldDelete = window.confirm('-----------------------------------------------------\n     Do you really want to delete this Payment ?\n-----------------------------------------------------');
        if (shouldDelete) {
            const { payments } = this.state;
            const updatedPayments = payments.filter((b) => b._id !== payment._id);

            this.setState({ payments: updatedPayments });

            try {
                await deletePayment(payment._id);
                toast.dark('Deleted successfully');
            } catch (ex) {
                this.setState({ payments });
                toast.error('Failed to delete the payment. Please try again.');
            }
        }

    };

    renderBlock() {
        const { payments } = this.state;
        return (
            <div>
                {payments.map((payment, index) => (
                    <div key={payment._id}>
                        <Alert
                            key={payment.id}
                            style={{
                                backgroundColor: '',
                                borderColor: '#e0dcdc',
                                color: '#000',
                                marginBottom: '10px',
                            }}
                        >
                            <Button
                                disabled
                                variant='outline-success'
                                className="float-right"
                            >
                                STATUS: Paid
                            </Button>

                            <h4>{payment.booking.program}</h4>
                            <strong>Event Date:</strong> {moment(payment.booking.eventDate).format('MMMM DD, YYYY')}<br />
                            <strong>Venue:</strong> {payment.booking.venue}<br /><br />
                            <Row>
                                <Col>
                                    <strong>Bill Number:</strong> {payment.booking.billNum}<br />
                                    <strong>Booking Amount:</strong> {payment.totalAmount}<br />
                                    <strong>Discount:</strong> {payment.discount}<br />
                                    <strong>Advance Payment:</strong> {payment.booking.advance}<br />
                                    <strong>Payment Amount:</strong> {payment.totalPaid}<br />
                                    <strong>Total Amount:</strong> {payment.booking.advance * 1 + payment.totalPaid * 1}<br />
                                </Col>
                                <Col>
                                    <strong>Customer Name:</strong> {payment.customer.customerName}<br />
                                    <strong>PAN:</strong> {payment.customer.panNum}<br />
                                    <strong>Mobile:</strong> {payment.customer.mobile}<br />
                                    <strong>Address:</strong> {payment.customer.address}<br />
                                </Col>
                            </Row>
                            <br />
                            <div className="float-right" style={{ fontSize: '12px' }}>
                                <strong>Date:</strong> {moment(payment.date).format('MMMM DD, YYYY')}<br />
                                <strong>Submitted By:</strong> {payment.submittedBy}<br />
                            </div>
                            <br />
                            <Button
                                size='sm'
                                variant='outline-dark'
                                style={{ marginRight: 5 }}
                                onClick={() => this.props.printPayment(payment)}
                            >
                                Print
                            </Button>
                            <Button
                                size='sm'
                                variant='outline-warning'
                                style={{ marginRight: 5 }}
                                onClick={() => this.props.update(payment._id)}
                            >
                                Edit
                            </Button>

                            <Button
                                size='sm'
                                variant='outline-danger'
                                onClick={() => this.deletePayment(payment)}
                            >
                                Delete
                            </Button>
                        </Alert>
                        <br />
                    </div>
                ))}
            </div>
        )
    }

    renderTable() {
        const { payments } = this.state;

        return (
            <div style={{ fontSize: '14px' }}>
                <Table size='sm' bordered hover style={{ 'width': '100%', tableLayout: 'auto' }}>
                    <thead>
                        <tr className="text-center">
                            <th style={{ whiteSpace: 'nowrap' }}>Bill No.</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Payment Date</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Event Date</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Program</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Venue</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Customer Name</th>
                            <th style={{ whiteSpace: 'nowrap' }}>PAN</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Mobile</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Address</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Booking Amount</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Settlement Amount</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Discount</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Advance Payment</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Payment Amount</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Total Amount</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Submitted By</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Modified Date</th>
                            <th style={{ whiteSpace: 'nowrap' }}>STATUS</th>
                            <th style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {payments.map((payment, index) => (
                            <tr key={payment._id}>
                                <td style={{ whiteSpace: 'nowrap' }}>{payment.booking.billNum}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{moment(payment.date).format('MMMM DD, YYYY')}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{moment(payment.booking.eventDate).format('MMMM DD, YYYY')}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{payment.booking.program}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{payment.booking.venue}</td>
                                <td style={{ whiteSpace: 'nowrap', fontWeight: '500' }}>{payment.customer.customerName}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{payment.customer.panNum}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{payment.customer.mobile}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{payment.customer.address}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{payment.totalAmount-parseInt(payment.settlementAmount)}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{payment.settlementAmount}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{payment.discount}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{payment.booking.advance}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{payment.totalPaid}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{payment.totalPaid * 1 + payment.booking.advance * 1}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{payment.submittedBy}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{moment(payment.dateModified).format('MMMM DD, YYYY')}</td>
                                <td className="text-center" style={{ backgroundColor: '#C4FFC4', whiteSpace: 'nowrap' }}>Paid</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                                    <Button
                                        variant='outline-dark'
                                        style={{ padding: '0.25rem 0.5rem', fontSize: '10px', marginRight: '5px', marginLeft: '5px' }}
                                        onClick={() => this.props.printPayment(payment)}
                                    >
                                        Print
                                    </Button>
                                    <Button
                                        variant='outline-warning'
                                        style={{ padding: '0.25rem 0.5rem', fontSize: '10px', marginRight: '5px' }}
                                        onClick={() => this.props.update(payment._id)}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant='outline-danger'
                                        style={{ padding: '0.25rem 0.5rem', fontSize: '10px', marginRight: '5px' }}
                                        onClick={() => this.deletePayment(payment)}
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }

    render() {
        const { payments, loading } = this.state;

        return (
            <div>
                {loading ? (
                    <div>
                        <center>
                            <br />
                            <br />
                            <br />
                            <Spinner size="sm" animation="grow" variant="danger" />{' '}
                            <Spinner size="sm" animation="grow" variant="warning" />{' '}
                            <Spinner size="sm" animation="grow" variant="success" />
                        </center>
                    </div>
                ) : (
                    <div>
                        <ToastContainer position="bottom-right" hideProgressBar />
                        {payments.length === 0 ? (
                            <Alert variant="info">No more Payments available.</Alert>
                        ) : (
                            <div>
                                <br />
                                <Button variant='outline-dark' size='sm' onClick={this.toggleView}>Toggle View</Button>
                                <br />
                                <br />
                                {(this.state.viewStyle === 'table') ? this.renderTable() : this.renderBlock()}
                            </div>
                        )}
                    </div>
                )}
                <br />
                <Button size='sm' variant='outline-dark' onClick={this.prevPage}>Previous</Button>
                <Button size='sm' variant='outline-dark' className="float-right" onClick={this.nextPage} disabled={(this.state.payments.length === 0)}>Next</Button>
                <br /><br />
            </div>
        );
    }
}

export default ExplorePayments;