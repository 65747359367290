import httpService from "./httpService.js";


export async function getDeliveries(pageNumber) {
    return httpService.get(`/api/delivery?page=${pageNumber}`);
}

export async function getDeliveriesWithCustomer(pageNumber) {
    return httpService.get(`/api/delivery/withCustomer?page=${pageNumber}`);
}

export async function getDeliveriesByCustomerId(customerId, pageNumber) {
    return httpService.get(`/api/delivery/byCustomerId?customerId=${customerId}&page=${pageNumber}`);
}

export async function getDelivery(id) {
    return httpService.get(`/api/delivery/${id}`);
}

export async function getDetailedDelivery(id) {
    return httpService.get(`/api/delivery/detailed/${id}`);
}

export async function saveDelivery(data) {
    if (!data._id) {
        return httpService.post(`/api/delivery`, data);
    }

    const body = { ...data };
    delete body._id;
    return httpService.put(`/api/delivery/${data._id}`, body);
}

export async function updateDelivery(data, id) {
    return httpService.put(`/api/delivery/${id}`, data);
}

export async function deleteDelivery(id) {
    return httpService.delete(`/api/delivery/${id}`);
}