import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Alert from 'react-bootstrap/Alert'
import Notification from '../components/Notification';
const company = require('./../company');


class Welcome extends Component {
	render() {
		return (
			<div>
				<Container>
					<Alert variant="light">
						<div style={{ color: '#36454F' }}><center>
							<Alert.Heading>{company.app}</Alert.Heading>
							<p>Welcome To {company.system}</p>
							<hr />
							<br /><br />
							<Notification printBooking={this.props.printBooking}/>
							{/* <p>
						<br /><br />
						Developed By Sunil Gautam<br />
						2023<br /><br />
						
						Contact:<br />
						gautam.sunil7725@gmail.com<br />
					</p> */}
						</center>
						</div>
					</Alert>
				</Container>
			</div>
		)
	}
}

export default Welcome