import React, { Component } from 'react';
// import { getUserInfo, deleteUser } from '../services/userService';
import { getUserInfo } from '../services/userService';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class SuperUser extends Component {
	_isMounted = false;

	state = {
		users: [],
		loading: true,
	};

	async componentDidMount() {
		this._isMounted = true;

		if (this._isMounted) {
			try {
				const { data: users } = await getUserInfo();
				this.setState({ users });
			} catch (error) {
				console.error('Error while fetching user info:', error);
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleDelete = async (id) => {
		// Show a confirmation dialog
		const shouldDelete = window.confirm('-----------------------------------------------------\n     Do you really want to delete this user ?\n-----------------------------------------------------');
		if (shouldDelete) {
			try {
				// Call the delete service function passing the user ID
				// await deleteUser(id);

				// Show a success toast message
				toast.success('User deleted successfully.');

				// Update the state by removing the deleted user from the list
				this.setState((prevState) => ({
					users: prevState.users.filter((user) => user._id !== id),
				}));
			} catch (error) {
				// Show an error toast message if the delete operation fails
				toast.error('Failed to delete user.');
			}
		}
	};

	render() {
		return (
			<div style={{ padding: '4rem 8rem' }}>
				<ToastContainer />
				<center>
					<Table size='sm' striped bordered hover variant='light'>
						<thead>
							<tr>
								<th>Name</th>
								<th>Email</th>
								<th className='text-center'>Is Admin</th>
								<th className='text-center'>Is Super Admin</th>
								<th className='text-center'>Action</th>
							</tr>
						</thead>
						<tbody>
							{this.state.users?.map((item) => {
								return (
									<tr key={item._id}>
										<td key={'name' + item._id}>{item.name}</td>
										<td key={'email' + item._id}>{item.email}</td>
										<td className='text-center' key={'isAdmin' + item._id}>
											{item.isAdmin.toString()}
										</td>
										<td className='text-center' key={'isSuperAdmin' + item._id}>
											{item.isSuperAdmin.toString()}
										</td>
										<td className='text-center' key={'button' + item._id}>
											<Button
												size='sm'
												variant='outline-danger'
												onClick={() => this.handleDelete(item._id)}
											>
												Delete
											</Button>
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</center>
			</div>
		);
	}
}

export default SuperUser;
