import React, { Component } from 'react'
import { getCustomer, getCustomers, updateCustomer } from '../services/customerService';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Joi from "joi-browser";
const company = require('./../company');


class UpdateCustomer extends Component {
	state = {
		_id: this.props.id,

		customerName: "",
		panNum: "",
		mobile: "",
		phone: "",
		email: "",
		address: "",

		customers: [],

		buttonDisabled: false
	}

	async componentDidMount() {
		const oldData = await getCustomer(this.props.id);

		this.setState({
			customerName: oldData.data.customerName.toString(),
			panNum: oldData.data.panNum.toString(),
			mobile: oldData.data.mobile.toString(),
			phone: oldData.data.phone.toString(),
			email: oldData.data.email.toString(),
			address: oldData.data.address.toString(),
			totalBooking: oldData.data.totalBooking,
			totalTransaction: oldData.data.totalTransaction,
			lastTransaction: oldData.data.lastTransaction,
		});

		if (oldData.data.panNum === "") {
			this.setState({ panNum: "" })
		}

		if (oldData.data.mobile === "") {
			this.setState({ mobile: "" })
		}

		if (oldData.data.phone === "") {
			this.setState({ phone: "" })
		}

		if (oldData.data.email === "") {
			this.setState({ email: "" })
		}

		if (oldData.data.address === "") {
			this.setState({ address: "" })
		}

		// Check duplicate PAN
		const { data: customers } = await getCustomers();
		const p = [];

		customers.forEach(i => {
			p.push(i.panNum)
		})

		if (oldData.data.panNum !== "") {
			const index = p.indexOf(oldData.data.panNum)
			if (index > -1) {
				p.splice(index, 1);
			}
		}

		this.setState({ customers: p })
	}

	handleChange = event => {
		this.setState({
			[event.target.name]: event.target.value,
		});
	}

	handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	}

	validateCustomer = (customer) => {
		const schema = {
			customerName: Joi.string()
				.min(2)
				.max(100)
				.required(),
			panNum: Joi.string()
				.allow(''),
			mobile: Joi.string()
				.allow(''),
			phone: Joi.string()
				.allow(''),
			email: Joi.string()
				.email()
				.allow(''),
			address: Joi.string()
				.allow(''),
			totalBooking: Joi.number().required(),
			totalTransaction: Joi.number().required(),
			lastTransaction: Joi.number().required()

		};

		return Joi.validate(customer, schema);
	}

	handleSubmit = async (event) => {
		event.preventDefault();

		const data = { ...this.state };
		delete data.buttonDisabled;
		const id = data._id;
		delete data._id;
		delete data.customers;

		const dupList = this.state.customers

		const { error } = this.validateCustomer(data)
		if (error) {
			toast.error(error.details[0].message)
		}
		else if (data.panNum !== "" && dupList.includes(data.panNum)) {
			toast.error("PAN alreday in use")
		}
		else {
			this.setState({ buttonDisabled: true });
			try {
				await updateCustomer(data, id);
				toast.dark("Updated successfully")

				this.props.handleUpdate();
			}
			catch (error) {
				toast.error(error)
			}
		}

		//this.props.handleUpdate();	
	}

	render() {
		return (
			<div>
				<ToastContainer hideProgressBar position="bottom-right" />

				<Form
					style={{ fontSize: '12px', padding: '0.25rem 0.5rem' }}
					onSubmit={this.handleSubmit}
					onKeyDown={this.handleKeyPress}
				>
					<div style={{ backgroundColor: '#FAFAFA', padding: '20px', border: '0.5px solid red' }}>
						<center>
							{company.company}
							<h4>Update Customer Details</h4>
							<p><br /></p>
						</center>
						<Form.Row>
							<Form.Group as={Col} controlId="">
								<Form.Label>Name</Form.Label>
								<Form.Control
									type="text"
									placeholder="Customer Name"
									name="customerName"
									value={this.state.customerName}
									onChange={this.handleChange}
								/>
							</Form.Group>

							<Form.Group as={Col} controlId="">
								<Form.Label>PAN</Form.Label>
								<Form.Control
									type="text"
									placeholder="Permanent Account Number"
									name="panNum"
									value={this.state.panNum}
									onChange={this.handleChange}
								/>
							</Form.Group>
						</Form.Row>

						<Form.Row>
							<Form.Group as={Col} controlId="">
								<Form.Label>Mobile Number</Form.Label>
								<Form.Control
									type="text"
									placeholder="Mobile Number"
									name="mobile"
									value={this.state.mobile}
									onChange={this.handleChange}
								/>
							</Form.Group>

							<Form.Group as={Col} controlId="">
								<Form.Label>Phone Number</Form.Label>
								<Form.Control
									type="text"
									placeholder="Phone Number"
									name="phone"
									value={this.state.phone}
									onChange={this.handleChange}
								/>
							</Form.Group>
						</Form.Row>

						<Form.Group controlId="">
							<Form.Label>Email Address</Form.Label>
							<Form.Control
								type="email"
								placeholder="abc@xyz.com"
								name="email"
								value={this.state.email}
								onChange={this.handleChange}
							/>
						</Form.Group>

						<Form.Row>
							<Form.Group as={Col} controlId="">
								<Form.Label>Address</Form.Label>
								<Form.Control
									type="text"
									name="address"
									value={this.state.address}
									onChange={this.handleChange}
								/>
							</Form.Group>
						</Form.Row>
					</div>

					<br />

					<Button
						variant="dark"
						size='sm'
						type="submit"
						disabled={this.state.buttonDisabled}
					>
						Update Customer
					</Button>{" "}

					<Button
						variant="outline-dark"
						size='sm'
						onClick={this.props.handleCancel}
					>
						Cancel
					</Button>
				</Form>
			</div>
		)
	}
}

export default UpdateCustomer