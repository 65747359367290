import React, { Component } from 'react'
import { updateCategory, getCategories, getCategory } from '../services/categoryService';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Joi from "joi-browser";
const company = require('./../company');


class UpdateCategory extends Component {
	_isMounted = false;

	state = {
		categoryName: "",
		description: "",
        categories: [],
		buttonDisabled: false,
		loading: true
	}

    populateCategories = async () => {
		const { data: categories } = await getCategories();
		const p = [];

		categories.forEach(i => {
			p.push(i.categoryName)
		})

		this._isMounted && this.setState({ categories: p })
	}

	populateCategory = async () => {
		const oldData = await getCategory(this.props.categoryId);
	  
		// Remove old category name from the categories array
		const updatedCategories = this.state.categories.filter(category => category !== oldData.data.categoryName.toString());
	  
		this._isMounted && this.setState({ 
		  categoryName: oldData.data.categoryName.toString(),
		  description: oldData.data.description.toString(),
		  categories: updatedCategories // Update categories array
		});
	  
		if (oldData.data.categoryName === "") {
		  this.setState({ categoryName: "" });
		}
	  
		if (oldData.data.description === "") {
		  this.setState({ description: "" });
		}
	  }	  

	async componentDidMount() {
		this._isMounted = true;

        this._isMounted && await this.populateCategories();
		this._isMounted && await this.populateCategory();
		this._isMounted && this.setState({ loading: false })
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleChange = event => {
		const { name, value } = event.target
		this.setState({
			[name]: value
		})
	}

	handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	}

	validateCategory = category => {
		const schema = {
			categoryName: Joi.string()
				.max(255)
				.required(),
			description: Joi.string()
				.max(1024)
				.allow('')
		};

		return Joi.validate(category, schema);
	}

	handleSubmit = async (event) => {
		event.preventDefault();

		const data = { ...this.state };
		delete data.buttonDisabled;
		delete data.categories;
		delete data.loading;

		const dupList = this.state.categories

		const { error } = this.validateCategory(data)
		if (error) {
			toast.error(error.details[0].message)
		}
		else if (dupList.includes(data.categoryName)) {
			toast.error("Name already in use")
		}
		else {
			this.setState({ buttonDisabled: true });
			try {
				await updateCategory(data, this.props.categoryId);
				toast.dark("Updated successfully")

				this.props.next()
			}
			catch (error) {
				toast.error(error);
			}
		}
	}

	render() {
		return (
			<div>
				<ToastContainer hideProgressBar position="bottom-right" />

				{this.state.loading
					?
					<div>
						<center>
							<br />
							<br />
							<br />
							<Spinner size="sm" animation="grow" variant="danger" />{' '}
							<Spinner size="sm" animation="grow" variant="warning" />{' '}
							<Spinner size="sm" animation="grow" variant="success" />
						</center>
					</div>
					:
					<div>
						<Form
							style={{ fontSize: '12px', padding: '0.25rem 0.5rem' }}
							onSubmit={this.handleSubmit}
							onKeyDown={this.handleKeyPress}
						>
							<Col xs={7}>
								<div style={{ backgroundColor: '#f7faf8', padding: '20px', border: '0.5px solid red' }}>

									<center>
										{company.company}
										<h4>Update Category</h4>
										<p><br /></p>
									</center>
									<Form.Label>Category Name</Form.Label>
									<Form.Control
										type="text"
										name="categoryName"
										value={this.state.categoryName}
										onChange={this.handleChange}
									/>
									<br />
									<Form.Label>Description</Form.Label>
									<Form.Control
										type="text"
										name="description"
										value={this.state.description}
										onChange={this.handleChange}
									/>
									<br />
								</div>
							</Col>

							<br />
							<Col xs={7}>
								<Button
									variant="dark"
									block
									size='sm'
									type="submit"
									disabled={this.state.buttonDisabled}
								>
									Update Category
								</Button>

								<Button
									variant="outline-dark"
									block
									size='sm'
									onClick={() => this.props.next()}
								>
									Cancel
								</Button>
							</Col>
						</Form>
					</div>
				}
			</div>
		)
	}
}

export default UpdateCategory