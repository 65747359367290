import React, { Component } from 'react'
import { getEmployee, getEmployees, updateEmployee } from '../services/employeeService';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Joi from "joi-browser";
const company = require('./../company');


class UpdateEmployee extends Component {
	state = {
		_id: this.props.id,

		employeeName: "",
		jobTitle: "",
		idNum: "",
		pan: "",
		phone: "",
		email: "",
		address: "",

		employees: [],

		buttonDisabled: false
	}

	async componentDidMount() {
		const oldData = await getEmployee(this.props.id);

		this.setState({
			employeeName: oldData.data.employeeName.toString(),
			jobTitle: oldData.data.jobTitle.toString(),
			idNum: oldData.data.idNum.toString(),
			pan: oldData.data.pan.toString(),
			phone: oldData.data.phone.toString(),
			email: oldData.data.email.toString(),
			address: oldData.data.address.toString()
		});

		if (oldData.data.jobTitle === "") {
			this.setState({ jobTitle: "" })
		}

		if (oldData.data.idNum === "") {
			this.setState({ idNum: "" })
		}

		if (oldData.data.pan === "") {
			this.setState({ pan: "" })
		}

		if (oldData.data.phone === "") {
			this.setState({ phone: "" })
		}

		if (oldData.data.email === "") {
			this.setState({ email: "" })
		}

		if (oldData.data.address === "") {
			this.setState({ address: "" })
		}

		// Check duplicate PAN
		const { data: employees } = await getEmployees();
		const p = [];

		employees.forEach(i => {
			p.push(i.pan)
		})

		if (oldData.data.pan !== "") {
			const index = p.indexOf(oldData.data.pan)
			if (index > -1) {
				p.splice(index, 1);
			}
		}

		this.setState({ employees: p })
	}

	handleChange = event => {
		const { name, value } = event.target
		this.setState({
			[name]: value
		})
	}

	handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	}

	validateEmployee = employee => {
		const schema = {
			employeeName: Joi.string()
				.min(2)
				.max(100)
				.required(),
			jobTitle: Joi.string()
				.max(255)
				.allow(''),
			idNum: Joi.string()
				.max(255)
				.allow(''),
			pan: Joi.string()
				.max(255)
				.allow(''),
			phone: Joi.string()
				.max(255)
				.allow(''),
			email: Joi.string()
				.email()
				.max(255)
				.allow(''),
			address: Joi.string()
				.max(1024)
				.allow('')
		};

		return Joi.validate(employee, schema);
	}

	handleSubmit = async (event) => {
		event.preventDefault();

		const data = { ...this.state };
		delete data.buttonDisabled;
		const id = data._id;
		delete data._id;
		delete data.employees;

		const dupList = this.state.employees

		const { error } = this.validateEmployee(data)
		if (error) {
			toast.error(error.details[0].message)
		}
		else if (data.pan !== "" && dupList.includes(data.pan)) {
			toast.error("PAN alreday in use")
		}
		else {
			this.setState({ buttonDisabled: true });
			try {
				await updateEmployee(data, id);
				toast.dark("Updated successfully")
			}
			catch (error) {
				toast.error(error);
			}

			this.props.handleUpdate();
		}
	}


	render() {
		return (
			<div>
				<ToastContainer hideProgressBar position="bottom-right" />

				<Form
					style={{ fontSize: '12px', padding: '0.25rem 0.5rem' }}
					onSubmit={this.handleSubmit}
					onKeyDown={this.handleKeyPress}
				>
					<div style={{ backgroundColor: '#FAFAFA', padding: '20px', border: '0.5px solid red' }}>
						<center>
							{company.company}
							<h4>Update Employee Details</h4>
							<p><br /></p>
						</center>
						<Form.Row>
							<Form.Group as={Col} controlId="">
								<Form.Label>Name</Form.Label>
								<Form.Control
									type="text"
									placeholder="Employee Name"
									name="employeeName"
									value={this.state.employeeName}
									onChange={this.handleChange}
								/>
							</Form.Group>

							<Form.Group as={Col} controlId="">
								<Form.Label>Job Title</Form.Label>
								<Form.Control
									type="text"
									name="jobTitle"
									value={this.state.jobTitle}
									onChange={this.handleChange}
								/>
							</Form.Group>
						</Form.Row>

						<Form.Row>
							<Form.Group as={Col} controlId="">
								<Form.Label>Identification Number</Form.Label>
								<Form.Control
									type="text"
									paceholder="Eg: Citizenship Number"
									name="idNum"
									value={this.state.idNum}
									onChange={this.handleChange}
								/>
							</Form.Group>

							<Form.Group as={Col} controlId="">
								<Form.Label>PAN</Form.Label>
								<Form.Control
									type="text"
									name="pan"
									value={this.state.pan}
									onChange={this.handleChange}
								/>
							</Form.Group>
						</Form.Row>

						<Form.Group controlId="">
							<Form.Label>Phone Number</Form.Label>
							<Form.Control
								placeholder="Mobile / Phone Number"
								type="text"
								name="phone"
								value={this.state.phone}
								onChange={this.handleChange}
							/>
						</Form.Group>

						<Form.Group controlId="">
							<Form.Label>Email Address</Form.Label>
							<Form.Control
								type="email"
								placeholder="abc@xyz.com"
								name="email"
								value={this.state.email}
								onChange={this.handleChange}
							/>
						</Form.Group>

						<Form.Group controlId="">
							<Form.Label>Address</Form.Label>
							<Form.Control
								type="text"
								name="address"
								value={this.state.address}
								onChange={this.handleChange}
							/>
						</Form.Group>
					</div>

					<br />

					<Button
						variant="dark"
						size='sm'
						type="submit"
						disabled={this.state.buttonDisabled}
					>
						Update Employee
					</Button>{" "}

					<Button
						variant="outline-dark"
						size='sm'
						onClick={this.props.handleCancel}
					>
						Cancel
					</Button>

				</Form>
			</div>
		)
	}
}

export default UpdateEmployee