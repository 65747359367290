import axios from "axios";
import auth from "./authService";
import { toast } from "react-toastify";
const config = require('./../config');

axios.defaults.baseURL = config.apiBaseURL;


axios.interceptors.response.use(null, error => {
    const expectedError =
        error.response &&
        error.response.status >= 400 &&
        error.response.status < 500;

    if (error.response.status === 401 && error.response.data === 'Access denied. Password Changed.') {
        auth.logout();
        window.location = "/"
    }

    if (!expectedError) {
        toast.error("An unexpected error occurred.");
    }

    else if (error.response.status === 401) {
        console.log(error.message)
        toast.error(error.response.data);
    }

    else if (error.response.status === 400) {
        toast.error(error.response.data);
    }

    else if (error.response.status === 403) {
        toast.error(error.response.data);
    }

    else if (error.response.status === 404) {
        toast.error(error.response.data);
    }

    else if (error.response.status === 500) {
        toast.error("Internal Server Error");
    }

    else {
        toast.error("Some error occurred");
    }

    return Promise.reject(error);
});

export function setJWT(jwt) {
    axios.defaults.headers.common["x-auth-token"] = jwt;
}

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
    setJWT
};
