import React, { Component } from 'react'
import { saveEmployee, getEmployees } from '../services/employeeService';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Joi from "joi-browser";
const company = require('./../company');


class CreateEmployee extends Component {
	_isMounted = false;

	state = {
		employeeName: "",
		jobTitle: "",
		idNum: "",
		pan: "",
		phone: "",
		email: "",
		address: "",

		employees: [],

		buttonDisabled: false,
		loading: true
	}

	populateEmployees = async () => {
		const { data: employees } = await getEmployees();
		const p = [];

		employees.forEach(i => {
			p.push(i.pan)
		})

		this._isMounted && this.setState({ employees: p })
	}

	async componentDidMount() {
		this._isMounted = true;

		this._isMounted && await this.populateEmployees();
		this._isMounted && this.setState({ loading: false })
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleChange = event => {
		const { name, value } = event.target
		this.setState({
			[name]: value
		})
	}

	handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	}

	validateEmployee = employee => {
		const schema = {
			employeeName: Joi.string()
				.min(2)
				.max(100)
				.required(),
			jobTitle: Joi.string()
				.max(255)
				.allow(''),
			idNum: Joi.string()
				.max(255)
				.allow(''),
			pan: Joi.string()
				.max(255)
				.allow(''),
			phone: Joi.string()
				.max(255)
				.allow(''),
			email: Joi.string()
				.email()
				.max(255)
				.allow(''),
			address: Joi.string()
				.max(1024)
				.allow('')
		};

		return Joi.validate(employee, schema);
	}

	handleSubmit = async (event) => {
		event.preventDefault();

		const data = { ...this.state };
		delete data.buttonDisabled;
		delete data.employees;
		delete data.loading;

		const dupList = this.state.employees

		const { error } = this.validateEmployee(data)
		if (error) {
			toast.error(error.details[0].message)
		}
		else if (data.pan !== "" && dupList.includes(data.pan)) {
			toast.error("PAN alreday in use")
		}
		else {
			this.setState({ buttonDisabled: true });
			try {
				await saveEmployee(data);
				toast.dark("Added successfully")
			}
			catch (error) {
				toast.error(error);
			}
		}
	}

	handleClear = async () => {
		await this.populateEmployees();

		this.setState({
			employeeName: "",
			jobTitle: "",
			idNum: "",
			pan: "",
			phone: "",
			email: "",
			address: "",
			buttonDisabled: false
		})
	}

	render() {
		return (
			<div>
				<ToastContainer hideProgressBar position="bottom-right" />

				{this.state.loading ? (
					<div>
						<center>
							<br />
							<br />
							<br />
							<Spinner size="sm" animation="grow" variant="danger" />{' '}
							<Spinner size="sm" animation="grow" variant="warning" />{' '}
							<Spinner size="sm" animation="grow" variant="success" />
						</center>
					</div>
				) : (
					<div style={{
						margin: '0 auto', // Center the form horizontally
						maxWidth: '70%',  // Limit the width to 70%
					}}>
						<Form
							onSubmit={this.handleSubmit}
							style={{ fontSize: '14px', padding: '0.25rem 0.5rem' }}
							onKeyDown={this.handleKeyPress}
						>
							<div style={{ backgroundColor: '#FAFAFA', padding: '20px' }}>
								<center>
									{company.company}
									<h4>Create New Employee</h4>
									<p><br /></p>
								</center>
								<Form.Group as={Row} controlId="employeeName">
									<Form.Label column sm={3}>Name</Form.Label>
									<Col sm={9}>
										<Form.Control
											type="text"
											placeholder="Employee Name"
											name="employeeName"
											value={this.state.employeeName}
											onChange={this.handleChange}
										/>
									</Col>
								</Form.Group>

								<Form.Group as={Row} controlId="jobTitle">
									<Form.Label column sm={3}>Job Title</Form.Label>
									<Col sm={9}>
										<Form.Control
											type="text"
											name="jobTitle"
											value={this.state.jobTitle}
											onChange={this.handleChange}
										/>
									</Col>
								</Form.Group>

								<Form.Group as={Row} controlId="idNum">
									<Form.Label column sm={3}>Identification Number</Form.Label>
									<Col sm={9}>
										<Form.Control
											type="text"
											placeholder="Eg: Citizenship Number"
											name="idNum"
											value={this.state.idNum}
											onChange={this.handleChange}
										/>
									</Col>
								</Form.Group>

								<Form.Group as={Row} controlId="pan">
									<Form.Label column sm={3}>PAN</Form.Label>
									<Col sm={9}>
										<Form.Control
											type="text"
											name="pan"
											value={this.state.pan}
											onChange={this.handleChange}
										/>
									</Col>
								</Form.Group>

								<Form.Group as={Row} controlId="phone">
									<Form.Label column sm={3}>Phone Number</Form.Label>
									<Col sm={9}>
										<Form.Control
											type="text"
											placeholder="Mobile / Phone Number"
											name="phone"
											value={this.state.phone}
											onChange={this.handleChange}
										/>
									</Col>
								</Form.Group>

								<Form.Group as={Row} controlId="email">
									<Form.Label column sm={3}>Email Address</Form.Label>
									<Col sm={9}>
										<Form.Control
											type="email"
											placeholder="abc@xyz.com"
											name="email"
											value={this.state.email}
											onChange={this.handleChange}
										/>
									</Col>
								</Form.Group>

								<Form.Group as={Row} controlId="address">
									<Form.Label column sm={3}>Address</Form.Label>
									<Col sm={9}>
										<Form.Control
											type="text"
											name="address"
											value={this.state.address}
											onChange={this.handleChange}
										/>
									</Col>
								</Form.Group>
							</div>
							<br />

							<Button
								variant="dark"
								type="submit"
								size='sm'
								disabled={this.state.buttonDisabled}
							>
								Create Employee
							</Button>

							<Button
								variant="danger"
								className="ml-2"
								size='sm'
								onClick={this.handleClear}
							>
								Clear
							</Button>
						</Form>
					</div>
				)}
			</div>
		);
	}


}

export default CreateEmployee