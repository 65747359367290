import React, { Component } from 'react'
import Table from 'react-bootstrap/Table'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import moment from 'moment'
const company = require('./../company');


class PrintPayment extends Component {
    componentDidMount = () => {
        window.print()
    }

    render() {
        const labelStyle = {
            display: 'inline-block',
            width: '140px',
            marginRight: '10px',
            textAlign: 'left'
        };

        const valueStyle = {
            display: 'inline-block',
        };

        return (
            <div>
                <Container>

                    <div style={{ backgroundColor: '#F0F7FF', padding: '20px' }}>
                        <center>
                            {company.company}
                            <h4>Payment Invoice</h4>
                            <p><br /></p>
                        </center>
                        <div style={{ fontSize: '12px' }}>
                            <Row>
                                <Col>
                                    <div>
                                        <span style={labelStyle}><strong>Bill Number:</strong></span>
                                        <span style={valueStyle}>{this.props.payload.booking.billNum}</span>
                                    </div>
                                    <div>
                                        <span style={labelStyle}><strong>Date:</strong></span>
                                        <span style={valueStyle}>{moment(this.props.payload.date).format('MMMM DD, YYYY')}</span>
                                    </div>
                                    <div>
                                        <span style={labelStyle}><strong>Program:</strong></span>
                                        <span style={valueStyle}>{this.props.payload.booking.program}</span>
                                    </div>
                                    <div>
                                        <span style={labelStyle}><strong>Venue:</strong></span>
                                        <span style={valueStyle}>{this.props.payload.booking.venue}</span>
                                    </div>
                                    <div>
                                        <span style={labelStyle}><strong>Event Date:</strong></span>
                                        <span style={valueStyle}>{moment(this.props.payload.booking.eventDate).format('MMMM DD, YYYY')}</span>
                                    </div>
                                    <div>
                                        <span style={labelStyle}><strong>Return Date:</strong></span>
                                        <span style={valueStyle}>{moment(this.props.payload.booking.returnedDate).format('MMMM DD, YYYY')}</span>
                                    </div>
                                </Col>
                                <Col>
                                    <div>
                                        <span style={labelStyle}><strong>Customer Name:</strong></span>
                                        <span style={valueStyle}>{this.props.payload.customer.customerName}</span>
                                    </div>
                                    <div>
                                        <span style={labelStyle}><strong>PAN:</strong></span>
                                        <span style={valueStyle}>{this.props.payload.customer.panNum}</span>
                                    </div>
                                    <div>
                                        <span style={labelStyle}><strong>Mobile:</strong></span>
                                        <span style={valueStyle}>{this.props.payload.customer.mobile}</span>
                                    </div>
                                    <div>
                                        <span style={labelStyle}><strong>Address:</strong></span>
                                        <span style={valueStyle}>{this.props.payload.customer.address}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <br />

                        <Table size='sm' style={{ fontSize: '12px' }} bordered striped hover responsive>
                            <thead>
                                <tr>
                                    <th style={{ width: '50%' }}>Particulars</th>
                                    <th style={{ width: '10%', textAlign: 'center' }}>Code No</th>
                                    <th style={{ width: '10%', textAlign: 'center' }}>Day</th>
                                    <th style={{ width: '10%', textAlign: 'center' }}>Qty</th>
                                    <th style={{ width: '10%', textAlign: 'center' }}>Out</th>
                                    <th style={{ width: '10%', textAlign: 'center' }}>In</th>
                                </tr>
                            </thead>
                            <tbody>{this.props.payload.items?.map((item, index) => (
                                <tr key={index}>
                                    <td style={{ width: '50%' }}>{item.particulars}</td>
                                    <td style={{ width: '10%', textAlign: 'center' }}>{item.codeNo}</td>
                                    <td style={{ width: '10%', textAlign: 'center' }}>{item.days}</td>
                                    <td style={{ width: '10%', textAlign: 'center' }}>{item.qty}</td>
                                    <td style={{ width: '10%', textAlign: 'center' }}>{item.out}</td>
                                    <td style={{ width: '10%', textAlign: 'center', backgroundColor: item.in < item.out ? '#FFC4C4' : '' }}>{item.in}</td>
                                </tr>
                            ))}</tbody>
                        </Table>

                        <br />

                        <div style={{ paddingLeft: '10px', paddingRight: '10px', fontSize: '12px', borderBottom: '1px solid #ccc' }}>
                            <span style={labelStyle}><strong>Comments:</strong></span>
                            <span style={valueStyle}>{this.props.payload.comments}</span>
                        </div>

                        <br />

                        <div style={{ fontSize: '12px' }}>
                            <Row>
                                <Col>
                                    <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                                        <span style={labelStyle}><strong>Booking Amount:</strong></span>
                                        <span style={valueStyle}>{this.props.payload.totalAmount-parseInt(this.props.payload.settlementAmount)}</span>
                                    </div>
                                    <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                                        <span style={labelStyle}><strong>Settlement Amount:</strong></span>
                                        <span style={valueStyle}>{this.props.payload.settlementAmount}</span>
                                    </div>
                                    <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                                        <span style={labelStyle}><strong>Discount:</strong></span>
                                        <span style={valueStyle}>{this.props.payload.discount}</span>
                                    </div>
                                    <div style={{ paddingLeft: '10px', paddingRight: '10px', paddingBottom: '5px' }}>
                                        <span style={labelStyle}><strong>Advance Payment:</strong></span>
                                        <span style={valueStyle}>{this.props.payload.advance}</span>
                                    </div>

                                    <div style={{
                                        border: '1px solid black',
                                        padding: '5px 10px',
                                        fontSize: '14px',
                                        display: 'inline-block'
                                    }}>
                                        <span style={labelStyle}><strong>Payment Amount:</strong></span>
                                        <span style={valueStyle}>
                                            <strong>{this.props.payload.totalPaid}</strong>
                                        </span>
                                    </div>

                                    <div style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: '5px' }}>
                                        <span style={labelStyle}><strong>Total Amount:</strong></span>
                                        <span style={valueStyle}>{this.props.payload.totalPaid * 1 + this.props.payload.advance * 1}</span>
                                    </div>
                                </Col>
                                <Col>
                                    {(this.props.payload.technicians.length === 0)
                                        ? <div></div>
                                        : <div style={{ paddingRight: '100px', fontSize: '12px' }}>
                                            <Table bordered size='sm'>
                                                <thead>
                                                    <tr>
                                                        <th>Technician Name</th>
                                                        <th>Contact</th>
                                                    </tr>
                                                </thead>
                                                <tbody>{this.props.payload.technicians?.map((technician, index) => (
                                                    <tr key={index}>
                                                        <td>{technician.employeeName}</td>
                                                        <td>{technician.phone}</td>
                                                    </tr>
                                                ))}</tbody>
                                            </Table>
                                        </div>}
                                </Col>
                            </Row>
                        </div>
                        <br />
                        <div className="float-right" style={{ fontSize: '10px' }}>
                            <strong>Submitted By:</strong> {this.props.payload.submittedBy}<br />
                        </div>
                        <br />
                    </div>
                </Container>
            </div>
        )
    }
}

export default PrintPayment