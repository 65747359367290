import httpService from "./httpService.js";


export async function getSalariesWithEmployee(pageNumber) {
    return httpService.get(`/api/salary/withEmployee?page=${pageNumber}`);
}

export async function getSalariesByEmployeeId(employeeId, pageNumber) {
    return httpService.get(`/api/salary/byEmployeeId?employeeId=${employeeId}&page=${pageNumber}`);
}

export function getSalaries() {
	return httpService.get(`/api/salary`);
}

export function getSalary(id) {
	return httpService.get(`/api/salary/${id}`);
}

export async function getDetailedSalary(id) {
    return httpService.get(`/api/salary/detailed/${id}`);
}

export function downloadCSV(d1, d2) {
    return httpService.get(`/api/salary/download/?date1=${d1}&date2=${d2}`);
}

export function saveSalary(salary) {
    if (!salary._id) {
        return httpService.post(`/api/salary`, salary)
        }
    const body = { ...salary };
    delete body._id;
    return httpService.put(`/api/salary/${salary._id}`, body);
}

export async function updateSalary(data, id) {
    return httpService.put(`/api/salary/${id}`, data);
}

export function deleteSalary(id) {
    return httpService.delete(`/api/salary/${id}`);
}
