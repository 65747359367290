import React, { Component } from 'react'
import { saveEquipment, getEquipments } from '../services/equipmentService';
import { getCategories } from '../services/categoryService';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Joi from "joi-browser";
const company = require('./../company');


class CreateEquipment extends Component {
	_isMounted = false;

	state = {
		equipmentName: "",
		code: "",
		stock: "",
		categoryId: "",
		description: "",

		equipments: [],
		categories: [],

		buttonDisabled: false,
		loading: true
	}

	populateCategories = async () => {
		const { data: categories } = await getCategories();
		// const p = [];

		// categories.forEach(i => {
		// 	p.push(i.categoryName)
		// })
		this._isMounted && this.setState({ categories: categories })
	}

	populateEquipments = async () => {
		const { data: equipments } = await getEquipments();
		const p = [];

		equipments.forEach(i => {
			p.push(i.equipmentName)
		})

		this._isMounted && this.setState({ equipments: p })
	}

	async componentDidMount() {
		this._isMounted = true;

		this._isMounted && await this.populateCategories();
		this._isMounted && await this.populateEquipments();
		this._isMounted && this.setState({ loading: false })
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleChange = event => {
		const { name, value } = event.target
		this.setState({
			[name]: value
		})
	}

	handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	}

	handleCategorySelect = async e => {
		this.setState({
			categoryId: e.value,
		})
	}


	validateEquipment = equipment => {
		const schema = {
			equipmentName: Joi.string()
				.max(255)
				.required(),
			code: Joi.string()
				.max(255)
				.required(),
			stock: Joi.number()
				.min(0)
				.required(),
			description: Joi.string()
				.max(1024)
				.allow(''),
			categoryId: Joi.string().required()
		};

		return Joi.validate(equipment, schema);
	}

	handleSubmit = async (event) => {
		event.preventDefault();

		const data = { ...this.state };
		delete data.buttonDisabled;
		delete data.categories;
		delete data.equipments;
		delete data.loading;

		const dupList = this.state.equipments

		const { error } = this.validateEquipment(data)
		if (error) {
			toast.error(error.details[0].message)
		}
		else if (dupList.includes(data.equipmentName)) {
			toast.error("Name already in use")
		}
		else {
			this.setState({ buttonDisabled: true });
			try {
				await saveEquipment(data);
				toast.dark("Added successfully")
			}
			catch (error) {
				toast.error(error);
			}
		}
	}

	handleClear = async () => {
		await this.populateCategories();
		await this.populateEquipments();

		this.setState({
			equipmentName: "",
			code: "",
			stock: "",
			categoryId: this.state.categoryId,
			description: "",
			buttonDisabled: false
		})
	}


	render() {
		return (
			<div>
				<ToastContainer hideProgressBar position="bottom-right" />

				{this.state.loading
					?
					<div>
						<center>
							<br />
							<br />
							<br />
							<Spinner size="sm" animation="grow" variant="danger" />{' '}
							<Spinner size="sm" animation="grow" variant="warning" />{' '}
							<Spinner size="sm" animation="grow" variant="success" />
						</center>
					</div>
					:
					<div>
						<Form
							style={{ fontSize: '12px', padding: '0.25rem 0.5rem' }}
							onSubmit={this.handleSubmit}
							onKeyDown={this.handleKeyPress}
						>
							<Row>
								<Col xs={7}>
									<div style={{ backgroundColor: '#f7faf8', padding: '20px' }}>
										<center>
											{company.company}
											<h4>Create New Equipment</h4>
											<p><br /></p>
										</center>
										<Form.Label>Category</Form.Label>
										<Select
											onChange={this.handleCategorySelect}
											options={this.state.categories?.map(i => {
												return ({
													value: i._id,
													label: i.categoryName,
												})
											})}
										/>
										<br />
										<Form.Label>Equipment Name</Form.Label>
										<Form.Control
											type="text"
											name="equipmentName"
											value={this.state.equipmentName}
											onChange={this.handleChange}
										/>
										<br />
										<Row>
											<Col xs={6}>
												<Form.Label>Code</Form.Label>
												<Form.Control
													type="text"
													name="code"
													value={this.state.code}
													onChange={this.handleChange}
												/>
											</Col>
											<Col xs={6}>
												<Form.Label>Stock</Form.Label>
												<Form.Control
													type="number"
													name="stock"
													min={0}
													value={this.state.stock}
													onChange={this.handleChange}
												/>
											</Col>
										</Row>
										<br />
										<Form.Label>Description</Form.Label>
										<Form.Control
											type="text"
											name="description"
											value={this.state.description}
											onChange={this.handleChange}
										/>
									</div>
								</Col>
							</Row>
							<br />
							<Row>
								<Col xs={7}>
									<Button
										variant="dark"
										block
										type="submit"
										size='sm'
										disabled={this.state.buttonDisabled}
									>
										Create Equipment
									</Button>
									<Button
										variant="danger"
										block
										size='sm'
										onClick={this.handleClear}
									>
										Clear
									</Button>
								</Col>
							</Row>
						</Form>

					</div>
				}
			</div>
		)
	}
}

export default CreateEquipment