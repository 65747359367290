import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import ReturnForm from '../components/ReturnForm'
import MakeReturn from '../components/MakeReturn'
import ExploreReturn from '../components/ExploreReturn'
import SearchReturn from '../components/SearchReturn'
import UpdateReturn from '../components/UpdateReturn'
import { ToastContainer } from 'react-toastify';


class Return extends Component {
	state = {
		view: "makeReturn",
		bookingId: "",
		deliveryId: "",
		returnId: ""
	}

	makeReturn = () => {
		this.setState({
			view: "makeReturn"
		})
	}

	returnForm = (bookingId, deliveryId) => {
		this.setState({ bookingId, deliveryId }, () => {
			this.setState({ view: "returnForm" })
		})
	}

	explore = () => {
		this.setState({
			view: "explore"
		})
	}

	search = () => {
		this.setState({
			view: "search"
		})
	}

	update = (returnId) => {
		this.setState({ returnId }, () => {
			this.setState({ view: "update" })
		})
	}

	renderView = () => {
		if (this.state.view === "makeReturn") {
			return (
				<div>
					<MakeReturn user={this.props.user} returnForm={this.returnForm} />
				</div>
			)
		}
		else if (this.state.view === "returnForm") {
			return (
				<div>
					<ReturnForm user={this.props.user} bookingId={this.state.bookingId} deliveryId={this.state.deliveryId} next={this.explore} />
				</div>
			)
		}
		else if (this.state.view === "search") {
			return (
				<div>
					<SearchReturn user={this.props.user} update={this.update} printReturn={this.props.printReturn} />
				</div>
			)
		}
		else if (this.state.view === "explore") {
			return (
				<div>
					<ExploreReturn user={this.props.user} update={this.update} printReturn={this.props.printReturn} />
				</div>
			)
		}
		else if (this.state.view === "update") {
			return (
				<div>
					<UpdateReturn user={this.props.user} returnId={this.state.returnId} next={this.explore} />
				</div>
			)
		}
	}

	render() {
		return (
			<div>
				<Container>
					<ToastContainer position="bottom-right" hideProgressBar />
					<Button size='sm' variant="dark" onClick={this.makeReturn}>
						Make Return
					</Button>{" "}

					<Button size='sm' variant="dark" onClick={this.search}>
						Search
					</Button>{" "}

					<Button size='sm' variant="dark" onClick={this.explore}>
						History
					</Button>{" "}

					<hr />

					{this.renderView()}
				</Container>
			</div>
		)
	}
}

export default Return