import React, { Component } from 'react'
import { getEmployees } from '../services/employeeService'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import { ToastContainer, toast } from 'react-toastify';
import { CSVLink } from "react-csv"
import CreateEmployee from '../components/CreateEmployee'
import PaySalary from '../components/PaySalary'
import ExploreEmployee from '../components/ExploreEmployee'
import ExploreSalary from '../components/ExploreSalary'
import EmployeeSalary from '../components/EmployeeSalary'
import UpdateSalary from '../components/UpdateSalary'
import SalaryCSV from '../components/SalaryCSV'
import { AiOutlineDownload } from "react-icons/ai";


class Employee extends Component {
	_isMounted = false;

	state = {
		view: "explore",
		id: "",
		employeeName: "",
		salaryId: "",

		csvData: [],
		headers: [],

		loading: true
	}

	async componentDidMount() {
		this._isMounted = true;

		if (this._isMounted) {
			const { data: employees } = await getEmployees();

			const headersData = [
				{ label: "Employee Name", key: "employeeName" },
				{ label: "Job Title", key: "jobTitle" },
				{ label: "Identification Number", key: "idNum" },
				{ label: "PAN", key: "pan" },
				{ label: "Phone", key: "phone" },
				{ label: "Email", key: "email" },
				{ label: "Address", key: "address" }
			]

			const data = [];
			employees.forEach(i => {
				data.push(
					{
						employeeName: i.employeeName,
						jobTitle: i.jobTitle,
						idNum: i.idNum,
						pan: i.pan,
						phone: i.phone,
						email: i.email,
						address: i.address
					}
				)
			});

			this.setState({ csvData: data, headers: headersData, loading: false })
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	employeeForm = () => {
		this.setState({
			view: "employeeForm"
		})
	}

	salaryForm = () => {
		if (this.props.user.isAdmin || this.props.user.isSuperAdmin) {
			this.setState({
				view: "salaryForm"
			})
		}
		else {
			toast.error('Access Denied.')
		}
	}

	explore = () => {
		this.setState({
			view: "explore"
		})
	}

	history = () => {
		if (this.props.user.isAdmin || this.props.user.isSuperAdmin) {
			this.setState({
				view: "history"
			})
		}
		else {
			toast.error('Access Denied.')
		}
	}

	update = (salaryId) => {
		this.setState({ salaryId }, () => {
			this.setState({ view: "update" })
		})
	}

	csv = () => {
		if (this.props.user.isAdmin || this.props.user.isSuperAdmin) {
			this.setState({
				view: "csv"
			})
		}
		else {
			toast.error('Access Denied.')
		}
	}

	payments = (id) => {
		this.setState({ id }, () => {
			this.setState({ view: "payments" })
		})
	}

	renderView = () => {
		if (this.state.view === "explore") {
			return (
				<div>
					<ExploreEmployee handleDownload={this.download} payments={this.payments} user={this.props.user} />
				</div>
			)
		}
		else if (this.state.view === "payments") {
			return (
				<div>
					<EmployeeSalary user={this.props.user} employeeId={this.state.id} update={this.update} />
				</div>
			)
		}
		else if (this.state.view === "employeeForm") {
			return (
				<div>
					<CreateEmployee />
				</div>
			)
		}
		else if (this.state.view === "salaryForm") {
			return (
				<div>
					<PaySalary user={this.props.user} next={this.history}/>
				</div>
			)
		}
		else if (this.state.view === "update") {
			return (
				<div>
					<UpdateSalary user={this.props.user} salaryId={this.state.salaryId} next={this.history} />
				</div>
			)
		}
		else if (this.state.view === "history") {
			return (
				<div>
					<ExploreSalary user={this.props.user} update={this.update} />
				</div>
			)
		}
		else if (this.state.view === "csv") {
			return (
				<div>
					<SalaryCSV user={this.props.user} />
				</div>
			)
		}
	}

	render() {
		return (
			<div>
				<ToastContainer position="bottom-right" hideProgressBar />
				{this.state.loading
					?
					<div>
						<center>
							<br />
							<br />
							<br />
							<Spinner size="sm" animation="grow" variant="danger" />{' '}
							<Spinner size="sm" animation="grow" variant="warning" />{' '}
							<Spinner size="sm" animation="grow" variant="success" />
						</center>
					</div>
					:
					<div>

						<Container>
							<Button size='sm' variant="dark" onClick={this.explore}>
								Explore
							</Button>{' '}

							<Button size='sm' variant="dark" onClick={this.employeeForm}>
								Add New
							</Button>{' '}

							<Button size='sm' variant="dark" onClick={this.salaryForm}>
								Payment
							</Button>{' '}

							<Button size='sm' variant="dark" onClick={this.history}>
								History
							</Button>{' '}

							<span
								style={{
									cursor: 'pointer',
									color: 'black',
									marginLeft: '5px'
								}}
								onClick={() => { this.csv() }}
							>
								<AiOutlineDownload size={24} />
							</span>

							<CSVLink
								filename={"employeeDetails.csv"}
								data={this.state.csvData}
								headers={this.state.headers}
							>
								<Button size="sm" variant="outline-success" style={{ float: "right", marginRight: 5 }}>
									Download
								</Button>
							</CSVLink>

							<hr />

							{this.renderView()}
						</Container>
					</div>
				}
			</div>
		)
	}
}

export default Employee