import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import ReportForm from '../components/ReportForm'


class Report extends Component {
    state = {
        view: "report",
    }

    report = () => {
        this.setState({
            view: "report"
        })
    }

    renderView = () => {
        if (this.state.view === "report") {
            return (
                <div>
                    <center>
                        <h4>Generate Report</h4>
                        <br />
                    </center>
                    <ReportForm user={this.props.user} printReport={this.props.printReport}/>
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                <Container>
                    {this.renderView()}
                </Container>
            </div>
        )
    }
}

export default Report