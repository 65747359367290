import React, { Component } from 'react'
import { getUnpaidBookings } from '../services/bookingService';

import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import moment from 'moment'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


class MakePayment extends Component {
    _isMounted = false;

    state = {
        bookings: [],
        currentPage: 1,

        loading: true
    }

    populateBooking = async () => {
        const { data: bookings } = await getUnpaidBookings(this.state.currentPage);
        this._isMounted && this.setState({ bookings })
    }

    async componentDidMount() {
        this._isMounted = true;
        this._isMounted && await this.populateBooking()
        this._isMounted && this.setState({ loading: false })
    }

    nextPage = async () => {
        const { currentPage } = this.state;
        this.setState({ loading: true });

        // Increment the currentPage by 1
        const nextPage = currentPage + 1;

        // Fetch the bookings for the next page
        const { data: bookings } = await getUnpaidBookings(nextPage);

        // Update the state with the new bookings and currentPage
        this.setState({
            bookings,
            currentPage: nextPage,
            loading: false,
        });
    };

    prevPage = async () => {
        const { currentPage } = this.state;
        if (currentPage === 1) return; // Prevent going to negative page number

        this.setState({ loading: true });

        // Decrement the currentPage by 1
        const prevPage = currentPage - 1;

        // Fetch the bookings for the previous page
        const { data: bookings } = await getUnpaidBookings(prevPage);

        // Update the state with the new bookings and currentPage
        this.setState({
            bookings,
            currentPage: prevPage,
            loading: false,
        });
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    handlePageChange = pageNumber => {
        this.setState({ currentPage: pageNumber });
    };

    getButtonVariant = (booking) => {
        if (booking.isPaid) return 'outline-success';
        if (booking.isReturned) return 'outline-info';
        if (booking.isDelivered) return 'outline-warning';
        return 'outline-danger';
    };

    handleMakePaymentClick = (bookingId, returnId) => {
        this.props.paymentForm(bookingId, returnId);
    };

    render() {
        const { bookings, loading } = this.state;

        return (
            <div>
                {loading ? (
                    <div>
                        <center>
                            <br />
                            <br />
                            <br />
                            <Spinner size="sm" animation="grow" variant="danger" />{' '}
                            <Spinner size="sm" animation="grow" variant="warning" />{' '}
                            <Spinner size="sm" animation="grow" variant="success" />
                        </center>
                    </div>
                ) : (
                    <div>
                        <ToastContainer position="bottom-right" hideProgressBar />
                        {bookings.length === 0 ? (
                            <Alert variant="info">No more Bookings available.</Alert>
                        ) : (
                            <div>
                                <Button variant='outline-info' style={{ marginRight: 5 }} disabled>Returned</Button>
                                <br />
                                <br />

                                {bookings.map((booking, index) => (
                                    <div key={booking._id}>
                                        <Alert
                                            key={booking.id}
                                            style={{
                                                backgroundColor: '',
                                                borderColor: '#e0dcdc',
                                                color: '#000',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            <Button
                                                disabled
                                                variant={this.getButtonVariant(booking)}
                                                className="float-right"
                                            >
                                                STATUS: {booking.isPaid
                                                    ? 'Paid'
                                                    : booking.isReturned
                                                        ? 'Returned'
                                                        : booking.isDelivered
                                                            ? 'Delivered'
                                                            : 'Booked'}
                                            </Button>

                                            <h4>{booking.program}</h4>
                                            <strong>Event Date:</strong> {moment(booking.eventDate).format('MMMM DD, YYYY')}<br /><br />
                                            <Row>
                                                <Col>
                                                    <strong>Bill Number:</strong> {booking.billNum}<br />
                                                    <strong>Venue:</strong> {booking.venue}<br />
                                                    <strong>Additional Phone 1:</strong> {booking.additional_phone}<br />
                                                    <strong>Additional Phone 2:</strong> {booking.additional_phone_2}<br />

                                                </Col>
                                                <Col>
                                                    <strong>Customer Name:</strong> {booking.customer.customerName}<br />
                                                    <strong>PAN:</strong> {booking.customer.panNum}<br />
                                                    <strong>Mobile:</strong> {booking.customer.mobile}<br />
                                                    <strong>Address:</strong> {booking.customer.address}<br />
                                                </Col>
                                            </Row>
                                            <br />
                                            <div className="float-right" style={{ fontSize: '12px' }}>
                                                {/* <strong>Date:</strong> {moment(booking.date).format('MMMM DD, YYYY')}<br /> */}
                                                <strong>Submitted By:</strong> {booking.submittedBy}<br />
                                            </div>
                                            <br />
                                            <Button
                                                size='sm'
                                                variant='outline-dark'
                                                style={{ marginRight: 5 }}
                                                onClick={() => this.handleMakePaymentClick(booking._id, booking.returnId)}
                                            >
                                                Confirm Payment
                                            </Button>
                                        </Alert>
                                        <br />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
                <br />
                <Button size='sm' variant='outline-dark' onClick={this.prevPage}>Previous</Button>
                <Button 
                    size='sm' 
                    variant='outline-dark' 
                    className="float-right" 
                    onClick={this.nextPage} 
                    disabled={ (this.state.bookings.length === 0) }
                    >
                        Next
                    </Button>
                <br /><br />
            </div>
        );
    }
}

export default MakePayment;