import React, { Component } from 'react';
import { saveExpenses } from '../services/expenseService';
import { getCurrentDate } from '../utils/utils';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Joi from "joi-browser";
const company = require('./../company');


class ExpenseForm extends Component {
    _isMounted = false;

    state = {
        date: "",

        items: [
            {
                particulars: "",
                amount: 0,
                remarks: "",
            }
        ],

        userId: "",
        submittedBy: "",

        totalAmount: 0,
        buttonDisabled: false,
        printDisabled: true,
        loading: true
    }

    async componentDidMount() {
        this._isMounted = true;

        this._isMounted && this.setState({
            submittedBy: this.props.user.name,
            userId: this.props.user._id,
            date: getCurrentDate(),
            loading: false,
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChange = event => {
        this.setState({
            [event.target.name]: event.target.value
        }, () => {
            this.calculateTotalAmount()
        });
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    validateExpense = (data) => {
        const schema = {
            date: Joi.date().required(),
            particulars: Joi.string().max(1024).required(),
            amount: Joi.number().required(),
            remarks: Joi.string().max(1024).allow(''),
            userId: Joi.string().required(),
            submittedBy: Joi.string().required(),
        };

        return Joi.validate(data, schema);
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        const data = { ...this.state };
        delete data.totalAmount;
        delete data.buttonDisabled;
        delete data.printDisabled;
        delete data.loading;

        const payload = [];

        data.items.forEach(i => {
            const data = {
                date: this.state.date,
                userId: this.state.userId,
                submittedBy: this.state.submittedBy,

                particulars: i.particulars,
                amount: parseInt(i.amount),
                remarks: i.remarks
            }
            payload.push(data);
        })

        // Validate each item of the payload
        const e = [];
        payload.forEach(i => {
            const { error } = this.validateExpense(i)

            if (error) {
                e.push('placeholder')
                toast.error(error.details[0].message)
            }
        })

        // Save if there isn't any error, save the payload
        if (e.length === 0) {
            this.setState({ buttonDisabled: true });
            try {
                await saveExpenses(payload);
                toast.dark("Added successfully")
                this.setState({ printDisabled: false });
            }
            catch (error) {
                toast.error(error);
            }
        }
    }

    handleRecordChange = (index, e) => {
        const values = [...this.state.items];
        values[index][e.target.name] = e.target.value;
        this.setState({ values }, () => {
            this.calculateTotalAmount()
        });
    }

    calculateTotalAmount = () => {
        const totalAmount = this.state.items.reduce((total, item) => {
            return total * 1 + item.amount * 1;
        }, 0);

        this.setState({ totalAmount });
    }

    handleRecordAdd = () => {
        this.setState((prevState) => ({
            items: [...prevState.items, {
                particulars: "",
                amount: 0,
                remarks: "",
            }]
        }), () => {
            this.calculateTotalAmount()
        })
    }

    handleRecordRemove = (index) => {
        if (this.state.items.length > 1) {
            const values = [...this.state.items];
            values.splice(index, 1);

            this.setState({ items: values }, () => {
                this.calculateTotalAmount()
            });
        }
    }

    render() {
        return (
            <div>
                <ToastContainer hideProgressBar position="bottom-right" />

                {this.state.loading
                    ?
                    <div>
                        <center>
                            <br />
                            <br />
                            <br />
                            <Spinner size="sm" animation="grow" variant="danger" />{' '}
                            <Spinner size="sm" animation="grow" variant="warning" />{' '}
                            <Spinner size="sm" animation="grow" variant="success" />
                        </center>
                    </div>
                    :
                    <div>
                        <Form
                            style={{ fontSize: '12px', padding: '0.25rem 0.5rem' }}
                            onSubmit={this.handleSubmit}
                            onKeyDown={this.handleKeyPress}
                        >
                            <div style={{ backgroundColor: '#F0F7FF', padding: '20px' }}>
                                <center>
                                    {company.company}
                                    <h4>Expense Form</h4>
                                    <p><br /></p>
                                </center>

                                <Form.Row>
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control
                                            style={{ width: '200px', height: '30px' }}
                                            type="date"
                                            name="date"
                                            value={this.state.date}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                </Form.Row>


                                <br />
                                <hr />
                                <br />

                                <Form.Row>
                                    <Col xs={6}>
                                        <Form.Label>Particulars</Form.Label>
                                    </Col>

                                    <Form.Group as={Col} controlId="">
                                        <Form.Label>Amount</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="">
                                        <Form.Label>Remarks</Form.Label>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="">
                                        <Form.Label></Form.Label>
                                    </Form.Group>
                                </Form.Row>


                                {this.state.items.map((i, index) => {
                                    return (
                                        <Form.Row key={"exp" + i + index}>
                                            <Col xs={6}>
                                                <Form.Control
                                                    style={{ height: '30px' }}
                                                    type="text"
                                                    name="particulars"
                                                    value={i.particulars}
                                                    onChange={e => this.handleRecordChange(index, e)}
                                                />
                                            </Col>

                                            <Form.Group as={Col} controlId="">
                                                <Form.Control
                                                    style={{ height: '30px' }}
                                                    type="number"
                                                    step="1"
                                                    min={0}
                                                    name="amount"
                                                    value={i.amount}
                                                    onChange={e => this.handleRecordChange(index, e)}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="">
                                                <Form.Control
                                                    style={{ height: '30px' }}
                                                    type="text"
                                                    name="remarks"
                                                    value={i.remarks}
                                                    onChange={e => this.handleRecordChange(index, e)}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col}>
                                                <Button variant="light" size="sm" onClick={this.handleRecordAdd}>
                                                    +
                                                </Button>{' '}

                                                <Button variant="light" size="sm" onClick={() => this.handleRecordRemove(index)}>
                                                    -
                                                </Button>
                                            </Form.Group>


                                        </Form.Row>
                                    )
                                })}

                                <br />
                                <hr />
                                <br />

                                <Row className="mb-3">
                                    {/* <Col sm="6"></Col> */}

                                    <Col sm="6"> {/* Right-aligned form elements */}
                                        <Form.Group as={Row} controlId="">
                                            <Form.Label column sm="4">Total Amount</Form.Label>
                                            <Col sm="6">
                                                <Form.Control
                                                    style={{ height: '30px', width: '100px' }}
                                                    type="number"
                                                    name="totalAmount"
                                                    value={this.state.totalAmount}
                                                    onChange={this.handleChange}
                                                    readOnly
                                                />
                                            </Col>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </div>

                            <div style={{ textAlign: 'right', fontSize: '12px' }}>
                                Submitted By: <b>{this.state.submittedBy}</b>
                            </div>

                            <br />
                            <Button
                                variant="dark"
                                type="submit"
                                size='sm'
                                disabled={this.state.buttonDisabled}
                                style={{ marginRight: 5 }}
                            >
                                Add Expenses
                            </Button>

                            {/* <Button
                                variant="outline-dark"
                                disabled={this.state.printDisabled}
                            >
                                Print
                            </Button> */}

                        </Form>
                    </div>
                }
            </div>

        )
    }
}

export default ExpenseForm