import React, { Component } from 'react'
import { getEquipments } from '../services/equipmentService';
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


class ExploreEquipment extends Component {
    _isMounted = false;

    state = {
        equipments: [],
        loading: true
    }

    populateEquipments = async () => {
        const { data: equipments } = await getEquipments();
        this._isMounted && this.setState({ equipments })
    }

    async componentDidMount() {
        this._isMounted = true;
        this._isMounted && await this.populateEquipments()
        this._isMounted && this.setState({ loading: false })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    renderTable() {
        const { equipments } = this.state;

        return (
            <div style={{ fontSize: '12px' }}>
                <Table variant='dark' size='sm' bordered hover style={{ 'width': '100%', tableLayout: 'auto' }}>
                    <thead>
                        <tr className="text-center">
                            <th style={{ whiteSpace: 'nowrap', width: '10%' }}>Code</th>
                            <th style={{ whiteSpace: 'nowrap', width: '15%' }}>Equipment Name</th>
                            <th style={{ whiteSpace: 'nowrap', width: '15%' }}>Category</th>
                            <th style={{ whiteSpace: 'nowrap', width: '10%' }}>Stock</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Description</th>
                            <th style={{ whiteSpace: 'nowrap', width: '10%' }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {equipments.map((equipment, index) => (
                            <tr key={equipment._id}>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{equipment.code}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{equipment.equipmentName}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{equipment.categoryId.categoryName}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>{equipment.stock}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{equipment.description}</td>
                                <td style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                                    <Button
                                        variant='outline-warning'
                                        style={{ padding: '0.25rem 0.5rem', fontSize: '10px', marginRight: '5px' }}
                                        onClick={() => this.props.updateEquipment(equipment._id)}
                                    >
                                        Edit
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }

    render() {
        const { equipments, loading } = this.state;

        return (
            <div>
                {loading ? (
                    <div>
                        <center>
                            <br />
                            <br />
                            <br />
                            <Spinner size="sm" animation="grow" variant="danger" />{' '}
                            <Spinner size="sm" animation="grow" variant="warning" />{' '}
                            <Spinner size="sm" animation="grow" variant="success" />
                        </center>
                    </div>
                ) : (
                    <div>
                        <ToastContainer position="bottom-right" hideProgressBar />
                        {equipments.length === 0 ? (
                            <Alert variant="info">No Equipments available.</Alert>
                        ) : (
                            <div>
                                { this.renderTable() }
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

export default ExploreEquipment;