import React, { Component } from 'react'
import { getEmployees } from '../services/employeeService';
import { getSalaries } from '../services/salaryService'
import UpdateEmployee from './UpdateEmployee'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


class ExploreEmployee extends Component {
	_isMounted = false;

	state = {
		employeeId: "",

		employeeName: "",
		jobTitle: "",
		idNum: "",
		pan: "",
		phone: "",
		email: "",
		address: "",
		
		employees: [],
		salaries: [],

		netPaid: 0.0,
		view: false,
		buttonDisabled: false,
		selectDisabled: false,

		update: false,
		loading: true
	}

	populateEmployees = async () => {
		const { data: employees } = await getEmployees();
		this._isMounted && this.setState({ employees })
	}

	populateSalary = async () => {
		const { data: salaries } = await getSalaries();
		this._isMounted && this.setState({ salaries })
	}

	async componentDidMount() {
		this._isMounted = true;

		this._isMounted && await this.populateEmployees();
		this._isMounted && await this.populateSalary();
		this._isMounted && this.setState({ loading: false })
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleChange = event => {
		const { name, value } = event.target
		this.setState({
			[name]: value
		})
	}

	handleSubmit = event => {
		event.preventDefault();

		if (this.state.employeeId !== "") {
			const e = this.state.employees.filter(i => i._id === this.state.employeeId)
			const s = this.state.salaries.filter(i => i.employeeId === this.state.employeeId)

			this.setState({
				view: true,
				buttonDisabled: true,
				selectDisabled: true
			})

			if (e.length !== 0) {
				this.setState({
					employeeName: e[0].employeeName,
					jobTitle: e[0].jobTitle,
					idNum: e[0].idNum,
					pan: e[0].pan,
					phone: e[0].phone,
					email: e[0].email,
					address: e[0].address,
					payables: e[0].payables,
					receivables: e[0].receivables,
				})
			}

			// Calculate Totals
			if (s.length !== 0) {
				const netPaid = s.map(j => j.netPayment).reduce(function (a, b) {
					return a + b;
				}, 0);

				this.setState({ netPaid });
			}
		}
	}

	handleEmployeeSelect = e => {
		this.setState({
			employeeId: e.value,
			employeeName: e.employeeName
		})
	}

	handleReset = () => {
		this.setState({
			employeeName: "",
			jobTitle: "",
			idNum: "",
			pan: "",
			phone: "",
			email: "",
			address: "",
			netPaid: 0.0,
			
			view: false,
			buttonDisabled: false,
			selectDisabled: false
		})
	}

	handleUpdate = async e => {
		if (this.state.employeeId !== "") {
			this.setState({ update: true });
		}
	}

	setCancel = e => {
		this.setState({
			update: false
		})
	}

	setUpdate = async () => {
		await this.populateEmployees();

		const e = this.state.employees.filter(i => i._id === this.state.employeeId)
		//const s = this.state.salaries.filter(i => i.employeeId == this.state.employeeId)

		this.setState({
			view: true,
			buttonDisabled: true,
			selectDisabled: true
		})

		if (e.length !== 0) {
			this.setState({
				employeeName: e[0].employeeName,
				jobTitle: e[0].jobTitle,
				idNum: e[0].idNum,
				pan: e[0].pan,
				phone: e[0].phone,
				email: e[0].email,
				address: e[0].address,
				payables: e[0].payables,
				receivables: e[0].receivables,
			})
		}

		this.setState({
			update: false
		})
	}

	// handleDownload = () => {
	// 	this.props.handleDownload(
	// 		this.state.employeeId,
	// 		this.state.employeeName
	// 	);
	// }

	render() {
		return (
			<div>
				<ToastContainer hideProgressBar position="bottom-right" />

				{this.state.loading
					?
					<div>
						<center>
							<br />
							<br />
							<br />
							<Spinner size="sm" animation="grow" variant="danger" />{' '}
							<Spinner size="sm" animation="grow" variant="warning" />{' '}
							<Spinner size="sm" animation="grow" variant="success" />
						</center>
					</div>
					:
					<div>
						{this.state.update
							? <div>
								<UpdateEmployee id={this.state.employeeId} handleCancel={this.setCancel} handleUpdate={this.setUpdate} />
							</div>
							:
							<div>
								<br />
								<Form onSubmit={this.handleSubmit}>
									<Form.Row>
										<Col xs={6}>
											<Form.Label>Employee Name / PAN</Form.Label>
										</Col>
										<Col>
											<Form.Label></Form.Label>
										</Col>
									</Form.Row>

									<Form.Row>
										<Col xs={4}>
											<Select
												onChange={this.handleEmployeeSelect}
												options={this.state.employees?.map(i => {
													return ({
														value: i._id,
														label: `${i.employeeName}  ( ${i.pan} )`,
														employeeName: i.employeeName
													})
												})}
												isDisabled={this.state.selectDisabled}
											/>
										</Col>
										<Col>
											<Button
												variant="dark"
												type="submit"
												disabled={this.state.buttonDisabled}
											>
												Choose
											</Button>
											{' '}
											<Button
												variant="danger"
												onClick={this.handleReset}
											>
												Reset
											</Button>
										</Col>
									</Form.Row>
								</Form>

								<br />

								{this.state.view
									?
									<div>
										<br />
										<h4>{this.state.employeeName}</h4>
										<br />
										<br />
										<h4>
											Employee Details
											<Button
												variant="outline-warning"
												size="sm"
												style={{ float: 'right' }}
												onClick={this.handleUpdate}
											>
												Update
											</Button>

											{/* <Button
												variant="outline-success"
												size="sm"
												style={{ float: 'right', marginRight: 5 }}
												onClick={this.handleDownload}
											>
												Download
											</Button> */}

											<Button
												variant="outline-dark"
												size="sm"
												style={{ float: 'right', marginRight: 5 }}
												onClick={() => {
													if (this.props.user.isAdmin || this.props.user.isSuperAdmin) {
														this.props.payments(this.state.employeeId)
													}
													else {
														toast.error('Access Denied.')
													}
												}}
											>
												Payments
											</Button>
										</h4>

										<hr />

										<Row>
											<Col xs={4}><p><b>Job Title</b></p></Col>
											<Col><p>{this.state.jobTitle}</p></Col>
										</Row>

										<Row>
											<Col xs={4}><p><b>Identification Number</b></p></Col>
											<Col><p>{this.state.idNum}</p></Col>
										</Row>

										<Row>
											<Col xs={4}><p><b>PAN</b></p></Col>
											<Col><p>{this.state.pan}</p></Col>
										</Row>

										<Row>
											<Col xs={4}><p><b>Phone</b></p></Col>
											<Col><p>{this.state.phone}</p></Col>
										</Row>

										<Row>
											<Col xs={4}><p><b>Email</b></p></Col>
											<Col><p>{this.state.email}</p></Col>
										</Row>

										<Row>
											<Col xs={4}><p><b>Address</b></p></Col>
											<Col><p>{this.state.address}</p></Col>
										</Row>

									</div>
									:
									<p>Choose an employee name or PAN.</p>}
							</div>
						}
					</div>
				}
			</div>
		)
	}
}

export default ExploreEmployee