import React, { Component } from 'react'
import { AiOutlineHome } from "react-icons/ai";
import Button from 'react-bootstrap/Button'
import Nav from 'react-bootstrap/Nav'
import Navbar from 'react-bootstrap/Navbar'
import { ToastContainer, toast } from 'react-toastify';
import Welcome from './Welcome'
import Booking from './Booking'
import Report from './Report'
import Employee from './Employee'
import Customer from './Customer'
import Expense from './Expense'
import Inventory from './Inventory'
import Manage from './Manage'
import Settings from './Settings'
import Payment from './Payment'
import Delivery from './Delivery'
import Return from './Return'
import PrintPayment from '../components/PrintPayment'
import PrintReport from '../components/PrintReport'
import PrintBooking from '../components/PrintBooking'
import PrintBookingPayment from '../components/PrintBookingPayment'
import PrintDelivery from '../components/PrintDelivery'
import PrintReturn from '../components/PrintReturn'
const company = require('./../company');


class Home extends Component {
	state = {
		view: "welcome",
		payload: "",
	}

	welcome = () => {
		this.setState({
			view: "welcome"
		})
	}

	showReturn = () => {
		this.setState({
			view: "showReturn"
		})
	}

	customer = () => {
		this.setState({
			view: "customer"
		})
	}

	employee = () => {
		this.setState({
			view: "employee"
		})
	}

	booking = () => {
		this.setState({
			view: "booking"
		})
	}

	expense = () => {
		this.setState({
			view: "expense"
		})
	}

	inventory = () => {
		this.setState({
			view: "inventory"
		})
	}

	manage = () => {
		this.setState({
			view: "manage"
		})
	}

	report = () => {
		if (this.props.user.isAdmin || this.props.user.isSuperAdmin) {
			this.setState({
				view: "report"
			})
		}
		else {
			toast.error('Access Denied.')
		}
	}

	settings = () => {
		this.setState({
			view: "settings"
		})
	}

	equipmentReturn = () => {
		this.setState({
			view: "equipmentReturn"
		})
	}

	payment = () => {
		if (this.props.user.isAdmin || this.props.user.isSuperAdmin) {
			this.setState({
				view: "payment"
			})
		}
		else {
			toast.error('Access Denied.')
		}
	}

	delivery = () => {
		this.setState({
			view: "delivery"
		})
	}

	printBooking = (p) => {
		this.setState({
			view: "printBooking",
			payload: p
		})
	}

	printBookingPayment = (p) => {
		this.setState({
			view: "printBookingPayment",
			payload: p
		})
	}

	printDelivery = (p) => {
		this.setState({
			view: "printDelivery",
			payload: p
		})
	}

	printReturn = (p) => {
		this.setState({
			view: "printReturn",
			payload: p
		})
	}

	printPayment = (p) => {
		this.setState({
			view: "printPayment",
			payload: p
		})
	}

	printReport = (p) => {
		this.setState({
			view: "printReport",
			payload: p
		})
	}

	renderView = () => {
		if (this.state.view === "welcome") {
			return <Welcome printBooking={this.printBooking} />
		}
		else if (this.state.view === "customer") {
			return <Customer user={this.props.user} printPayment={this.printPayment} />
		}
		else if (this.state.view === "booking") {
			return <Booking user={this.props.user} printBooking={this.printBooking} printAdvance={this.printBookingPayment} />
		}
		else if (this.state.view === "employee") {
			return <Employee user={this.props.user} />
		}
		else if (this.state.view === "inventory") {
			return <Inventory />
		}
		else if (this.state.view === "manage") {
			return <Manage />
		}
		else if (this.state.view === "settings") {
			return <Settings user={this.props.user} />
		}
		else if (this.state.view === "payment") {
			return <Payment user={this.props.user} printPayment={this.printPayment} />
		}
		else if (this.state.view === "delivery") {
			return <Delivery user={this.props.user} printDelivery={this.printDelivery} />
		}
		else if (this.state.view === "showReturn") {
			return <Return user={this.props.user} printReturn={this.printReturn} />
		}
		else if (this.state.view === "expense") {
			return <Expense user={this.props.user} />
		}
		else if (this.state.view === "report") {
			return <Report user={this.props.user} printReport={this.printReport} />
		}
		else if (this.state.view === "printBooking") {
			return <PrintBooking payload={this.state.payload} />
		}
		else if (this.state.view === "printBookingPayment") {
			return <PrintBookingPayment payload={this.state.payload} />
		}
		else if (this.state.view === "printDelivery") {
			return <PrintDelivery payload={this.state.payload} />
		}
		else if (this.state.view === "printReturn") {
			return <PrintReturn payload={this.state.payload} />
		}
		else if (this.state.view === "printPayment") {
			return <PrintPayment payload={this.state.payload} />
		}
		else if (this.state.view === "printReport") {
			return <PrintReport payload={this.state.payload} />
		}
	}

	render() {
		return (
			<div>
				<ToastContainer position="bottom-right" hideProgressBar />
				<Navbar expand="lg" bg="dark" variant="dark">
					<Navbar.Brand>
						{company.app}
					</Navbar.Brand>
					<Nav className="ml-auto">

						<Nav.Link>
							<span
								style={{
									cursor: 'pointer',
									color: 'white',
								}}
								onClick={this.welcome}
							>
								<AiOutlineHome size={24} />{' '}
							</span>
						</Nav.Link>

						<Nav.Link>
							<Button size='sm' variant="dark" onClick={this.booking}>
								Booking
							</Button>{' '}
						</Nav.Link>

						<Nav.Link>
							<Button size='sm' variant="dark" onClick={this.delivery}>
								Delivery
							</Button>{' '}
						</Nav.Link>

						<Nav.Link>
							<Button size='sm' variant="dark" onClick={this.showReturn}>
								Return
							</Button>{' '}
						</Nav.Link>

						<Nav.Link>
							<Button size='sm' variant="dark" onClick={this.payment}>
								Payment
							</Button>{' '}
						</Nav.Link>

						<Nav.Link>
							<Button size='sm' variant="dark" onClick={this.expense}>
								Expense
							</Button>{' '}
						</Nav.Link>

						<Nav.Link>
							<Button size='sm' variant="dark" onClick={this.customer}>
								Customer
							</Button>{' '}
						</Nav.Link>

						<Nav.Link>
							<Button size='sm' variant="dark" onClick={this.employee}>
								Employee
							</Button>{' '}
						</Nav.Link>

						<Nav.Link>
							<Button size='sm' variant="dark" onClick={this.inventory}>
								Inventory
							</Button>{' '}
						</Nav.Link>

						<Nav.Link>
							<Button size='sm' variant="dark" onClick={this.report}>
								Report
							</Button>{' '}
						</Nav.Link>

						<Nav.Link>
							<Button size='sm' variant="dark" onClick={this.manage}>
								Manage
							</Button>{' '}
						</Nav.Link>

						<Nav.Link>
							<Button size='sm' variant="dark" onClick={this.settings}>
								Settings
							</Button>{' '}
						</Nav.Link>

					</Nav>
				</Navbar>

				<br />

				{this.renderView()}

			</div>
		)
	}
}

export default Home