import React, { Component } from 'react'
import { getCustomers } from '../services/customerService';
import UpdateCustomer from './UpdateCustomer'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


class ExploreCustomer extends Component {
	_isMounted = false;

	state = {
		customerId: "",

		customerName: "",
		panNum: "",
		phone: "",
		mobile: "",
		email: "",
		address: "",
		totalBooking: 0,
		lastTransaction: 0,
		totalTransaction: 0,

		customers: [],

		view: false,
		buttonDisabled: false,
		selectDisabled: false,

		update: false,
		loading: true
	}

	populateCustomers = async () => {
		const { data: customers } = await getCustomers();
		this._isMounted && this.setState({ customers })
	}

	async componentDidMount() {
		this._isMounted = true;

		this._isMounted && await this.populateCustomers();
		this._isMounted && this.setState({ loading: false })
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleChange = event => {
		const { name, value } = event.target
		this.setState({
			[name]: value
		})
	}

	handleSubmit = event => {
		event.preventDefault();

		if (this.state.customerId !== "") {
			const c = this.state.customers.filter(i => i._id === this.state.customerId);

			this.setState({
				view: true,
				buttonDisabled: true,
				selectDisabled: true
			})

			if (c.length !== 0) {
				this.setState({
					customerName: c[0].customerName,
					panNum: c[0].panNum,
					mobile: c[0].mobile,
					phone: c[0].phone,
					email: c[0].email,
					address: c[0].address,
					totalBooking: c[0].totalBooking,
					lastTransaction: c[0].lastTransaction,
					totalTransaction: c[0].totalTransaction
				})
			}
		}
	}

	handleCustomerSelect = e => {
		this.setState({
			customerId: e.value,
			customerName: e.customerName
		})
	}

	handleReset = () => {
		this.setState({
			customerName: "",
			panNum: "",
			mobile: "",
			phone: "",
			email: "",
			address: "",
			totalBooking: 0,
			lastTransaction: 0,
			totalTransaction: 0,

			view: false,
			buttonDisabled: false,
			selectDisabled: false
		})
	}

	handleUpdate = async e => {
		if (this.state.customerId !== "") {
			this.setState({ update: true });
		}
	}

	setCancel = e => {
		this.setState({
			update: false
		})
	}

	setUpdate = async () => {
		await this.populateCustomers();

		const c = this.state.customers.filter(i => i._id === this.state.customerId);

		this.setState({
			view: true,
			buttonDisabled: true,
			selectDisabled: true
		})

		if (c.length !== 0) {
			this.setState({
				customerName: c[0].customerName,
				panNum: c[0].panNum,
				mobile: c[0].mobile,
				phone: c[0].phone,
				email: c[0].email,
				address: c[0].address,
				totalBooking: c[0].totalBooking,
				lastTransaction: c[0].lastTransaction,
				totalTransaction: c[0].totalTransaction,
			})
		}

		this.setState({
			update: false
		})
	}

	// handleDownload = () => {
	// 	this.props.handleDownload(
	// 		this.state.customerId,
	// 		this.state.customerName,
	// 	);
	// }

	render() {
		return (
			<div>
				<ToastContainer hideProgressBar position="bottom-right" />

				{this.state.loading
					?
					<div>
						<center>
							<br />
							<br />
							<br />
							<Spinner size="sm" animation="grow" variant="danger" />{' '}
							<Spinner size="sm" animation="grow" variant="warning" />{' '}
							<Spinner size="sm" animation="grow" variant="success" />
						</center>
					</div>
					:
					<div>
						{this.state.update
							? <div>
								<UpdateCustomer id={this.state.customerId} handleCancel={this.setCancel} handleUpdate={this.setUpdate} />
							</div>
							:
							<div>
								<br />
								<Form onSubmit={this.handleSubmit}>
									<Form.Row>
										<Col xs={6}>
											<Form.Label>Customer Name / PAN</Form.Label>
										</Col>
										<Col>
											<Form.Label></Form.Label>
										</Col>
									</Form.Row>

									<Form.Row>
										<Col xs={4}>
											<Select
												onChange={this.handleCustomerSelect}
												options={this.state.customers?.map(i => {
													return ({
														value: i._id,
														label: `${i.customerName}  ( ${i.panNum} )`,
														customerName: i.customerName
													})
												})}
												isDisabled={this.state.selectDisabled}
											/>
										</Col>
										<Col>
											<Button
												variant="dark"
												type="submit"
												disabled={this.state.buttonDisabled}
											>
												Choose
											</Button>
											{' '}
											<Button
												variant="danger"
												onClick={this.handleReset}
											>
												Reset
											</Button>
										</Col>
									</Form.Row>
								</Form>

								<br />

								{this.state.view
									?
									<div>
										<br />
										<h4>{this.state.customerName}</h4>
										<br />

										<Row>
											<Col>
												<Alert variant='danger'>
													<center>
														<h5>Total Booking</h5>
														<hr />
														<p>{this.state.totalBooking}</p>
													</center>
												</Alert>
											</Col>
											<Col>
												<Alert variant='warning'>
													<center>
														<h5>Last Transaction</h5>
														<hr />
														<p>{(this.props.user.isAdmin || this.props.user.isSuperAdmin) ? this.state.lastTransaction : "X X X X"}</p>
													</center>
												</Alert>
											</Col>

											<Col>
												<Alert variant='success'>
													<center>
														<h5>Total Transaction Amount</h5>
														<hr />
														<p>{(this.props.user.isAdmin || this.props.user.isSuperAdmin) ? this.state.totalTransaction : "X X X X"}</p>
													</center>
												</Alert>
											</Col>
										</Row>

										<br />
										<h4>
											Customer Details
											<Button
												variant="outline-warning"
												size="sm"
												style={{ float: 'right' }}
												onClick={this.handleUpdate}
											>
												Update
											</Button>

											{/* <Button
												variant="outline-success"
												size="sm"
												style={{ float: 'right', marginRight: 5 }}
												onClick={this.handleDownload}
											>
												Download
											</Button> */}

											<Button
												variant="outline-dark"
												size="sm"
												style={{ float: 'right', marginRight: 5 }}
												onClick={() => {
													if (this.props.user.isAdmin || this.props.user.isSuperAdmin) {
														if (this.state.customerId !== "") {
															this.props.payments(this.state.customerId)
														}
													}
													else {
														toast.error('Access Denied.')
													}
												}}
											>
												Payments
											</Button>
										</h4>

										<hr />

										<Row>
											<Col xs={4}><p><b>PAN</b></p></Col>
											<Col><p>{this.state.panNum}</p></Col>
										</Row>

										<Row>
											<Col xs={4}><p><b>Mobile</b></p></Col>
											<Col><p>{this.state.mobile}</p></Col>
										</Row>

										<Row>
											<Col xs={4}><p><b>Phone</b></p></Col>
											<Col><p>{this.state.phone}</p></Col>
										</Row>

										<Row>
											<Col xs={4}><p><b>Email</b></p></Col>
											<Col><p>{this.state.email}</p></Col>
										</Row>

										<Row>
											<Col xs={4}><p><b>Address</b></p></Col>
											<Col><p>{this.state.address}</p></Col>
										</Row>

									</div>
									:
									<p>Choose a customer name or PAN.</p>}
							</div>
						}
					</div>
				}
			</div>
		)
	}
}

export default ExploreCustomer