import httpService from "./httpService.js";


export function getExpensesByDate(date) {
    return httpService.get(`/api/expense/byDate/?date=${date}`);
}

export function getExpenses(pageNumber) {
	return httpService.get(`/api/expense?page=${pageNumber}`);
}

export function getExpense(id) {
	return httpService.get(`/api/expense/${id}`);
}

export function downloadCSV(d1, d2) {
    return httpService.get(`/api/expense/download/?date1=${d1}&date2=${d2}`);
}

export function saveExpenses(value) {
    return httpService.post(`/api/expense`, value)
}

export async function updateExpense(data, id) {
    return httpService.put(`/api/expense/${id}`, data);
}

export function deleteExpense(id) {
    return httpService.delete(`/api/expense/${id}`);
}
