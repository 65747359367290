import httpService from "./httpService.js";


export async function getReturns(pageNumber) {
    return httpService.get(`/api/return?page=${pageNumber}`);
}

export async function getReturnsWithCustomer(pageNumber) {
    return httpService.get(`/api/return/withCustomer?page=${pageNumber}`);
}

export async function getReturnsByCustomerId(customerId, pageNumber) {
    return httpService.get(`/api/return/byCustomerId?customerId=${customerId}&page=${pageNumber}`);
}

export async function getDetailedReturn(id) {
    return httpService.get(`/api/return/detailed/${id}`);
}

export async function getReturn(id) {
    return httpService.get(`/api/return/${id}`);
}

export async function saveReturn(data) {
    if (!data._id) {
        return httpService.post(`/api/return`, data);
    }

    const body = { ...data };
    delete body._id;
    return httpService.put(`/api/return/${data._id}`, body);
}

export async function updateReturn(data, id) {
    return httpService.put(`/api/return/${id}`, data);
}

export async function deleteReturn(id) {
    return httpService.delete(`/api/return/${id}`);
}