import httpService from "./httpService.js";


export async function getCustomers() {
    return httpService.get(`/api/customers`);
}

export async function getCustomer(id) {
    return httpService.get(`/api/customers/${id}`);
}

export async function saveCustomer(customer) {
    if (!customer._id) {
        return httpService.post(`/api/customers`, customer);
    }

    const body = { ...customer };
    delete body._id;
}

export async function updateCustomer(customer, id) {
    return httpService.put(`/api/customers/${id}`, customer);
}

export async function deleteCustomer(id) {
    return httpService.delete(`/api/customers/${id}`);
}