import React, { Component } from 'react'
import { updateExpense, getExpense } from '../services/expenseService';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Joi from "joi-browser";
import moment from 'moment'
const company = require('./../company');


class UpdateExpense extends Component {
    _isMounted = false;

    state = {
        date: "",
        particulars: "",
        amount: "",
        remarks: "",
        userId: "",
        submittedBy: "",

        buttonDisabled: false,
        loading: true
    }

    populateExpense = async () => {
        const oldData = await getExpense(this.props.expenseId);

        this._isMounted && this.setState({
            date: oldData.data.date,
            particulars: oldData.data.particulars.toString(),
            amount: oldData.data.amount,
            remarks: oldData.data.remarks.toString()
        });

        if (oldData.data.particulars === "") {
            this.setState({ particulars: "" });
        }
        if (oldData.data.amount === "") {
            this.setState({ amount: 0 });
        }
        if (oldData.data.remarks === "") {
            this.setState({ remarks: "" });
        }
    }

    async componentDidMount() {
        this._isMounted = true;

        this._isMounted && await this.populateExpense();
        this._isMounted && this.setState({ 
            submittedBy: this.props.user.name,
            userId: this.props.user._id,
            loading: false })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChange = event => {
        const { name, value } = event.target
        this.setState({
            [name]: value
        })
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    validateExpense(expense) {
        const schema = {
            date: Joi.date().required(),
            particulars: Joi.string().max(1024).required(),
            amount: Joi.number().required(),
            remarks: Joi.string().max(1024).allow(''),
            userId: Joi.string().required(),
            submittedBy: Joi.string().required(),
        };
    
        return Joi.validate(expense, schema);
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        const data = { ...this.state };
        delete data.buttonDisabled;
        delete data.loading;

        const { error } = this.validateExpense(data)
        if (error) {
            toast.error(error.details[0].message)
        }
        else {
            this.setState({ buttonDisabled: true });
            try {
                await updateExpense(data, this.props.expenseId);
                toast.dark("Updated successfully")

                this.props.next()
            }
            catch (error) {
                toast.error(error);
            }
        }
    }

    render() {
        return (
            <div>
                <ToastContainer hideProgressBar position="bottom-right" />

                {this.state.loading
                    ?
                    <div>
                        <center>
                            <br />
                            <br />
                            <br />
                            <Spinner size="sm" animation="grow" variant="danger" />{' '}
                            <Spinner size="sm" animation="grow" variant="warning" />{' '}
                            <Spinner size="sm" animation="grow" variant="success" />
                        </center>
                    </div>
                    :
                    <div>
                        <Form
                            style={{ fontSize: '12px', padding: '0.25rem 0.5rem' }}
                            onSubmit={this.handleSubmit}
                            onKeyDown={this.handleKeyPress}
                        >
                            <Col xs={7}>
                                <div style={{ backgroundColor: '#f7faf8', padding: '20px', border: '0.5px solid red' }}>

                                    <center>
                                        {company.company}
                                        <h4>Update Expense</h4>
                                        <p><br /></p>
                                    </center>
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="date"
                                        value={moment(this.state.date).format("YYYY-MM-DD")}
                                        onChange={this.handleChange}
                                    />
                                    <br />
                                    <Form.Label>Particulars</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="particulars"
                                        value={this.state.particulars}
                                        onChange={this.handleChange}
                                    />
                                    <br />
                                    <Form.Label>Amount</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="amount"
                                        min={0}
                                        step="1"
                                        value={this.state.amount}
                                        onChange={this.handleChange}
                                    />
                                    <br />
                                    <Form.Label>Remarks</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="remarks"
                                        value={this.state.remarks}
                                        onChange={this.handleChange}
                                    />
                                    <br />
                                </div>
                            </Col>

                            <br />
                            <Col xs={7}>
                                <Button
                                    variant="dark"
                                    block
                                    size='sm'
                                    type="submit"
                                    disabled={this.state.buttonDisabled}
                                >
                                    Update Expense
                                </Button>

                                <Button
                                    variant="outline-dark"
                                    block
                                    size='sm'
                                    onClick={() => this.props.next()}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Form>
                    </div>
                }
            </div>
        )
    }
}

export default UpdateExpense