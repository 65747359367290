import React, { Component } from 'react'
import ExploreEquipment from './ExploreEquipment';
import ExploreCategory from './ExploreCategory';
import { ToastContainer } from 'react-toastify';


class ManageEquipments extends Component {
    render() {

        return (
            <div>
                <ToastContainer position="bottom-right" hideProgressBar />

                <ExploreCategory updateCategory={this.props.updateCategory}/>
                <br /><br />
                <ExploreEquipment updateEquipment={this.props.updateEquipment}/>
            </div>
        );
    }
}

export default ManageEquipments;