import httpService from "./httpService.js";


export async function getCategories() {
    return httpService.get(`/api/category`);
}

export async function getCategory(id) {
    return httpService.get(`/api/category/${id}`);
}

export async function saveCategory(data) {
    if (!data._id) {
        return httpService.post(`/api/category`, data);
    }

    const body = { ...data };
    delete body._id;
    return httpService.put(`/api/category/${data._id}`, body);
}

export async function updateCategory(data, id) {
    return httpService.put(`/api/category/${id}`, data);
}

export async function deleteCategory(id) {
    return httpService.delete(`/api/category/${id}`);
}