import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import CreateCategory from '../components/CreateCategory'
import CreateEquipment from '../components/CreateEquipment'
import ManageEquipments from '../components/ManageEquipments'
import UpdateCategory from '../components/UpdateCategory'
import UpdateEquipment from '../components/UpdateEquipment'
import { ToastContainer } from 'react-toastify';


class Manage extends Component {
	state = {
		view: "manage",
		categoryId: "",
		equipmentId: ""
	}

	createCategory = () => {
		this.setState({
			view: "createCategory"
		})
	}

	createEquipment = () => {
		this.setState({
			view: "createEquipment"
		})
	}

	manage = () => {
		this.setState({
			view: "manage"
		})
	}

	updateCategory = (id) => {
		this.setState({
			categoryId: id
		}, () => {
			this.setState({ view: "updateCategory" })
		})
	}

	updateEquipment = (id) => {
		this.setState({
			equipmentId: id
		}, () => {
			this.setState({ view: "updateEquipment" })
		})
	}

	renderView = () => {
		if (this.state.view==="manage") {
			return (
				<div>
					<ManageEquipments updateCategory={this.updateCategory} updateEquipment={this.updateEquipment}/>
				</div>
			)
		}
		else if (this.state.view==="createCategory") {
			return (
				<div>
					<CreateCategory />
				</div>
			)
		}
		else if (this.state.view==="createEquipment") {
			return (
				<div>
					<CreateEquipment />
				</div>
			)
		}
		else if (this.state.view==="updateCategory") {
			return (
				<div>
					<UpdateCategory categoryId={this.state.categoryId} next={this.manage}/>
				</div>
			)
		}
		else if (this.state.view==="updateEquipment") {
			return (
				<div>
					<UpdateEquipment equipmentId={this.state.equipmentId} next={this.manage}/>
				</div>
			)
		}
	}

	render() {
		return (
			<div>
				<Container>
				<ToastContainer position="bottom-right" hideProgressBar />
					
					<Button size='sm' variant="dark" onClick={this.manage}>
						Manage
					</Button>{' '}
						
					<Button size='sm' variant="dark" onClick={this.createCategory}>
						Create New Category
					</Button>{' '}

					<Button size='sm' variant="dark" onClick={this.createEquipment}>
						Create New Equipment
					</Button>{' '}

					<hr />
					<br />

					{ this.renderView() }
				</Container>			
			</div>
		)
	}
}

export default Manage