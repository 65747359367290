import React, { Component } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
// import moment from 'moment'
const company = require('./../company');


class PrintReport extends Component {
    componentDidMount = () => {
        console.log(this.props)
        window.print()
    }

    render() {
        return (
            <div>
                <Container>

                    <div>
                        <center>
                            <br />
                            <b>{company.company}</b>
                            <h2>Transaction Summary</h2>
                            <p>From <b>{this.props.payload.date1}</b> to <b>{this.props.payload.date2}</b></p>
                        </center>
                        <br />
                        <br />
                        <Row>
                            <Col>
                                <p><b>Number of Bookings</b><span style={{ float: 'right' }}>{this.props.payload.summary.bookingCount}</span></p>
                                <p><b>Booking Amount</b><span style={{ float: 'right' }}>{this.props.payload.summary.bookingAmount}</span></p>
                                <br /><br />
                                <p><b>Number of Payments</b><span style={{ float: 'right' }}>{this.props.payload.summary.paymentCount}</span></p>
                                <p><b>Payment Amount</b><span style={{ float: 'right' }}>{this.props.payload.summary.paymentAmount}</span></p>
                                <p><b>(Less) Discount</b><span style={{ float: 'right' }}>{this.props.payload.summary.discount}</span></p>
                                <hr />
                                <p><b>Total Earning</b><span style={{ float: 'right' }}>{this.props.payload.summary.earning}</span></p>
                                <p><b>(Less) Total Expenses</b><span style={{ float: 'right' }}>{this.props.payload.summary.totalExpenses}</span></p>
                                <hr />
                                <p><b>Total Income</b><span style={{ float: 'right' }}>{this.props.payload.summary.totalIncome}</span></p>
                            </Col>
                            <Col></Col>

                            <Col>
                                <p><b>Salary</b><span style={{ float: 'right' }}>{this.props.payload.summary.salary}</span></p>
                                <p><b>Bonus</b><span style={{ float: 'right' }}>{this.props.payload.summary.bonus}</span></p>
                                <p><b>(Less) Deductions</b><span style={{ float: 'right' }}>{this.props.payload.summary.deductions}</span></p>
                                <hr />
                                <p><b>Total Employee Expenses</b><span style={{ float: 'right' }}>{this.props.payload.summary.totalEmployeeExpenses}</span></p>
                                <p><b>Other Expenses</b><span style={{ float: 'right' }}>{this.props.payload.summary.otherExpenses}</span></p>
                                <hr />
                                <p><b>Total Expenses</b><span style={{ float: 'right' }}>{this.props.payload.summary.totalExpenses}</span></p>
                            </Col>
                        </Row>
                        <br />
                    </div>

                </Container>
            </div>
        )
    }
}

export default PrintReport