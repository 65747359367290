import React, { Component } from 'react';
import { getPayment, updatePayment } from '../services/paymentService';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'
import moment from 'moment'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Joi, { cloneWithShallow } from "joi-browser";
const company = require('./../company');


class UpdatePayment extends Component {
    _isMounted = false;
    bookingAmount = 0;

    state = {
        payments: [],
        submittedBy: "",
        userId: "",

        buttonDisabled: false,
        loading: true
    }

    populatePayment = async () => {
        const { data: payments } = await getPayment(this.props.paymentId);
        // this._isMounted && this.setState({ payments })
        if (this._isMounted) {
            this.setState({ payments });
            // Calculate and store bookingAmount only once after getting payment data
            const payment = payments.payment || {};
            const totalAmount = parseInt(payment.totalAmount) || 0;
            const settlementAmount = parseInt(payment.settlementAmount) || 0;
            this.bookingAmount = totalAmount - settlementAmount;
        }
    }

    async componentDidMount() {
        this._isMounted = true;

        this._isMounted && await this.populatePayment();
        this._isMounted && this.setState({
            submittedBy: this.props.user.name,
            userId: this.props.user._id,
            loading: false,
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChange = event => {
        const { name, value } = event.target;

        this.setState(
            prevState => ({
                payments: {
                    ...prevState.payments,
                    payment: {
                        ...prevState.payments.payment,
                        [name]: value
                    }
                }
            }),
            () => {
                this.calculateTotalPaid();
            }
        );
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    validatePayment = (data) => {
        const schema = {
            date: Joi.date().required(),
            bookingId: Joi.string().required(),
            items: Joi.array().items(Joi.object({
                particulars: Joi.string().required(),
                equipmentId: Joi.string().required(),
                out: Joi.number().required(),
                in: Joi.number().required(),
                days: Joi.number().required(),
                qty: Joi.number().required(),
                codeNo: Joi.string().required(),
                remarks: Joi.string().allow('')
            })).required(),
            settlementAmount: Joi.number().required(), 
            totalAmount: Joi.number().required(),
            advance: Joi.number().required(),
            discount: Joi.number().required(),
            totalPaid: Joi.number().required(),
            customerId: Joi.string().required(),
            userId: Joi.string().required(),
            submittedBy: Joi.string().required(),
            comments: Joi.string().max(1024).allow(''),
            returnedDate: Joi.string().required()
        };

        return Joi.validate(data, schema);
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        const shouldUpdate = window.confirm('-----------------------------------------------------\n     Do you really want to save changes ?\n-----------------------------------------------------');
        if (shouldUpdate) {
            let data = { ...this.state.payments.payment };

            delete data._id;
            data.items = data.items.map(item => {
                const { _id, ...itemWithoutId } = item;
                return itemWithoutId;
            });
            delete data.dateModified;
            data.submittedBy = this.props.user.name;
            data.userId = this.props.user._id;

            const { error } = this.validatePayment(data)
            if (error) {
                toast.error(error.details[0].message)
            }
            else {
                this.setState({ buttonDisabled: true });
                try {
                    await updatePayment(data, this.props.paymentId);
                    toast.dark("Updated successfully")

                    this.props.next()
                }
                catch (error) {
                    toast.error(error);
                }
            }
        }
    }

    calculateTotalPaid = () => {
        const balance = (this.state.payments.booking.balance === '') ? 0 : this.state.payments.booking.balance;
        const discount = (this.state.payments.payment.discount === '') ? 0 : this.state.payments.payment.discount;
        const settlementAmount = (this.state.payments.payment.settlementAmount === '') ? 0 : this.state.payments.payment.settlementAmount;

        const totalPaid = parseInt(settlementAmount) + parseInt(balance) - parseInt(discount);
        this.setState(prevState => ({
            payments: {
                ...prevState.payments,
                payment: {
                    ...prevState.payments.payment,
                    totalPaid: totalPaid
                }
            }
        }));
    }

    render() {
        const labelStyle = {
            display: 'inline-block',
            width: '120px',
            marginRight: '10px',
            textAlign: 'left'
        };

        const valueStyle = {
            display: 'inline-block',
        };

        return (
            <div>
                <ToastContainer hideProgressBar position="bottom-right" />

                {this.state.loading
                    ?
                    <div>
                        <center>
                            <br />
                            <br />
                            <br />
                            <Spinner size="sm" animation="grow" variant="danger" />{' '}
                            <Spinner size="sm" animation="grow" variant="warning" />{' '}
                            <Spinner size="sm" animation="grow" variant="success" />
                        </center>
                    </div>
                    :
                    <div>
                        <Form
                            style={{ fontSize: '12px', padding: '0.25rem 0.5rem' }}
                            onSubmit={this.handleSubmit}
                            onKeyDown={this.handleKeyPress}
                        >

                            <div style={{ backgroundColor: '#FAFAFA', padding: '20px', border: '0.5px solid red' }}>
                                <center>
                                    {company.company}
                                    <h4>Update Payment Details</h4>
                                    <p><br /></p>
                                </center>
                                <div style={{ fontSize: '15px' }}>
                                    <Row>
                                        <Col>
                                            <div>
                                                <span style={labelStyle}><strong>Bill Number:</strong></span>
                                                <span style={valueStyle}>{this.state.payments.booking.billNum}</span>
                                            </div>
                                            <div>
                                                <span style={labelStyle}><strong>Program:</strong></span>
                                                <span style={valueStyle}>{this.state.payments.booking.program}</span>
                                            </div>
                                            <div>
                                                <span style={labelStyle}><strong>Venue:</strong></span>
                                                <span style={valueStyle}>{this.state.payments.booking.venue}</span>
                                            </div>
                                            <div>
                                                <span style={labelStyle}><strong>Event Date:</strong></span>
                                                <span style={valueStyle}>{moment(this.state.payments.booking.eventDate).format('MMMM DD, YYYY')}</span>
                                            </div>
                                            <div>
                                                <span style={labelStyle}><strong>Return Date:</strong></span>
                                                <span style={valueStyle}>{moment(this.state.payments.booking.returnedDate).format('MMMM DD, YYYY')}</span>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div>
                                                <span style={labelStyle}><strong>Customer Name:</strong></span>
                                                <span style={valueStyle}>{this.state.payments.customer.customerName}</span>
                                            </div>
                                            <div>
                                                <span style={labelStyle}><strong>PAN:</strong></span>
                                                <span style={valueStyle}>{this.state.payments.customer.panNum}</span>
                                            </div>
                                            <div>
                                                <span style={labelStyle}><strong>Mobile:</strong></span>
                                                <span style={valueStyle}>{this.state.payments.customer.mobile}</span>
                                            </div>
                                            <div>
                                                <span style={labelStyle}><strong>Address:</strong></span>
                                                <span style={valueStyle}>{this.state.payments.customer.address}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>


                                <br />
                                <br />

                                <Form.Row>
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control
                                            style={{ width: '200px', height: '30px' }}
                                            type="date"
                                            name="date"
                                            value={moment(this.state.payments.payment.date).format("YYYY-MM-DD")}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                    <Col></Col><Col></Col>
                                </Form.Row>

                                <br />
                                <br />

                                <Table size='sm' striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '50%' }}>Particulars</th>
                                            <th style={{ width: '10%', textAlign: 'center' }}>Code No</th>
                                            <th style={{ width: '10%', textAlign: 'center' }}>Day</th>
                                            <th style={{ width: '10%', textAlign: 'center' }}>Qty</th>
                                            <th style={{ width: '10%', textAlign: 'center' }}>Out</th>
                                            <th style={{ width: '10%', textAlign: 'center' }}>In</th>
                                        </tr>
                                    </thead>
                                    <tbody>{this.state.payments.payment.items?.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.particulars}</td>
                                            <td style={{ width: '10%', textAlign: 'center' }}>{item.codeNo}</td>
                                            <td style={{ width: '10%', textAlign: 'center' }}>{item.days}</td>
                                            <td style={{ width: '10%', textAlign: 'center' }}>{item.qty}</td>
                                            <td style={{ width: '10%', textAlign: 'center' }}>{item.out}</td>
                                            <td style={{ width: '10%', textAlign: 'center', backgroundColor: item.in < item.out ? '#FFC4C4' : '' }}>{item.in}</td>
                                        </tr>
                                    ))}</tbody>
                                </Table>

                                <br />
                                <br />

                                <Form.Row>
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label>Technicians</Form.Label>
                                        <div style={{ paddingRight: '100px' }}>
                                            <Table size='sm' bordered>
                                                <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Contact</th>
                                                    </tr>
                                                </thead>
                                                <tbody>{this.state.payments.technicians?.map((technician, index) => (
                                                    <tr key={index}>
                                                        <td>{technician.employee.employeeName}</td>
                                                        <td>{technician.employee.phone}</td>
                                                    </tr>
                                                ))}</tbody>
                                            </Table>
                                        </div>
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="">
                                        <Form.Label>Comments</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={2}
                                            name="comments"
                                            value={this.state.payments.payment.comments}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                </Form.Row>

                                <br />
                                <hr />
                                <br />

                                <div style={{ fontSize: '14px' }}>
                                    <Row>
                                        <Col>
                                            <span><strong>Booking Amount:</strong></span>
                                        </Col>
                                        <Col>
                                            <span>{this.bookingAmount}</span>
                                        </Col>
                                        <Col></Col><Col></Col><Col></Col>
                                    </Row>
                                    <Row style={{ alignItems: 'center' }}>
                                        <Col>
                                            <span><strong>Settlement Amount:</strong></span>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                style={{ width: '100px', height: '30px' }}
                                                type="number"
                                                min={0}
                                                name="settlementAmount"
                                                value={this.state.payments.payment.settlementAmount}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                        <Col></Col><Col></Col><Col></Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <span><strong>Advance Payment:</strong></span>
                                        </Col>
                                        <Col>
                                            <span>{this.state.payments.payment.advance}</span>
                                        </Col>
                                        <Col></Col><Col></Col><Col></Col>
                                    </Row>
                                    {/* <Row>
                                        <Col>
                                            <span><strong>Balance:</strong></span>
                                        </Col>
                                        <Col>
                                            <span>{this.state.payments.booking.balance}</span>
                                            <hr style={{ width: '100px', marginLeft: '0' }} />
                                        </Col>
                                        <Col></Col><Col></Col><Col></Col>
                                    </Row> */}
                                    <Row style={{ alignItems: 'center' }}>
                                        <Col>
                                            <span><strong>Discount:</strong></span>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                style={{ width: '100px', height: '30px' }}
                                                type="number"
                                                min={0}
                                                max={this.state.payments.payment.balance}
                                                name="discount"
                                                value={this.state.payments.payment.discount}
                                                onChange={this.handleChange}
                                            />
                                        </Col>
                                        <Col></Col><Col></Col><Col></Col>
                                    </Row>
                                    <br />
                                    <Row style={{ alignItems: 'center' }}>
                                        <Col>
                                            <span><strong>Payment Amount:</strong></span>
                                        </Col>
                                        <Col>
                                            <Form.Control
                                                style={{ width: '100px', height: '30px' }}
                                                type="number"
                                                name="totalPaid"
                                                value={this.state.payments.payment.totalPaid}
                                                onChange={this.handleChange}
                                                disabled
                                            />
                                        </Col>
                                        <Col></Col><Col></Col><Col></Col>
                                    </Row>
                                </div>
                            </div>
                            <div style={{ textAlign: 'right', fontSize: '12px' }}>
                                User: <b>{this.state.payments.payment.submittedBy}</b>
                            </div>

                            <br />
                            <Button
                                variant="dark"
                                size="sm"
                                type="submit"
                                disabled={this.state.buttonDisabled}
                            >
                                Save Changes
                            </Button>{" "}
                            <Button
                                variant="outline-dark"
                                size='sm'
                                onClick={() => this.props.next()}
                            >
                                Cancel
                            </Button>
                        </Form>
                    </div>
                }
            </div>
        )
    }
}


export default UpdatePayment