import httpService from "./httpService.js";


export async function getEmployees() {
    return httpService.get(`/api/employee`);
}

export async function getEmployee(id) {
    return httpService.get(`/api/employee/${id}`);
}

export async function saveEmployee(employee) {
    if (!employee._id) {
        return httpService.post(`/api/employee`, employee);
    }

    const body = { ...employee };
    delete body._id;
    return httpService.put(`/api/employee/${employee._id}`, body);
}

export async function updateEmployee(employee, id) {
    return httpService.put(`/api/employee/${id}`, employee);
}

export async function deleteEmployee(id) {
    return httpService.delete(`/api/employee/${id}`);
}