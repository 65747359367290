import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import DeliveryForm from '../components/DeliveryForm'
import MakeDelivery from '../components/MakeDelivery'
import ExploreDelivery from '../components/ExploreDelivery'
import SearchDelivery from '../components/SearchDelivery'
import UpdateDelivery from '../components/UpdateDelivery'
import { ToastContainer } from 'react-toastify';


class Delivery extends Component {
	state = {
		view: "makeDelivery",
		bookingId: "",
		deliveryId: "",
	}

	makeDelivery = () => {
		this.setState({
			view: "makeDelivery"
		})
	}

	deliveryForm = (bookingId) => {
		this.setState({ bookingId }, () => {
			this.setState({ view: "deliveryForm" })
		})
	}

	explore = () => {
		this.setState({
			view: "explore"
		})
	}

	search = () => {
		this.setState({
			view: "search"
		})
	}

	update = (deliveryId) => {
		this.setState({ deliveryId }, () => {
			this.setState({ view: "update" })
		})
	}

	renderView = () => {
		if (this.state.view === "makeDelivery") {
			return (
				<div>
					<MakeDelivery user={this.props.user} deliveryForm={this.deliveryForm} />
				</div>
			)
		}
		else if (this.state.view === "deliveryForm") {
			return (
				<div>
					<DeliveryForm user={this.props.user} bookingId={this.state.bookingId} next={this.explore} />
				</div>
			)
		}
		else if (this.state.view === "search") {
			return (
				<div>
					<SearchDelivery user={this.props.user} update={this.update} printDelivery={this.props.printDelivery} />
				</div>
			)
		}
		else if (this.state.view === "explore") {
			return (
				<div>
					<ExploreDelivery user={this.props.user} update={this.update} printDelivery={this.props.printDelivery} />
				</div>
			)
		}
		else if (this.state.view === "update") {
			return (
				<div>
					<UpdateDelivery user={this.props.user} deliveryId={this.state.deliveryId} next={this.explore} />
				</div>
			)
		}
	}

	render() {
		return (
			<div>
				<Container>
					<ToastContainer position="bottom-right" hideProgressBar />
					<Button size='sm' variant="dark" onClick={this.makeDelivery}>
						Make Delivery
					</Button>{" "}

					<Button size='sm' variant="dark" onClick={this.search}>
						Search
					</Button>{" "}

					<Button size='sm' variant="dark" onClick={this.explore}>
						History
					</Button>{" "}

					<hr />

					{this.renderView()}
				</Container>
			</div>
		)
	}
}

export default Delivery