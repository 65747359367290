import React, { Component } from 'react';
import { saveBooking } from '../services/bookingService';
import { getCustomers } from '../services/customerService';
import { getEquipments } from '../services/equipmentService';
import { getCurrentDate } from '../utils/utils';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Table from 'react-bootstrap/Table'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import moment from 'moment'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Joi from "joi-browser";
const company = require('./../company');


class BookingForm extends Component {
	_isMounted = false;

	state = {
		date: "",
		eventDate: "",
		venue: "",
		program: "",
		billNum: "",

		items: [
			{
				particulars: "",
				equipmentId: "",
				days: "",
				qty: "",
				codeNo: "",
				remarks: ""
			}
		],

		totalAmount: 0,
		advance: 0,
		balance: 0,
		customerId: "",
		customerName: "",
		userId: "",
		submittedBy: "",
		additional_phone: "",
		additional_phone_2: "",
		comments: "",
		deliveryId: "",
		returnId: "",
		paymentId: "",

		customers: [],
		equipments: [],

		mobile: "",
		panNum: "",
		address: "",

		buttonDisabled: false,
		loading: true
	}

	populateCustomers = async () => {
		const { data: customers } = await getCustomers();
		this._isMounted && this.setState({ customers })
	}

	populateEquipments = async () => {
		const { data: equipments } = await getEquipments();
		this._isMounted && this.setState({ equipments })
	}

	async componentDidMount() {
		this._isMounted = true;

		this._isMounted && await this.populateCustomers();
		this._isMounted && await this.populateEquipments();
		this._isMounted && this.setState({
			loading: false,
			submittedBy: this.props.user.name,
			userId: this.props.user._id,
			date: getCurrentDate()
		})
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	handleChange = event => {
		this.setState({
			[event.target.name]: event.target.value
		}, () => {
			this.calculateBalance()
		});
	}

	handleCustomerSelect = e => {
		this.setState({
			customerId: e.value,
			customerName: e.label,
			panNum: e.panNum,
			mobile: e.mobile,
			address: e.address
		})
	}

	handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	}

	validateBooking = (data) => {
		const schema = {
			date: Joi.date().required(),
			eventDate: Joi.date().required(),
			venue: Joi.string().max(1024).required(),
			program: Joi.string().max(1024).required(),
			billNum: Joi.string().required(),
			items: Joi.array().items(Joi.object({
				particulars: Joi.string().required(),
				equipmentId: Joi.string().required(),
				days: Joi.number().required(),
				qty: Joi.number().required(),
				codeNo: Joi.string().required(),
				remarks: Joi.string().allow('')
			})),
			totalAmount: Joi.number().required(),
			advance: Joi.number().required(),
			balance: Joi.number().required(),
			customerId: Joi.string().required(),
			userId: Joi.string().required(),
			submittedBy: Joi.string().allow(''),
			additional_phone: Joi.string().max(1024).allow(''),
			additional_phone_2: Joi.string().max(1024).allow(''),
			comments: Joi.string().max(1024).allow(''),
			deliveryId: Joi.string().allow(''),
			returnId: Joi.string().allow(''),
			paymentId: Joi.string().allow('')
		};

		return Joi.validate(data, schema);
	}

	handleSubmit = async (event) => {
		event.preventDefault();

		if (parseInt(this.state.advance) > parseInt(this.state.totalAmount)) {
			toast.error("Advance should be less than or equal to Total Amount")
		}
		else {
			const data = { ...this.state };
			delete data.customers;
			delete data.customerName;
			delete data.address;
			delete data.equipments;
			delete data.mobile;
			delete data.panNum;
			delete data.values;
			delete data.buttonDisabled;
			delete data.loading;

			data.advance = parseInt(data.advance)

			// Filter out items with null or empty equipmentId
			data.items = data.items.filter(item => item.equipmentId !== null && item.equipmentId !== '');

			const { error } = this.validateBooking(data)
			if (error) {
				toast.error(error.details[0].message)
			}
			else {
				this.setState({ buttonDisabled: true });
				try {
					await saveBooking(data);
					toast.dark("Booked successfully")

					this.props.next()
				}
				catch (error) {
					toast.error(error);
				}
			}
		}
	}

	handleRecordChange = (index, e) => {
		const values = [...this.state.items];
		values[index][e.target.name] = e.target.value;
		this.setState({ values }, () => {
			this.calculateBalance()
		});
	}

	// calculateAmount = index => {
	// 	const values = [...this.state.items];
	// 	values[index].amount = values[index].days * values[index].qty * values[index].rate;

	// 	this.setState({ values }, () => {
	// 		this.calculateTotalAmount();
	// 	});
	// }

	// calculateTotalAmount = () => {
	// 	const totalAmount = this.state.items.reduce((total, item) => {
	// 		return total * 1 + item.amount * 1;
	// 	}, 0);

	// 	this.setState({ totalAmount }, () => {
	// 		this.calculateBalance();
	// 	});
	// }

	calculateBalance = () => {
		const balance = this.state.totalAmount - this.state.advance;
		this.setState({ balance });
	}

	handleEquipmentSelect = (index, e) => {
		const values = [...this.state.items];
		values[index].particulars = e.value;
		values[index].equipmentId = e.equipmentId;
		values[index].codeNo = e.codeNo;

		this.setState({ values })
	}

	handleRecordAdd = () => {
		this.setState((prevState) => ({
			items: [...prevState.items, {
				particulars: "",
				equipmentId: "",
				days: "",
				qty: "",
				codeNo: "",
				remarks: ""
			}]
		}), () => {
			this.calculateBalance()
		})
	}

	handleRecordRemove = (index) => {
		if (this.state.items.length > 1) {
			const values = [...this.state.items];
			values.splice(index, 1);

			this.setState({ items: values }, () => {
				this.calculateBalance()
			});
		}
	}

	render() {
		const labelStyle = {
			display: 'inline-block',
			width: '160px',
			marginRight: '10px',
			textAlign: 'left'
		};

		const valueStyle = {
			display: 'inline-block'
		};

		return (
			<div>
				<ToastContainer hideProgressBar position="bottom-right" />

				{this.state.loading
					?
					<div>
						<center>
							<br />
							<br />
							<br />
							<Spinner size="sm" animation="grow" variant="danger" />{' '}
							<Spinner size="sm" animation="grow" variant="warning" />{' '}
							<Spinner size="sm" animation="grow" variant="success" />
						</center>
					</div>
					:
					<div>
						<Form
							style={{ fontSize: '12px', padding: '0.25rem 0.5rem' }}
							onSubmit={this.handleSubmit}
							onKeyDown={this.handleKeyPress}
						>
							<div style={{ backgroundColor: '#F0F7FF', padding: '20px' }}>
								<center>
									{company.company}
									<h4>Booking Form</h4>
									<p><br /></p>
								</center>

								<div style={{ fontSize: '15px' }}>
									<Row>
										<Col>
											<div>
												<span style={labelStyle}><strong>Date:</strong></span>
												<span style={valueStyle}>
													<Form.Control
														style={{ width: '200px', height: '20px' }}
														type="date"
														name="date"
														value={this.state.date}
														onChange={this.handleChange}
														readOnly
													/>
												</span>
											</div>
											<div>
												<span style={labelStyle}><strong>Bill Number:</strong></span>
												<span style={valueStyle}>
													<Form.Control
														style={{ width: '200px', height: '20px' }}
														type="text"
														name="billNum"
														value={this.state.billNum}
														onChange={this.handleChange}
													/>
												</span>
											</div>
											<div>
												<span style={labelStyle}><strong>Program:</strong></span>
												<span style={valueStyle}>
													<Form.Control
														style={{ width: '200px', height: '20px' }}
														type="text"
														name="program"
														value={this.state.program}
														onChange={this.handleChange}
													/>
												</span>
											</div>
											<div>
												<span style={labelStyle}><strong>Venue:</strong></span>
												<span style={valueStyle}>
													<Form.Control
														style={{ width: '200px', height: '20px' }}
														type="text"
														name="venue"
														value={this.state.venue}
														onChange={this.handleChange}
													/>
												</span>
											</div>
											<div>
												<span style={labelStyle}><strong>Event Date:</strong></span>
												<span style={valueStyle}>
													<Form.Control
														style={{ width: '200px', height: '20px' }}
														type="date"
														name="eventDate"
														value={moment(this.state.eventDate).format("YYYY-MM-DD")}
														onChange={this.handleChange}
													/>
												</span>
											</div>
											<br />
											<div>
												<span style={labelStyle}><strong>Additional Phone 1:</strong></span>
												<span style={valueStyle}>
													<Form.Control
														style={{ width: '200px', height: '20px' }}
														type="text"
														name="additional_phone"
														value={this.state.additional_phone}
														onChange={this.handleChange}
													/>
												</span>
											</div>
											<div>
												<span style={labelStyle}><strong>Additional Phone 2:</strong></span>
												<span style={valueStyle}>
													<Form.Control
														style={{ width: '200px', height: '20px' }}
														type="additional_phone_2"
														name="venue"
														value={this.state.additional_phone_2}
														onChange={this.handleChange}
													/>
												</span>
											</div>
										</Col>
										<Col>
											<div>
												<span style={labelStyle}><strong>Customer Name:</strong></span>
												<span style={valueStyle}>{this.state.customerName}</span>
											</div>
											<div>
												<span style={labelStyle}><strong>PAN:</strong></span>
												<span style={valueStyle}>{this.state.panNum}</span>
											</div>
											<div>
												<span style={labelStyle}><strong>Mobile:</strong></span>
												<span style={valueStyle}>{this.state.mobile}</span>
											</div>
											<div>
												<span style={labelStyle}><strong>Address:</strong></span>
												<span style={valueStyle}>{this.state.address}</span>
											</div>
											<br />
											<div>
												<span style={labelStyle}><strong>Choose Customer:</strong></span>
												<span style={valueStyle}>
													<div style={{ fontSize: '12px', width: '200px' }}>
														<Select
															onChange={this.handleCustomerSelect}
															options={this.state.customers?.map(i => ({
																value: i._id,
																label: i.customerName,
																panNum: i.panNum,
																mobile: i.mobile,
																address: i.address
															}))}
															menuPortalTarget={document.body}
															styles={{
																menuPortal: base => ({ ...base, zIndex: 9999 }),
																control: (provided) => ({ ...provided, minHeight: 30, height: 30, maxHeight: 30, padding: '0px' }),
																valueContainer: (provided) => ({ ...provided, padding: '0px 8px' }),
																option: (provided, state) => ({
																	...provided,
																	fontSize: '12px'
																}),
																singleValue: (provided, state) => ({
																	...provided,
																	fontSize: '12px',
																	transform: 'none',
																	margin: '0px'
																}),
															}}
														/>
													</div>
												</span>
											</div>
										</Col>
									</Row>
								</div>

								<br />
								<hr />
								<br />

								<Table size='sm' responsive style={{ border: 'none' }}>
									<thead>
										<tr style={{ border: 'none' }}>
											<th style={{ width: '60%', border: 'none' }}>Particulars</th>
											<th style={{ width: '10%', border: 'none' }} className="text-center">Code No</th>
											<th style={{ width: '10%', border: 'none' }} className="text-center">Days</th>
											<th style={{ width: '10%', border: 'none' }} className="text-center">Quantity</th>
											<th style={{ width: '10%', border: 'none' }} className="text-center">Action</th>
										</tr>
									</thead>
									<tbody>
										{this.state.items.map((i, index) => (
											<tr key={"Cr" + i + index}>
												<td>
													<Select
														onChange={e => this.handleEquipmentSelect(index, e)}
														options={this.state.equipments?.map(i => ({
															value: i.equipmentName,
															label: i.equipmentName + ' ' + i.code,
															codeNo: i.code,
															equipmentId: i._id
														}))}
														menuPortalTarget={document.body}
														styles={{
															menuPortal: base => ({ ...base, zIndex: 9999 }),
															control: (provided) => ({ ...provided, minHeight: 30, height: 30, maxHeight: 30, padding: '0px' }),
															valueContainer: (provided) => ({ ...provided, padding: '0px 8px' }),
															option: (provided, state) => ({
																...provided,
																fontSize: '12px'
															}),
															singleValue: (provided, state) => ({
																...provided,
																fontSize: '12px',
																transform: 'none',
																margin: '0px'
															}),
														}}
													/>
												</td>
												<td className="text-center">
													<Form.Control
														style={{ textAlign: "center", height: '30px', fontSize: "12px" }}
														type="text"
														name="codeNo"
														value={i.codeNo}
														onChange={e => this.handleRecordChange(index, e)}
														readOnly
													/>
												</td>
												<td className="text-center">
													<Form.Control
														style={{ textAlign: "center", height: '30px', fontSize: "12px" }}
														type="number"
														step="1"
														min={0}
														name="days"
														value={i.days}
														onChange={e => this.handleRecordChange(index, e)}
													/>
												</td>
												<td className="text-center">
													<Form.Control
														style={{ textAlign: "center", height: '30px', fontSize: "12px" }}
														type="number"
														step="1"
														min={0}
														name="qty"
														value={i.qty}
														onChange={e => this.handleRecordChange(index, e)}
													/>
												</td>
												<td className="text-center">
													<Button variant="light" size="sm" onClick={this.handleRecordAdd}>
														+
													</Button>{' '}

													<Button variant="light" size="sm" onClick={() => this.handleRecordRemove(index)}>
														-
													</Button>
												</td>
											</tr>
										))}
									</tbody>
								</Table>

								<br />

								<div style={{ fontSize: '15px' }}>
									<Row>
										<Col>
											<div>
												<span style={labelStyle}><strong>Total Amount:</strong></span>
												<span style={valueStyle}>
													<Form.Control
														style={{ width: '200px', height: '20px' }}
														type="number"
														name="totalAmount"
														min={0}
														value={this.state.totalAmount}
														onChange={this.handleChange}
													/>
												</span>
											</div>
											<div>
												<span style={labelStyle}><strong>Advance Payment:</strong></span>
												<span style={valueStyle}>
													<Form.Control
														style={{ width: '200px', height: '20px' }}
														type="number"
														name="advance"
														min={0}
														max={this.state.totalAmount}
														value={this.state.advance}
														onChange={this.handleChange}
													/>
												</span>
											</div>
											<div>
												<span style={labelStyle}><strong>Balance Amount:</strong></span>
												<span style={valueStyle}>
													<Form.Control
														style={{ width: '200px', height: '20px' }}
														type="number"
														name="balance"
														value={this.state.balance}
														onChange={this.handleChange}
														readOnly
													/>
												</span>
											</div>
										</Col>
										<Col></Col>
									</Row>

									<br />
									
									<div>
										<span style={labelStyle}><strong>Comments:</strong></span>
										<span>
											<Form.Control
												style={{ height: '30px', width: '370px' }}
												type="text"
												name="comments"
												value={this.state.comments}
												onChange={this.handleChange}
											/>
										</span>
									</div>
								</div>
							</div>

							<div style={{ textAlign: 'right', fontSize: '12px' }}>
								Submitted By: <b>{this.state.submittedBy}</b>
							</div>

							<br />
							<Button
								variant="dark"
								type="submit"
								size='sm'
								disabled={this.state.buttonDisabled}
								style={{ marginRight: 5 }}
							>
								Confirm Booking
							</Button>
						</Form>
					</div >
				}
			</div>

		)
	}
}

export default BookingForm