import React, { Component } from 'react'
import { updateEquipment, getEquipments, getEquipment } from '../services/equipmentService';
import { getCategories } from '../services/categoryService';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Joi from "joi-browser";
const company = require('./../company');


class UpdateEquipment extends Component {
    _isMounted = false;

    state = {
        equipmentName: "",
        code: "",
        stock: "",
        categoryName: "",
        categoryId: "",
        description: "",

        categories: [],
        buttonDisabled: false,
        loading: true
    }

    populateCategories = async () => {
        const { data: categories } = await getCategories();
        this._isMounted && this.setState({ categories })
    }

    populateEquipments = async () => {
        const { data: equipments } = await getEquipments();
        const p = [];

        equipments.forEach(i => {
            p.push(i.equipmentName)
        })
        this._isMounted && this.setState({ equipments: p })
    }

    populateEquipment = async () => {
        const oldData = await getEquipment(this.props.equipmentId);

        // Remove old equipment name from the equipments array
        const updatedEquipments = this.state.equipments.filter(equipment => equipment !== oldData.data.equipmentName.toString());
        const categoryName = this.state.categories.filter(i => i._id === oldData.data.categoryId)[0].categoryName.toString();

        this._isMounted && this.setState({
            equipmentName: oldData.data.equipmentName.toString(),
            code: oldData.data.code.toString(),
            stock: oldData.data.stock,
            categoryId: oldData.data.categoryId,
            description: oldData.data.description.toString(),

            equipments: updatedEquipments,
            categoryName

        });
        if (oldData.data.equipmentName === "") {
            this.setState({ equipmentName: "" });
        }
        if (oldData.data.code === "") {
            this.setState({ code: "" });
        }
        if (oldData.data.stock === "") {
            this.setState({ stock: 0 });
        }
        if (oldData.data.description === "") {
            this.setState({ description: "" });
        }
    }

    async componentDidMount() {
        this._isMounted = true;

        this._isMounted && await this.populateCategories();
        this._isMounted && await this.populateEquipments();
        this._isMounted && await this.populateEquipment();
        this._isMounted && this.setState({ loading: false })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChange = event => {
        const { name, value } = event.target
        this.setState({
            [name]: value
        })
    }

    handleCategorySelect = async e => {
		this.setState({
			categoryId: e.value,
            categoryName: e.categoryName
		})
	}

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    validateEquipment = equipment => {
        const schema = {
            equipmentName: Joi.string()
                .max(255)
                .required(),
            code: Joi.string()
                .max(255)
                .required(),
            stock: Joi.number()
                .min(0)
                .required(),
            description: Joi.string()
                .max(1024)
                .allow(''),
            categoryId: Joi.string().required()
        };

        return Joi.validate(equipment, schema);
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        const data = { ...this.state };

        const equipmentName = data.equipmentName;

        delete data.buttonDisabled;
        delete data.categories;
        delete data.categoryName;
        delete data.equipments;
        delete data.loading;

        const dupList = this.state.equipments

        const { error } = this.validateEquipment(data)
        if (error) {
            toast.error(error.details[0].message)
        }
        else if (dupList.includes(equipmentName)) {
            toast.error("Name already in use")
        }
        else {
            this.setState({ buttonDisabled: true });
            try {
                await updateEquipment(data, this.props.equipmentId);
                toast.dark("Updated successfully")

                this.props.next()
            }
            catch (error) {
                toast.error(error);
            }
        }
    }

    render() {
        return (
            <div>
                <ToastContainer hideProgressBar position="bottom-right" />

                {this.state.loading
                    ?
                    <div>
                        <center>
                            <br />
                            <br />
                            <br />
                            <Spinner size="sm" animation="grow" variant="danger" />{' '}
                            <Spinner size="sm" animation="grow" variant="warning" />{' '}
                            <Spinner size="sm" animation="grow" variant="success" />
                        </center>
                    </div>
                    :
                    <div>
                        <Form
                            style={{ fontSize: '12px', padding: '0.25rem 0.5rem' }}
                            onSubmit={this.handleSubmit}
                            onKeyDown={this.handleKeyPress}
                        >
                            <Row>
                                <Col xs={7}>
                                    <div style={{ backgroundColor: '#f7faf8', padding: '20px', border: '0.5px solid red' }}>
                                        <center>
                                            {company.company}
                                            <h4>Update Equipment</h4>
                                            <p><br /></p>
                                        </center>
                                        <Row>
                                            <Col xs={6}>
                                                <Form.Label>Category Name</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="categoryName"
                                                    value={this.state.categoryName}
                                                    onChange={this.handleChange}
                                                    disabled
                                                />
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Label>Change Category</Form.Label>
                                                <Select
                                                    onChange={this.handleCategorySelect}
                                                    options={this.state.categories?.map(i => {
                                                        return ({
                                                            value: i._id,
                                                            label: i.categoryName,
                                                            categoryName: i.categoryName
                                                        })
                                                    })}
                                                />
                                            </Col>
                                        </Row>
                                        <br />

                                        <Form.Label>Equipment Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="equipmentName"
                                            value={this.state.equipmentName}
                                            onChange={this.handleChange}
                                        />
                                        <br />
                                        <Row>
                                            <Col xs={6}>
                                                <Form.Label>Code</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="code"
                                                    value={this.state.code}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                            <Col xs={6}>
                                                <Form.Label>Stock</Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    name="stock"
                                                    min={0}
                                                    value={this.state.stock}
                                                    onChange={this.handleChange}
                                                />
                                            </Col>
                                        </Row>
                                        <br />
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="description"
                                            value={this.state.description}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                </Col>
                            </Row>
                            <br />
                            <Row>
                                <Col xs={7}>
                                    <Button
                                        variant="dark"
                                        block
                                        type="submit"
                                        size='sm'
                                        disabled={this.state.buttonDisabled}
                                    >
                                        Update Equipment
                                    </Button>
                                    <Button
                                        variant="outline-dark"
                                        block
                                        size='sm'
                                        onClick={() => this.props.next()}
                                    >
                                        Cancel
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                }
            </div>
        )
    }
}

export default UpdateEquipment