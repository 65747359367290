import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import BookingForm from '../components/BookingForm'
import ExploreBookings from '../components/ExploreBookings'
import SearchBooking from '../components/SearchBooking'
import BookingFormTwo from '../components/BookingFormTwo'
import CreateCustomerTwo from '../components/CreateCustomerTwo'
import UpdateBooking from '../components/UpdateBooking'
import { ToastContainer } from 'react-toastify';


class Booking extends Component {
	state = {
		view: "bookingForm",
		bookingId: "",
		customerId: "",
		customerName: "",
		panNum: "",
		mobile: "",
	}

	explore = () => {
		this.setState({
			view: "explore"
		})
	}

	search = () => {
		this.setState({
			view: "search"
		})
	}

	update = (bookingId) => {
		this.setState({ bookingId }, () => {
			this.setState({ view: "update" })
		})
	}

	addCustomer = () => {
		this.setState({
			view: "addCustomer"
		})
	}

	addBooking = () => {
		this.setState({
			view: "bookingForm"
		})
	}

	initiateBooking = (id, customerName, panNum, mobile) => {
		this.setState({
			customerId: id,
			customerName,
			panNum,
			mobile
		}, () => {
			this.setState({ view: "bookingForm_2" })
		})
	}

	renderView = () => {
		if (this.state.view === "bookingForm") {
			return (
				<div>
					<BookingForm user={this.props.user}  next={this.explore}/>
				</div>
			)
		}
		else if (this.state.view === "bookingForm_2") {
			return (
				<div>
					<BookingFormTwo
						user={this.props.user}
						customerId={this.state.customerId}
						customerName={this.state.customerName}
						panNum={this.state.panNum}
						mobile={this.state.mobile}
						next={this.explore}
					/>
				</div>
			)
		}
		else if (this.state.view === "addCustomer") {
			return (
				<div>
					<CreateCustomerTwo user={this.props.user} initiateBooking={this.initiateBooking} />
				</div>
			)
		}
		else if (this.state.view === "search") {
			return (
				<div>
					<SearchBooking user={this.props.user} update={this.update} printBooking={this.props.printBooking} printAdvance={this.props.printAdvance} />
				</div>
			)
		}
		else if (this.state.view === "explore") {
			return (
				<div>
					<ExploreBookings user={this.props.user} update={this.update} printBooking={this.props.printBooking} printAdvance={this.props.printAdvance} />
				</div>
			)
		}

		else if (this.state.view === "update") {
			return (
				<div>
					<UpdateBooking user={this.props.user} bookingId={this.state.bookingId} next={this.explore} />
				</div>
			)
		}
	}

	render() {
		return (
			<div>
				<Container>
					<ToastContainer position="bottom-right" hideProgressBar />
					<Button size='sm' variant="dark" onClick={this.addCustomer}>
						New Customer
					</Button>{" "}

					<Button size='sm' variant="dark" onClick={this.addBooking}>
						Add Booking
					</Button>{" "}

					<Button size='sm' variant="dark" onClick={this.search}>
						Search
					</Button>{" "}

					<Button size='sm' variant="dark" onClick={this.explore}>
						History
					</Button>{" "}

					<hr />
					<br />

					{this.renderView()}
				</Container>
			</div>
		)
	}
}

export default Booking