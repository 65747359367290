import React, { Component } from 'react'
import { downloadCSV } from '../services/salaryService'
import { getCurrentDate, getDateThreeMonthsAgo } from '../utils/utils';
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { AiOutlineDownload } from "react-icons/ai";
import moment from 'moment'
import { CSVLink } from "react-csv";
const company = require('./../company');


class SalaryCSV extends Component {
    _isMounted = false;

    state = {
        date1: "",
        date2: "",

        csvData: [],
        headers: [],

        view: false,
        buttonDisabled: false,
        selectDisabled: false,

        update: false,
        loading: true
    }

    async componentDidMount() {
        this._isMounted = true;

        this._isMounted && this.setState({
            date1: getDateThreeMonthsAgo(),
            date2: getCurrentDate()
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChange = event => {
        const { name, value } = event.target
        this.setState({
            [name]: value
        })
    }

    populateCSV = async (d1, d2) => {
        const { data } = await downloadCSV(d1, d2);

        const headersData = [
            { label: "Date", key: "date" },
            { label: "Employee Name", key: "employeeName" },
            { label: "Job Title", key: "jobTitle" },
            { label: "Phone", key: "phone" },
            { label: "PAN", key: "pan" },

            { label: "Base Salary", key: "baseSalary" },
            { label: "Bonus", key: "bonus" },
            { label: "Deductions", key: "deductions" },
            { label: "NetPayment", key: "netPayment" },
            { label: "Payment Method", key: "paymentMethod" },
            { label: "Bank", key: "bank" },
            { label: "Account Number", key: "accountNumber" },
            { label: "Cheque Number", key: "chequeNumber" },
            { label: "Remarks", key: "remarks" },

            { label: "Date Modified", key: "dateModified" },
            { label: "Submitted By", key: "submittedBy" }
        ]

        const value = [];
        data.forEach(i => {
            value.push(
                {
                    date: moment(i.salaryData.date).format('MMMM DD, YYYY'),
                    employeeName: i.employee[0].employeeName,
                    jobTitle: i.employee[0].jobTitle,
                    phone: i.employee[0].phone,
                    pan: i.employee[0].pan,

                    baseSalary: i.salaryData.baseSalary,
                    bonus: i.salaryData.bonus,
                    deductions: i.salaryData.deductions,
                    netPayment: i.salaryData.netPayment,
                    paymentMethod: i.salaryData.paymentMethod,
                    bank: i.salaryData.bank,
                    accountNumber: i.salaryData.accountNumber,
                    chequeNumber: i.salaryData.chequeNumber,
                    remarks: i.salaryData.remarks,

                    dateModified: moment(i.salaryData.dateModified).format('MMMM DD, YYYY'),
                    submittedBy: i.salaryData.submittedBy,
                }
            )
        });

        this.setState({ csvData: value, headers: headersData })
        this._isMounted && this.setState({ loading: false })
    }

    handleSubmit = async event => {
        event.preventDefault();

        const oneYearInMilliseconds = 1000 * 60 * 60 * 24 * 100;
        const dateDifference = Math.abs(new Date(this.state.date2) - new Date(this.state.date1));

        if (this.state.date1 > this.state.date2) {
            toast.info('Start Date should be less than End Date')
        }
        else if (dateDifference > oneYearInMilliseconds) {
            toast.info('The gap between dates must be less than 100 days');
        }
        else {
            this.setState({
                view: true,
                buttonDisabled: true,
                selectDisabled: true
            })

            await this.populateCSV(this.state.date1, this.state.date2)
        }
    }

    handleReset = () => {
        this.setState({
            csvData: [],
            headers: [],
            view: false,
            buttonDisabled: false,
            selectDisabled: false,
            loading: true
        })
    }

    render() {
        return (
            <div>
                <ToastContainer hideProgressBar position="bottom-right" />

                <Form
                    style={{ fontSize: '12px', padding: '0.25rem 0.5rem' }}
                    onSubmit={this.handleSubmit}
                >

                    <Form.Row>
                        <Form.Group as={Col} controlId="">
                            <Form.Label>Start Date</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} controlId="">
                            <Form.Control
                                style={{ height: '30px' }}
                                type="date"
                                name="date1"
                                disabled={this.state.selectDisabled}
                                value={this.state.date1}
                                onChange={this.handleChange}
                            />
                        </Form.Group>
                        <Col></Col>
                        <Form.Group as={Col} controlId="">
                            <Form.Label>End Date</Form.Label>
                        </Form.Group>
                        <Form.Group as={Col} controlId="">
                            <Form.Control
                                style={{ height: '30px' }}
                                type="date"
                                name="date2"
                                disabled={this.state.selectDisabled}
                                value={this.state.date2}
                                onChange={this.handleChange}
                            />
                        </Form.Group>
                        <Col></Col>
                        <Col>
                            <Button
                                variant="dark"
                                type="submit"
                                size='sm'
                                disabled={this.state.buttonDisabled}
                            >
                                Generate
                            </Button>
                            {' '}
                            <Button
                                variant="danger"
                                size='sm'
                                onClick={this.handleReset}
                            >
                                Reset
                            </Button>
                        </Col>
                    </Form.Row>


                </Form>

                {this.state.view
                    ?
                    <div>
                        {this.state.loading
                            ?
                            <div>
                                <center>
                                    <br />
                                    <br />
                                    <br />
                                    <Spinner size="sm" animation="grow" variant="danger" />{' '}
                                    <Spinner size="sm" animation="grow" variant="warning" />{' '}
                                    <Spinner size="sm" animation="grow" variant="success" />
                                </center>
                            </div>
                            :
                            <div>
                                <center>
                                    <br /><br /><br />
                                    <b>{company.company}</b>
                                    <h2>Salary</h2>
                                    <p>From <b>{this.state.date1}</b> to <b>{this.state.date2}</b></p>
                                    <br /><br />

                                    <CSVLink
                                        filename={"Salary_" + this.state.date1 + "_to_" + this.state.date2 + ".csv"}
                                        data={this.state.csvData}
                                        headers={this.state.headers}
                                    >
                                        <span
                                            style={{
                                                cursor: 'pointer',
                                                color: 'green',
                                            }}
                                            onClick={() => { }}
                                        >
                                            <AiOutlineDownload size={100} />
                                            <br />
                                            Download CSV
                                        </span>
                                    </CSVLink>

                                </center>
                                <br />
                            </div>
                        }
                    </div>
                    :
                    <div><center><p><br />Choose a timeframe to download Salary CSV.</p></center></div>

                }
            </div>
        )
    }
}

export default SalaryCSV
