import React, { Component } from 'react'
import Container from 'react-bootstrap/Container'


class PageNotFound extends Component {
	render() {
		return (
			<div>
				<Container>
					<br />
					<br />
					<br />
					<center>
						<h3>Page Not Found</h3>
					</center>
				</Container>			
			</div>
		)
	}
}

export default PageNotFound