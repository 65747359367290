import React, { Component } from 'react';
import { getDetailedDelivery, updateDelivery } from '../services/deliveryService';
import { getEmployees } from '../services/employeeService';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'
import moment from 'moment'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Joi from "joi-browser";
const company = require('./../company');


class UpdateDelivery extends Component {
    _isMounted = false;

    state = {
        deliveries: [],
        employees: [],
        technicians: [
            {
                employeeId: "",
                employeeName: "",
                phone: ""
            }
        ],
        submittedBy: "",
        userId: "",

        buttonDisabled: false,
        loading: true
    }

    populateDelivery = async () => {
        const { data: deliveries } = await getDetailedDelivery(this.props.deliveryId);
        this._isMounted && this.setState({ deliveries })
    }

    populateEmployees = async () => {
        const { data: employees } = await getEmployees();
        this._isMounted && this.setState({ employees })
    }

    async componentDidMount() {
        this._isMounted = true;
        this._isMounted && await this.populateDelivery();
        this._isMounted && await this.populateEmployees();
        this._isMounted && this.setState({
            submittedBy: this.props.user.name,
            userId: this.props.user._id,
            loading: false
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChange = event => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            deliveries: {
                ...prevState.deliveries,
                delivery: {
                    ...prevState.deliveries.delivery,
                    [name]: value
                }
            }
        }));
    };

    handleOutChange = (index, event) => {
        const { name, value } = event.target;
        const intValue = parseInt(value, 10);
        const updatedItems = this.state.deliveries.delivery.items.map((item, idx) =>
            idx === index ? { ...item, [name]: intValue } : item
        );
        this.setState(prevState => ({
            deliveries: {
                ...prevState.deliveries,
                delivery: {
                    ...prevState.deliveries.delivery,
                    items: updatedItems
                }
            }
        }));
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    validateDelivery = (data) => {
        const schema = {
            deliveryDate: Joi.date().required(),
            bookingId: Joi.string().required(),
            customerId: Joi.string().required(),
            items: Joi.array().items(Joi.object({
                particulars: Joi.string().required(),
                equipmentId: Joi.string().required(),
                days: Joi.number().required(),
                qty: Joi.number().required(),
                codeNo: Joi.string().required(),
                out: Joi.number().required(),
                in: Joi.number().allow(''),
                remarks: Joi.string().allow('')
            })),
            technicians: Joi.array().items(Joi.object({
                employeeId: Joi.string().required(),
                employeeName: Joi.string().required()
            })),
            comments: Joi.string().max(1024).allow(''),
            userId: Joi.string().required(),
            submittedBy: Joi.string().required()
        };

        return Joi.validate(data, schema);
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        let technician_table = this.state.deliveries.technicians
        technician_table = technician_table.map(technician => {
            return {
                employeeId: technician._id,
                employeeName: technician.employeeName
            };
        });

        let technician_form = this.state.technicians
        technician_form = technician_form.filter(technician => technician.employeeId !== null && technician.employeeId !== '');
        technician_form = technician_form.map(technician => {
            const { phone, ...rest } = technician;
            return rest;
        });

        const combinedTechnicians = [...technician_table, ...technician_form];

        const shouldUpdate = window.confirm('-----------------------------------------------------\n     Do you really want to save changes ?\n-----------------------------------------------------');
        if (shouldUpdate) {
            let data = { ...this.state.deliveries.delivery };

            delete data._id;
            data.items = data.items.map(item => {
                const { _id, ...itemWithoutId } = item;
                return itemWithoutId;
            });
            delete data.dateModified;
            data.submittedBy = this.props.user.name;
            data.userId = this.props.user._id;

            // Replace data.technicians with combinedTechnicians
            data.technicians = combinedTechnicians;

            const { error } = this.validateDelivery(data)
            if (error) {
                toast.error(error.details[0].message)
            }
            else {
                this.setState({ buttonDisabled: true });
                try {
                    await updateDelivery(data, this.props.deliveryId);
                    toast.dark("Updated successfully")

                    this.props.next()
                }
                catch (error) {
                    toast.error(error);
                }
            }
        }
    }

    handleTechnicianSelect = (index, e) => {
        const values = [...this.state.technicians];
        values[index].employeeId = e.employeeId;
        values[index].employeeName = e.employeeName;
        values[index].phone = e.phone;

        this.setState({ technicians: values })
    }

    handleRecordAdd = () => {
        this.setState((prevState) => ({
            technicians: [...prevState.technicians, {
                employeeId: "",
                employeeName: "",
                phone: "",
            }]
        }))
    }

    handleRecordRemove = (index) => {
        if (this.state.technicians.length > 1) {
            const values = [...this.state.technicians];
            values.splice(index, 1);

            this.setState({ technicians: values });
        }
    }

    removeTechnician(id) {
        // Get the current state
        const { deliveries } = this.state;

        // Filter out the technician with the matching _id
        const updatedTechnicians = deliveries.technicians.filter(
            (technician) => technician._id !== id
        );

        // Update the state with the modified technicians array
        this.setState({
            deliveries: {
                ...deliveries,
                technicians: updatedTechnicians,
            },
        });
    }

    render() {
        const labelStyle = {
            display: 'inline-block',
            width: '120px',
            marginRight: '10px',
            textAlign: 'left'
        };

        const valueStyle = {
            display: 'inline-block',
        };

        return (
            <div>
                <ToastContainer hideProgressBar position="bottom-right" />

                {this.state.loading
                    ?
                    <div>
                        <center>
                            <br />
                            <br />
                            <br />
                            <Spinner size="sm" animation="grow" variant="danger" />{' '}
                            <Spinner size="sm" animation="grow" variant="warning" />{' '}
                            <Spinner size="sm" animation="grow" variant="success" />
                        </center>
                    </div>
                    :
                    <div>
                        <Form
                            style={{ fontSize: '12px', padding: '0.25rem 0.5rem' }}
                            onSubmit={this.handleSubmit}
                            onKeyDown={this.handleKeyPress}
                        >
                            <div style={{ backgroundColor: '#FAFAFA', padding: '20px', border: '0.5px solid red' }}>
                                <center>
                                    {company.company}
                                    <h4>Update Delivery Details</h4>
                                    <p><br /></p>
                                </center>
                                <div style={{ fontSize: '15px' }}>
                                    <Row>
                                        <Col>
                                            <div>
                                                <span style={labelStyle}><strong>Bill Number:</strong></span>
                                                <span style={valueStyle}>{this.state.deliveries.booking.billNum}</span>
                                            </div>
                                            <div>
                                                <span style={labelStyle}><strong>Program:</strong></span>
                                                <span style={valueStyle}>{this.state.deliveries.booking.program}</span>
                                            </div>
                                            <div>
                                                <span style={labelStyle}><strong>Venue:</strong></span>
                                                <span style={valueStyle}>{this.state.deliveries.booking.venue}</span>
                                            </div>
                                            <div>
                                                <span style={labelStyle}><strong>Event Date:</strong></span>
                                                <span style={valueStyle}>{moment(this.state.deliveries.booking.eventDate).format('MMMM DD, YYYY')}</span>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div>
                                                <span style={labelStyle}><strong>Customer Name:</strong></span>
                                                <span style={valueStyle}>{this.state.deliveries.customer.customerName}</span>
                                            </div>
                                            <div>
                                                <span style={labelStyle}><strong>PAN:</strong></span>
                                                <span style={valueStyle}>{this.state.deliveries.customer.panNum}</span>
                                            </div>
                                            <div>
                                                <span style={labelStyle}><strong>Mobile:</strong></span>
                                                <span style={valueStyle}>{this.state.deliveries.customer.mobile}</span>
                                            </div>
                                            <div>
                                                <span style={labelStyle}><strong>Address:</strong></span>
                                                <span style={valueStyle}>{this.state.deliveries.customer.address}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                <br />
                                <br />

                                <Form.Row>
                                    <Form.Group as={Col} controlId="">
                                        <Form.Label>Delivery Date</Form.Label>
                                        <Form.Control
                                            style={{ width: '200px', height: '30px' }}
                                            type="date"
                                            name="deliveryDate"
                                            value={moment(this.state.deliveries.delivery.deliveryDate).format("YYYY-MM-DD")}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                    <Col></Col><Col></Col>
                                </Form.Row>

                                <br />
                                <br />

                                <Table size='sm' striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '60%' }}>Particulars</th>
                                            <th style={{ width: '10%', textAlign: 'center' }}>Code No</th>
                                            <th style={{ width: '15%', textAlign: 'center' }}>Qty</th>
                                            <th style={{ width: '15%', textAlign: 'center' }}>Out</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.deliveries.delivery.items?.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.particulars}</td>
                                                <td style={{ textAlign: 'center' }}>{item.codeNo}</td>
                                                <td style={{ backgroundColor: item.qty !== item.out ? '#FFC4C4' : '', textAlign: 'center' }}>
                                                    <Form.Control
                                                        style={{ width: '100%', height: '30px', fontSize: '12px' }}
                                                        type="number"
                                                        name="qty"
                                                        max={item.qty}
                                                        value={item.qty}
                                                        readOnly
                                                    />
                                                </td>
                                                <td style={{ textAlign: 'center' }}>
                                                    <Form.Control
                                                        style={{ width: '100%', height: '30px', fontSize: '12px' }}
                                                        type="number"
                                                        name="out"
                                                        min={0}
                                                        max={item.qty}
                                                        value={item.out}
                                                        onChange={(event) => this.handleOutChange(index, event)}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <br />
                                <br />

                                <Row>
                                    <Col>
                                        {(this.state.deliveries.technicians.length > 0)
                                            ?
                                            <Form.Row>
                                                <Col>
                                                    <div style={{ paddingRight: '100px' }}>
                                                        <Table size='sm' bordered>
                                                            <thead>
                                                                <tr>
                                                                    <th>Name</th>
                                                                    <th>Contact</th>
                                                                    <th style={{ textAlign: 'center', width: '10%' }}>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>{this.state.deliveries.technicians?.map((technician, index) => (
                                                                <tr key={index}>
                                                                    <td>{technician.employeeName}</td>
                                                                    <td>{technician.phone}</td>
                                                                    <td style={{ textAlign: 'center' }}>
                                                                        <Button
                                                                            variant='outline-danger'
                                                                            style={{ padding: '0.25rem 0.5rem', fontSize: '10px', marginRight: '5px' }}
                                                                            onClick={() => this.removeTechnician(technician._id)}
                                                                        >
                                                                            Remove
                                                                        </Button>
                                                                    </td>
                                                                </tr>
                                                            ))}</tbody>
                                                        </Table>
                                                    </div>
                                                </Col>
                                            </Form.Row>
                                            :
                                            <div></div>}

                                        <br />
                                        <h6>Add Technician</h6>
                                        <Form.Row>
                                            <Col>
                                                <Form.Label>Technician</Form.Label>
                                            </Col>

                                            <Form.Group as={Col} controlId="">
                                                <Form.Label>Contact Number</Form.Label>
                                            </Form.Group>

                                            <Form.Group as={Col} controlId="">
                                                <Form.Label></Form.Label>
                                            </Form.Group>
                                        </Form.Row>

                                        {this.state.technicians.map((i, index) => {
                                            return (
                                                <Form.Row key={"T" + i + index}>
                                                    <Col>
                                                        <Select
                                                            onChange={e => this.handleTechnicianSelect(index, e)}
                                                            options={this.state.employees?.map(i => {
                                                                return ({
                                                                    value: i.employeeName,
                                                                    label: i.employeeName,
                                                                    employeeName: i.employeeName,
                                                                    phone: i.phone,
                                                                    employeeId: i._id
                                                                })
                                                            })}
                                                        />
                                                    </Col>

                                                    <Form.Group as={Col} controlId="">
                                                        <Form.Control
                                                            type="text"
                                                            name="phone"
                                                            value={i.phone}
                                                            onChange={e => this.handleRecordChange(index, e)}
                                                            readOnly
                                                        />
                                                    </Form.Group>

                                                    <Form.Group as={Col}>
                                                        <Button variant="light" size="sm" onClick={this.handleRecordAdd}>
                                                            +
                                                        </Button>{' '}

                                                        <Button variant="light" size="sm" onClick={() => this.handleRecordRemove(index)}>
                                                            -
                                                        </Button>
                                                    </Form.Group>

                                                </Form.Row>
                                            )
                                        })}
                                    </Col>
                                    <Col>
                                        <Form.Group as={Col} controlId="">
                                            <Form.Label>Comments</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={3}
                                                name="comments"
                                                value={this.state.deliveries.delivery.comments}
                                                onChange={this.handleChange}
                                            />
                                        </Form.Group>
                                    </Col></Row>

                            </div>

                            <div style={{ textAlign: 'right', fontSize: '12px' }}>
                                User: <b>{this.state.submittedBy}</b>
                            </div>

                            <br />
                            <Button
                                variant="dark"
                                size="sm"
                                type="submit"
                                disabled={this.state.buttonDisabled}
                            >
                                Save Changes
                            </Button>{" "}
                            <Button
                                variant="outline-dark"
                                size='sm'
                                onClick={() => this.props.next()}
                            >
                                Cancel
                            </Button>
                        </Form>
                    </div>
                }
            </div>
        )
    }
}

export default UpdateDelivery