import React, { Component } from 'react';
import { getDetailedSalary, updateSalary } from '../services/salaryService';
import { getEmployees } from '../services/employeeService';
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import moment from 'moment'
import Select from 'react-select'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Joi from "joi-browser";
const company = require('./../company');


class UpdateSalary extends Component {
    _isMounted = false;

    state = {
        salary: [],
        employees: [],
        submittedBy: "",
        userId: "",

        buttonDisabled: false,
        loading: true
    }

    populateSalary = async () => {
        const { data: salary } = await getDetailedSalary(this.props.salaryId);
        this._isMounted && this.setState({ salary })
    }

    populateEmployees = async () => {
        const { data: employees } = await getEmployees();
        this._isMounted && this.setState({ employees })
    }

    async componentDidMount() {
        this._isMounted = true;
        this._isMounted && await this.populateSalary();
        this._isMounted && await this.populateEmployees();
        this._isMounted && this.setState({
            submittedBy: this.props.user.name,
            userId: this.props.user._id,
            loading: false
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleChange = event => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            salary: {
                ...prevState.salary,
                salaryData: {
                    ...prevState.salary.salaryData,
                    [name]: value
                }
            }
        }), () => {
            this.calculate();
        });
    };

    calculate = () => {
        const { salary } = this.state;
        const newNetPayment = salary.salaryData.baseSalary * 1 + salary.salaryData.bonus * 1 - salary.salaryData.deductions * 1;
        const updatedSalaryData = { ...salary.salaryData, netPayment: newNetPayment };
        const updatedSalary = { ...salary, salaryData: updatedSalaryData };
        this.setState({ salary: updatedSalary });
    }

    handleEmployeeSelect = (e) => {
        this.setState((prevState) => ({
            salary: {
                ...prevState.salary,
                salaryData: {
                    ...prevState.salary.salaryData,
                    employeeId: e.value,
                },
                employee: {
                    ...prevState.salary.employee,
                    employeeId: e.value,
                    employeeName: e.label,
                    panNum: e.pan,
                    phone: e.phone,
                },
            },
        }));
    };

    handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
        }
    }

    validateSalary = (salary) => {
        const schema = {
            date: Joi.date().required(),
            employeeId: Joi.string().required(),
            paymentMethod: Joi.string().required(),
            bank: Joi.string().allow(''),
            accountNumber: Joi.string().allow(''),
            chequeNumber: Joi.string().allow(''),
            baseSalary: Joi.number().min(0).required(),
            bonus: Joi.number().min(0).allow(''),
            deductions: Joi.number().min(0).allow(''),
            netPayment: Joi.number().allow(''),
            remarks: Joi.string().max(1024).allow(''),
            userId: Joi.string().required(),
            submittedBy: Joi.string().required(),
        };

        return Joi.validate(salary, schema);
    }

    handleSubmit = async (event) => {
        event.preventDefault();

        const shouldUpdate = window.confirm('-----------------------------------------------------\n     Do you really want to save changes ?\n-----------------------------------------------------');
        if (shouldUpdate) {
            let data = { ...this.state.salary.salaryData };

            delete data._id;
            delete data.dateModified;
            data.submittedBy = this.props.user.name;
            data.userId = this.props.user._id;

            if (data.paymentMethod === "Cash") {
                data.bank = "";
                data.accountNumber = "";
                data.chequeNumber = "";
            }

            const { error } = this.validateSalary(data)
            if (error) {
                toast.error(error.details[0].message)
            }
            else {
                this.setState({ buttonDisabled: true });
                try {
                    await updateSalary(data, this.props.salaryId);
                    toast.dark("Updated successfully")

                    this.props.next()
                }
                catch (error) {
                    toast.error(error);
                }
            }
        }
    }

    render() {
        const labelStyle = {
            display: 'inline-block',
            width: '160px',
            marginRight: '10px',
            textAlign: 'left'
        };

        const valueStyle = {
            display: 'inline-block'
        };

        return (
            <div>
                <ToastContainer hideProgressBar position="bottom-right" />

                {this.state.loading
                    ?
                    <div>
                        <center>
                            <br />
                            <br />
                            <br />
                            <Spinner size="sm" animation="grow" variant="danger" />{' '}
                            <Spinner size="sm" animation="grow" variant="warning" />{' '}
                            <Spinner size="sm" animation="grow" variant="success" />
                        </center>
                    </div>
                    :
                    <div>
                        <Form
                            style={{ fontSize: '12px', padding: '0.25rem 0.5rem' }}
                            onSubmit={this.handleSubmit}
                            onKeyDown={this.handleKeyPress}
                        >
                            <div style={{ backgroundColor: '#FAFAFA', padding: '20px', border: '0.5px solid red' }}>
                                <center>
                                    {company.company}
                                    <h4>Update Salary Details</h4>
                                    <p><br /></p>
                                </center>
                                <div style={{ fontSize: '15px' }}>
                                    <Row>
                                        <Col>
                                            <div>
                                                <span style={labelStyle}><strong>Date:</strong></span>
                                                <span style={valueStyle}>
                                                    <Form.Control
                                                        style={{ width: '200px', height: '20px' }}
                                                        type="date"
                                                        name="date"
                                                        value={moment(this.state.salary.salaryData.date).format("YYYY-MM-DD")}
                                                        onChange={this.handleChange}
                                                    />
                                                </span>
                                            </div>
                                            <br />
                                            <div>
                                                <span style={labelStyle}><strong>Base Salary:</strong></span>
                                                <span style={valueStyle}>
                                                    <Form.Control
                                                        style={{ width: '200px', height: '20px', border: '1px solid green' }}
                                                        type="number"
                                                        step="1"
                                                        min={0}
                                                        name="baseSalary"
                                                        value={this.state.salary.salaryData.baseSalary}
                                                        onChange={this.handleChange}
                                                    />
                                                </span>
                                            </div>
                                            <div>
                                                <span style={labelStyle}><strong>Bonus:</strong></span>
                                                <span style={valueStyle}>
                                                    <Form.Control
                                                        style={{ width: '200px', height: '20px', border: '1px solid green' }}
                                                        type="number"
                                                        step="1"
                                                        min={0}
                                                        name="bonus"
                                                        value={this.state.salary.salaryData.bonus}
                                                        onChange={this.handleChange}
                                                    />
                                                </span>
                                            </div>
                                            <div>
                                                <span style={labelStyle}><strong>Deductions:</strong></span>
                                                <span style={valueStyle}>
                                                    <Form.Control
                                                        style={{ width: '200px', height: '20px', border: '1px solid red' }}
                                                        type="number"
                                                        step="1"
                                                        max={this.state.salary.salaryData.baseSalary}
                                                        name="deductions"
                                                        value={this.state.salary.salaryData.deductions}
                                                        onChange={this.handleChange}
                                                    />
                                                </span>
                                            </div>
                                            <div>
                                                <span style={labelStyle}><strong>Net Payment:</strong></span>
                                                <span style={valueStyle}>
                                                    <Form.Control
                                                        style={{ width: '200px', height: '20px' }}
                                                        type="number"
                                                        name="netPayment"
                                                        value={this.state.salary.salaryData.netPayment}
                                                        onChange={this.handleChange}
                                                        readOnly
                                                    />
                                                </span>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div>
                                                <span style={labelStyle}><strong>Choose Employee:</strong></span>
                                                <span style={valueStyle}>
                                                    <div style={{ fontSize: '12px', width: '200px' }}>
                                                        <Select
                                                            onChange={this.handleEmployeeSelect}
                                                            options={this.state.employees?.map(i => ({
                                                                value: i._id,
                                                                label: i.employeeName,
                                                                pan: i.pan,
                                                                phone: i.phone
                                                            }))}
                                                            menuPortalTarget={document.body}
                                                            styles={{
                                                                menuPortal: base => ({ ...base, zIndex: 9999 }),
                                                                control: (provided) => ({ ...provided, minHeight: 30, height: 30, maxHeight: 30, padding: '0px' }),
                                                                valueContainer: (provided) => ({ ...provided, padding: '0px 8px' }),
                                                                option: (provided, state) => ({
                                                                    ...provided,
                                                                    fontSize: '12px'
                                                                }),
                                                                singleValue: (provided, state) => ({
                                                                    ...provided,
                                                                    fontSize: '12px',
                                                                    transform: 'none',
                                                                    margin: '0px'
                                                                }),
                                                            }}
                                                        />
                                                    </div>
                                                </span>
                                            </div>

                                            <br />

                                            <div>
                                                <span style={labelStyle}><strong>Employee Name:</strong></span>
                                                <span style={valueStyle}>{this.state.salary.employee.employeeName}</span>
                                            </div>
                                            <div>
                                                <span style={labelStyle}><strong>Contact Number:</strong></span>
                                                <span style={valueStyle}>{this.state.salary.employee.phone}</span>
                                            </div>
                                            <div>
                                                <span style={labelStyle}><strong>PAN:</strong></span>
                                                <span style={valueStyle}>{this.state.salary.employee.panNum}</span>
                                            </div>
                                        </Col>
                                    </Row>

                                    <br /><br />

                                    <Row>
                                        <Col xs={2}><Form.Label>Payment Method</Form.Label></Col>
                                        <Col>
                                            <Form.Check
                                                id="paymentMethodCheque"
                                                inline
                                                type="radio"
                                                label="Cheque"
                                                value="Cheque"
                                                name="paymentMethod"
                                                onChange={this.handleChange}
                                                checked={this.state.salary.salaryData.paymentMethod === "Cheque"}
                                            />

                                            <Form.Check
                                                id="paymentMethodCash"
                                                inline
                                                type="radio"
                                                label="Cash"
                                                value="Cash"
                                                name="paymentMethod"
                                                onChange={this.handleChange}
                                                checked={this.state.salary.salaryData.paymentMethod === "Cash"}
                                            />
                                        </Col>
                                    </Row>

                                    <br />

                                    {this.state.salary.salaryData.paymentMethod === "Cheque"
                                        ?
                                        <div>
                                            <Form.Row>
                                                <Form.Group as={Col} controlId="">
                                                    <Form.Label>Bank Name</Form.Label>
                                                    <Form.Control
                                                        style={{ height: '30px', width: '370px' }}
                                                        type="text"
                                                        name="bank"
                                                        value={this.state.salary.salaryData.bank}
                                                        onChange={this.handleChange}
                                                    />
                                                </Form.Group>

                                                <Form.Group as={Col} controlId="">
                                                    <Form.Label>Account Number</Form.Label>
                                                    <Form.Control
                                                        style={{ height: '30px', width: '370px' }}
                                                        type="text"
                                                        name="accountNumber"
                                                        value={this.state.salary.salaryData.accountNumber}
                                                        onChange={this.handleChange}
                                                    />
                                                </Form.Group>
                                            </Form.Row>

                                            <Form.Group controlId="">
                                                <Form.Label>Cheque Number</Form.Label>
                                                <Form.Control
                                                    style={{ height: '30px', width: '370px' }}
                                                    type="text"
                                                    name="chequeNumber"
                                                    value={this.state.salary.salaryData.chequeNumber}
                                                    onChange={this.handleChange}
                                                />
                                            </Form.Group>

                                        </div>
                                        :
                                        <div></div>
                                    }

                                    <Form.Group controlId="">
                                        <Form.Label>Remarks</Form.Label>
                                        <Form.Control
                                            style={{ height: '30px', width: '370px' }}
                                            type="text"
                                            name="remarks"
                                            value={this.state.salary.salaryData.remarks}
                                            onChange={this.handleChange}
                                        />
                                    </Form.Group>
                                </div>
                            </div>

                            <div style={{ textAlign: 'right', fontSize: '12px' }}>
                                User: <b>{this.state.submittedBy}</b>
                            </div>

                            <br />
                            <Button
                                variant="dark"
                                size="sm"
                                type="submit"
                                disabled={this.state.buttonDisabled}
                            >
                                Save Changes
                            </Button>{" "}
                            <Button
                                variant="outline-dark"
                                size='sm'
                                onClick={() => this.props.next()}
                            >
                                Cancel
                            </Button>
                        </Form>
                    </div>
                }
            </div>
        )
    }
}

export default UpdateSalary