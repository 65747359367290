import React, { Component } from 'react'

import SuperUser from '../components/SuperUser'

class SuperAdmin extends Component {
	
	
	render() {
		return (
			<div>
				<SuperUser />
			</div>
		)
	}
}

export default SuperAdmin