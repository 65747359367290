import React, { Component } from 'react'
import { getDeliveriesWithCustomer, deleteDelivery } from '../services/deliveryService';

import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Table from 'react-bootstrap/Table'
import Spinner from 'react-bootstrap/Spinner'
import moment from 'moment'

import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'


class ExploreDelivery extends Component {
    _isMounted = false;

    state = {
        deliveries: [],
        currentPage: 1,

        loading: true,
        viewStyle: 'table'
    }

    populateDelivery = async () => {
        const { data: deliveries } = await getDeliveriesWithCustomer(this.state.currentPage);
        this._isMounted && this.setState({ deliveries })
    }

    async componentDidMount() {
        this._isMounted = true;
        this._isMounted && await this.populateDelivery()
        this._isMounted && this.setState({ loading: false })
    }

    nextPage = async () => {
        const { currentPage } = this.state;
        this.setState({ loading: true });

        const nextPage = currentPage + 1;

        const { data: deliveries } = await getDeliveriesWithCustomer(nextPage);

        this.setState({
            deliveries,
            currentPage: nextPage,
            loading: false,
        });
    };

    prevPage = async () => {
        const { currentPage } = this.state;
        if (currentPage === 1) return; // Prevent going to negative page number

        this.setState({ loading: true });

        const prevPage = currentPage - 1;

        const { data: deliveries } = await getDeliveriesWithCustomer(prevPage);

        this.setState({
            deliveries,
            currentPage: prevPage,
            loading: false,
        });
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    handlePageChange = pageNumber => {
        this.setState({ currentPage: pageNumber });
    };

    getButtonVariant = (booking) => {
        if (booking.isPaid) return 'outline-success';
        if (booking.isReturned) return 'outline-info';
        if (booking.isDelivered) return 'outline-warning';
        return 'outline-danger';
    };

    getStatusColor = (booking) => {
        if (booking.isPaid) return '#C4FFC4';
        if (booking.isReturned) return '#5bc0de';
        if (booking.isDelivered) return '#ffe57e';
        return '#FFC4C4';
    };

    toggleView = () => {
        this.setState((prevState) => ({
            viewStyle: prevState.viewStyle === 'table' ? 'block' : 'table',
        }));
    };

    deleteDelivery = async (delivery) => {
        if (delivery.booking.isReturned) {
            toast.info('Delivery has already RETURNED');
            return;
        } else {
            const shouldDelete = window.confirm('-----------------------------------------------------\n     Do you really want to delete this Delivery ?\n-----------------------------------------------------');
            if (shouldDelete) {
                const { deliveries } = this.state;
                const updatedDeliveries = deliveries.filter((b) => b._id !== delivery._id);

                this.setState({ deliveries: updatedDeliveries });

                try {
                    await deleteDelivery(delivery._id);
                    toast.dark('Deleted successfully');
                } catch (ex) {
                    this.setState({ deliveries });
                    toast.error('Failed to delete the delivery. Please try again.');
                }
            }
        }
    };

    renderBlock() {
        const { deliveries } = this.state;
        return (
            <div>
                {deliveries.map((delivery, index) => (
                    <div key={delivery._id}>
                        <Alert
                            key={delivery.id}
                            style={{
                                backgroundColor: '',
                                borderColor: '#e0dcdc',
                                color: '#000',
                                marginBottom: '10px',
                            }}
                        >
                            <Button
                                disabled
                                variant={this.getButtonVariant(delivery.booking)}
                                className="float-right"
                            >
                                STATUS: {delivery.booking.isPaid
                                    ? 'Paid'
                                    : delivery.booking.isReturned
                                        ? 'Returned'
                                        : delivery.booking.isDelivered
                                            ? 'Delivered'
                                            : 'Booked'}
                            </Button>

                            <h4>{delivery.booking.program}</h4>
                            <br />

                            <Row>
                                <Col>
                                    <strong>Bill Number:</strong> {delivery.booking.billNum}<br />
                                    <strong>Event Date:</strong> {moment(delivery.booking.eventDate).format('MMMM DD, YYYY')}<br />
                                    <strong>Delivery Date:</strong> {moment(delivery.deliveryDate).format('MMMM DD, YYYY')}<br />
                                    <strong>Venue:</strong> {delivery.booking.venue}<br /><br />
                                </Col>
                                <Col>
                                    <strong>Customer Name:</strong> {delivery.customer.customerName}<br />
                                    <strong>PAN:</strong> {delivery.customer.panNum}<br />
                                    <strong>Mobile:</strong> {delivery.customer.mobile}<br />
                                    <strong>Address:</strong> {delivery.customer.address}<br />
                                </Col>
                            </Row>
                            <br />
                            <div className="float-right" style={{ fontSize: '12px' }}>
                                {/* <strong>Date:</strong> {moment(delivery.dateModified).format('MMMM DD, YYYY')}<br /> */}
                                <strong>Submitted By:</strong> {delivery.submittedBy}<br />
                            </div>
                            <br />
                            <Button
                                size='sm'
                                variant='outline-dark'
                                style={{ marginRight: 5 }}
                                onClick={() => this.props.printDelivery(delivery)}
                            >
                                Print
                            </Button>
                            <Button
                                size='sm'
                                variant='outline-warning'
                                style={{ marginRight: 5 }}
                                onClick={() => {
                                    if (this.props.user.isAdmin || this.props.user.isSuperAdmin) {
                                        if (delivery.booking.isReturned) {
                                            toast.info('Delivery has already RETURNED');
                                        }
                                        else { this.props.update(delivery._id) }
                                    }
                                    else {
                                        toast.error('Access Denied.')
                                    }
                                }}
                            >
                                Edit
                            </Button>

                            <Button
                                size='sm'
                                variant='outline-danger'
                                onClick={() => {
                                    if (this.props.user.isAdmin || this.props.user.isSuperAdmin) {
                                        this.deleteDelivery(delivery)
                                    }
                                    else {
                                        toast.error('Access Denied.')
                                    }
                                }}
                            >
                                Delete
                            </Button>
                        </Alert>
                        <br />
                    </div>
                ))}
            </div>
        )
    }

    renderTable() {
        const { deliveries } = this.state;

        return (
            <div style={{ fontSize: '14px' }}>
                <Table size='sm' bordered hover style={{ 'width': '100%', tableLayout: 'auto' }}>
                    <thead>
                        <tr className="text-center">
                            <th style={{ whiteSpace: 'nowrap' }}>Bill No.</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Event Date</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Delivery Date</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Program</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Venue</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Customer Name</th>
                            <th style={{ whiteSpace: 'nowrap' }}>PAN</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Mobile</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Address</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Submitted By</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Modified Date</th>
                            <th style={{ whiteSpace: 'nowrap' }}>STATUS</th>
                            <th style={{ whiteSpace: 'nowrap' }}>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {deliveries.map((delivery, index) => (
                            <tr key={delivery._id}>
                                <td style={{ whiteSpace: 'nowrap' }}>{delivery.booking.billNum}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{moment(delivery.booking.eventDate).format('MMMM DD, YYYY')}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{moment(delivery.deliveryDate).format('MMMM DD, YYYY')}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{delivery.booking.program}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{delivery.booking.venue}</td>
                                <td style={{ whiteSpace: 'nowrap', fontWeight: '500' }}>{delivery.customer.customerName}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{delivery.customer.panNum}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{delivery.customer.mobile}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{delivery.customer.address}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{delivery.submittedBy}</td>
                                <td style={{ whiteSpace: 'nowrap' }}>{moment(delivery.dateModified).format('MMMM DD, YYYY')}</td>
                                <td className="text-center" style={{ backgroundColor: this.getStatusColor(delivery.booking), whiteSpace: 'nowrap' }}>
                                    {delivery.booking.isPaid
                                        ? 'Paid'
                                        : delivery.booking.isReturned
                                            ? 'Returned'
                                            : delivery.booking.isDelivered
                                                ? 'Delivered'
                                                : 'Booked'}
                                </td>
                                <td style={{ whiteSpace: 'nowrap' }}>
                                    <Button
                                        variant='outline-dark'
                                        style={{ padding: '0.25rem 0.5rem', fontSize: '10px', marginRight: '5px', marginLeft: '5px' }}
                                        onClick={() => this.props.printDelivery(delivery)}
                                    >
                                        Print
                                    </Button>
                                    <Button
                                        variant='outline-warning'
                                        style={{ padding: '0.25rem 0.5rem', fontSize: '10px', marginRight: '5px' }}
                                        onClick={() => {
                                            if (this.props.user.isAdmin || this.props.user.isSuperAdmin) {
                                                if (delivery.booking.isReturned) {
                                                    toast.info('Delivery has already RETURNED');
                                                }
                                                else { this.props.update(delivery._id) }
                                            }
                                            else {
                                                toast.error('Access Denied.')
                                            }
                                        }}
                                    >
                                        Edit
                                    </Button>
                                    <Button
                                        variant='outline-danger'
                                        style={{ padding: '0.25rem 0.5rem', fontSize: '10px', marginRight: '5px' }}
                                        onClick={() => {
                                            if (this.props.user.isAdmin || this.props.user.isSuperAdmin) {
                                                this.deleteDelivery(delivery)
                                            }
                                            else {
                                                toast.error('Access Denied.')
                                            }
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        );
    }

    render() {
        const { deliveries, loading } = this.state;

        return (
            <div>
                {loading ? (
                    <div>
                        <center>
                            <br />
                            <br />
                            <br />
                            <Spinner size="sm" animation="grow" variant="danger" />{' '}
                            <Spinner size="sm" animation="grow" variant="warning" />{' '}
                            <Spinner size="sm" animation="grow" variant="success" />
                        </center>
                    </div>
                ) : (
                    <div>
                        <ToastContainer position="bottom-right" hideProgressBar />
                        {deliveries.length === 0 ? (
                            <Alert variant="info">No more Deliveries available.</Alert>
                        ) : (
                            <div>
                                <br />
                                <Button variant='outline-dark' size='sm' onClick={this.toggleView}>Toggle View</Button>
                                <br />
                                <br />
                                {(this.state.viewStyle === 'table') ? this.renderTable() : this.renderBlock()}
                            </div>
                        )}
                    </div>
                )}
                <br />
                <Button size='sm' variant='outline-dark' onClick={this.prevPage}>Previous</Button>
                <Button size='sm' variant='outline-dark' className="float-right" onClick={this.nextPage} disabled={(this.state.deliveries.length === 0)}>Next</Button>
                <br /><br />
            </div>
        );
    }
}

export default ExploreDelivery;